import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
const DetailPanel = styled.div`
  &:last-child {
    @media only screen and (max-width:1024px) and (min-width:281px){
     margin-bottom:3rem;
    }
}

`;

const DetailsContainer = styled.div`
margin-top:20px;
width: ${(props) => (props.width ? props.width : '490px')};
border: 0.5px solid #d3d3d3;
background: white;
z-index: 200;
display: flex;
flex-direction: column;
top: 133px;
left: 708px
border-radius: 7px;
@media only screen and (max-width:1024px) and (min-width:281px){
  width: 100%;
  display: block !important;
  top:0 !important;
  left: 0 !important;
  position: inherit !important;
    margin-top:${(props) => (props.margin_top ? props.margin_top : '3.5rem')} !important; 
}  

@media screen and (max-device-width:480px) and (orientation: portrait) {
    width: 100%;
    display: block !important;
    top:0 !important;
    left: 0 !important;
    position: inherit !important;
    margin-top:${(props) => (props.margin_top ? props.margin_top : '3rem')} !important; 
    
}


 @media only screen and (max-width:1100px) and (min-width:1025px){
  width: 39%;
  display: block !important;
  top: 8.5rem !important;
  left: 50rem !important;
 } 
 @media only screen and (max-width:1200px) and (min-width:1101px){
  width:42%;
  display: block !important;
  top:8.2rem !important;
  left: 52rem !important;
 } 
 @media only screen and (max-width:1300px) and (min-width:1201px){
  width:42%;
  display: block !important;
  top:8.8rem !important;
  left:58.5rem !important;
 } 
 @media only screen and (max-width:1400px) and (min-width:1301px){
  width:48%;
  display: block !important;
  top: 8.7rem !important;
  left: 55rem !important;
 } 
 @media only screen and (max-width:1500px) and (min-width:1401px){
  width: 46%;
  display: block !important;
  top: 8.9rem !important;
  left: 61.5rem !important;
 }
 @media only screen and (max-width:1600px) and (min-width:1501px){
  width: 46%;
  display: block !important;
    top: 9rem !important;
    left:63.5rem !important;
 }
 @media only screen and (max-width:1700px) and (min-width:1601px){
  width: 46%;
  display: block !important;
  top: 8.5rem !important;
  left: 64.2rem !important;
 }
 @media only screen and (max-width:1800px) and (min-width:1701px){
  width:42%;
  display: block !important;
  top: 9.2rem !important;
  left:70.2rem !important;
 }
 @media only screen and (max-width:1900px) and (min-width:1801px){
  width:42%;
  display: block !important;
  top: 9.1rem !important;
  left:73.2rem !important;
 }
 @media only screen and (max-width:2000px) and (min-width:1901px){
  width:42%;
  display: block !important;
  top: 9.1rem !important;
  left:78.8rem !important;
 }
 @media only screen and (max-width:2200px) and (min-width:2001px){
  width:42%;
  display: block !important;
  top: 9.1rem !important;
  left:87.8rem !important;
 }
 @media only screen and (max-width:2400px) and (min-width:2201px){
  width:42%;
  display: block !important;
  top: 10rem !important;
  left:94.8rem !important;
 }
 @media only screen and (max-width:2600px) and (min-width:2401px){
  width:42%;
  display: block !important;
  top: 9.5rem !important;
  left:97.8rem !important;
 }
 @media only screen and (max-width:2800px) and (min-width:2601px){
  width:42%;
  display: block !important;
  top: 10.7rem !important;
  left:107.8rem !important;
 }
 @media only screen and (max-width:3000px) and (min-width:2801px){
  width:42%;
  display: block !important;
  top: 9.7rem !important;
  left:117rem !important;
 }
 @media only screen and (max-width:3200px) and (min-width:3001px){
  width:42%;
  display: block !important;
  top: 9.7rem !important;
  left:129rem !important;
 }
 @media only screen and (max-width:3400px) and (min-width:3201px){
  width:42%;
  display: block !important;
  top: 9.7rem !important;
  left:132rem !important;
 }
 @media only screen and (max-width:3600px) and (min-width:3401px){
  width:42%;
  display: block !important;
  top: 9.7rem !important;
  left:135rem !important;
 }
 @media only screen and (max-width:3800px) and (min-width:3601px){
  width:42%;
  display: block !important;
  top: 9.7rem !important;
  left:135rem !important;
 }
 @media only screen and (max-width:4000px) and (min-width:3801px){
  width:42%;
  display: block !important;
  top: 11rem !important;
  left:144rem !important;
 }
`;
const ChildContainer = styled.div`
padding: 10px 25px 15px 25px;
overflow-y:auto;
max-height:61vh;
@media (max-width:768px) {
  width: 100%;
  display: block !important;
  position: relative !important;
  padding: 12px;
  overflow-x: hidden;
   }
@media only screen and (max-width:1024px) and (min-width:280px){
    max-height:100%;
  }  
`;
const DetailsHeader = styled.div`
  padding: 15px 25px 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  @media (max-width:768px) {
    padding:12px !important;  
     }
     h2{
      @media (max-width:480px) {
        font-size: 18px;
        margin-bottom: 0;
         }
     }
`;
const EditButton = styled(Button)`
  border-radius: 4px;
  padding: 6px 10px;
  flex-grow: 0;
  min-width: 50px;
  height: 29px;
  font-family: 'Hauora';
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  background: #fbfbfb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: 0.5px solid #eaeaea;
  color: #7f808f;
  text-align: center;
  transition: box-shadow 0.2s;
  min-width: 0;
  width: auto;
  margin: 0;
`;
const SaveButton = styled(EditButton)`
  background: var(--key-color);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: none;
  color: white;
  text-align: center;
  transition: box-shadow 0.2s;
  margin-left: 10px;
`;

export class AdminDetails extends Component {
  render() {
    const { headerText, loading, children, alt, positionT, margin_top, handleClose, isDefault, handleEdit, width } = this.props;
    return (
      <DetailPanel>
        <DetailsContainer
          width={width}
          margin_top={margin_top}
          style={{
            position: positionT ? 'absolute' : 'fixed',
            left: positionT ? '454px' : '720px',
            top: positionT ? '0px' : '110px',
          }} >
          <DetailsHeader id='detailsContainer'>
            <h2>{headerText}</h2>
            {!alt && !isDefault && <EditButton onClick={handleEdit}>Edit</EditButton>}
            {alt && (
              <div style={{ display: "flex" }}>
                <EditButton onClick={handleClose}>Cancel</EditButton>
                <SaveButton disabled={loading} type='submit'>Save</SaveButton>
              </div>
            )}
          </DetailsHeader>
          <ChildContainer>
            {children}
          </ChildContainer>
        </DetailsContainer>
      </DetailPanel>
    );
  }
}
