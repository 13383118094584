import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import pending from '../../assets/forms-icon/circle-gray.svg';
import active from '../../assets/forms-icon/circle-green.svg';
import completed from '../../assets/forms-icon/circle-checked.svg';
import chevronRight from '../../assets/forms-icon/chevron-right.svg';

import { WhiteButton } from '../Administration/Style';
import { Button } from '../../components';
import FormGenerator from './FormGenerator';
import UploadDocuments from './UploadDoc';
import { HealthRecordDetails } from '../../helpers';
import { DetailsSection, FooterButtonSection, FormViewContainer, ImageContainer, Label, QuestionSectionContainer, SubSectionCard, SubSectionCardBody, SubSectionContainer } from './Style';


class FormView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            form: {},
            formData: {},
            currentStep: 1,
            completedStep: 1,
            errors: { apiError: false },
            members: [],
            formId: '',
            taskId: '',
            isDataChanged: false,
            saveInprogress: false,
        };
        this.timeoutId = null;
    }

    componentDidMount() {
        this.getForm();
        this.getMasterData();
    };

    getIncompleteFormData = async (formId, taskId) => {
        try {
            const { user: { location } } = this.props;
            let apiUrl = `/api/v2/inCompletedRecords/${formId}/${location._id}`;
            if (taskId) apiUrl += `/${taskId}`;

            const { data: { payload } } = await axios.get(apiUrl);
            const formData = JSON.parse(payload.formData);

            this.setState({ loading: false, formData, currentStep: payload.currentStep + 1, completedStep: payload.currentStep + 1, incompleteRecord: payload._id, formId });
        } catch (error) {
            console.error(error);
            this.setState({ loading: false, formId });
        }
    };

    getForm = async () => {
        try {
            this.setState({ loading: true });
            const { history: { location } } = this.props;
            const searchParams = new URLSearchParams(location.search);
            const formId = searchParams.get('formId');
            const taskId = searchParams.get('taskId');
            if (formId === 'uploadHealthDocumentForm') {
                this.setState({ loading: false, form: HealthRecordDetails, formId });
            } else {
                const { data: { payload: form } } = await axios.get('/api/v2/form/' + formId);
                this.setState({ form, formId, taskId });
                await this.getIncompleteFormData(formId, taskId);
            }
        } catch (error) {
            console.error(error);
            this.setState({ loading: false, errors: { apiError: true, errorMessage: 'Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.' } });
            return {};
        }
    };

    getMasterData = async () => {
        try {
            const { user: { location } } = this.props;
            const { data: { payload: members } } = await axios.get(`/api/v2/master/users_by_location/${location._id}`);
            this.setState({ members });
        } catch (error) {
            console.error(error);
        }
    };

    onModalChange = (key, value) => {
        const { formData, currentStep, completedStep } = this.state;
        this.setState({ formData: { ...formData, [key]: value } });
        if (currentStep < completedStep) {
            this.setState({ isDataChanged: true });
        };
    };

    changeStep = (currentStep) => {
        const { completedStep, isDataChanged } = this.state;
        if (isDataChanged) {
            this.setState({ errors: { dataChangedError: true } });
            return;
        };
        if (currentStep <= completedStep && !isDataChanged) {
            this.setState({ currentStep });
        };
    };

    update = async (e) => {
        e.preventDefault();
        try {
            const { currentStep, completedStep, formId, taskId } = this.state;
            await this.saveOrUpdateRecord();
            await this.getIncompleteFormData(formId, taskId);
            const cs = (currentStep + 1) > completedStep ? currentStep + 1 : completedStep;
            this.setState({ currentStep: currentStep + 1, completedStep: cs, isDataChanged: false });
        } catch (error) {
            console.error(error);
        }
    };

    save = async (e) => {
        e.preventDefault();
        const res = await this.saveOrUpdateRecord();
        if (res) {
            const { history } = this.props;
            Swal.fire({
                icon: 'success',
                title: 'Record has been created successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => {
                return history.goBack();
            });
        };
    };

    saveOrUpdateRecord = async () => {
        try {
            this.setState({ saveInprogress: true });
            const { user: { location, isAdmin: isApproved } } = this.props;
            const { currentStep, taskId, formData, form: { name, id, question_blocks }, incompleteRecord, completedStep } = this.state;
            const recordData = {
                name,
                formData: JSON.stringify(formData),
                currentStep: currentStep >= completedStep ? currentStep : completedStep - 1,
                formId: id,
                isCompleted: question_blocks.length === currentStep ? true : false,
                deleted: false,
                location: location._id,
                isApproved,
            };
            if (taskId) recordData.taskId = taskId;

            const apiUrl = incompleteRecord ? `/api/v2/record/${incompleteRecord}` : '/api/v2/record';
            const result = await axios.post(apiUrl, recordData);
            this.setState({ saveInprogress: false });
            return result;
        } catch (error) {
            this.setState({ saveInprogress: false, errors: { apiError: true } });
            console.error(error);
            return false;
        }
    };

    render() {
        const { history, user } = this.props;
        const { errors, formId, members, loading, currentStep, completedStep, formData, form: { name: formName, description: formDescription, sections, question_blocks }, errors: { dataChangedError, apiError }, saveInprogress, timeLeft } = this.state;
        const { question_block } = loading ? [] : question_blocks[currentStep - 1];

        return (
            <>{loading ?
                <Container className='text-center my-3'>
                    <Spinner animation="border" variant="success" />
                </Container> :
                <FormViewContainer>
                    <div className="d-flex justify-content-between mx-2">
                        <div>
                            <h2 className='my-0'>{formName}</h2>
                            <p className='my-0'>{formDescription}</p>
                        </div>
                        <WhiteButton type='button' onClick={() => { clearTimeout(this.timeoutId); history.goBack(); }} width='100' className='my-0'>Cancel</WhiteButton>
                    </div>
                    <hr />
                    {dataChangedError &&
                        <Row>
                            <Col>
                                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                    <p className='w-100 mb-0'>Save current changes.</p>
                                </Alert>
                            </Col>
                        </Row>
                    }
                    <DetailsSection className='mt-2'>
                        <SubSectionContainer lg='4'>
                            {sections.map(({ label, stepNumber }) => (
                                <SubSectionCard onClick={() => this.changeStep(stepNumber)} active={stepNumber === currentStep ? 'true' : ''} key={stepNumber} disabled={stepNumber > completedStep}>
                                    <SubSectionCardBody>
                                        <Col lg='1' className='p-0'>
                                            <ImageContainer src={stepNumber < completedStep ? completed : stepNumber === currentStep ? active : pending} />
                                        </Col>
                                        <Col lg='10' sm='10' className='p-0'>
                                            <Label textColor={stepNumber < completedStep ? '#343747' : stepNumber === currentStep ? '#4FB973' : '#BFBFBF'}>{stepNumber + ". " + label}
                                            </Label>
                                        </Col>
                                        <Col lg='1' className='p-0'>
                                            {stepNumber === currentStep &&
                                                <ImageContainer src={chevronRight} />
                                            }
                                        </Col>
                                    </SubSectionCardBody>
                                </SubSectionCard>
                            ))}
                        </SubSectionContainer>

                        {formId === 'uploadHealthDocumentForm' ?
                            <UploadDocuments history={history} user={user} error={errors} members={members} />
                            :
                            <form onSubmit={question_blocks.length === currentStep ? this.save : this.update} className='col-lg-8'>
                                <QuestionSectionContainer>
                                    {question_block && question_block.map((question, index) => (
                                        <Col md='12' className="mb-3" key={index}>
                                            <FormGenerator
                                                members={members}
                                                questionNo={index + 1}
                                                data={formData}
                                                question={question.question}
                                                onFormDataChange={this.onModalChange}
                                            />
                                        </Col>
                                    ))}
                                </QuestionSectionContainer>
                                {apiError &&
                                    <Col lg='12' md='12' sm='12' className='mb-2'>
                                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                            <p className='w-100 mb-0'>Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.</p>
                                        </Alert>
                                    </Col>
                                }
                                <FooterButtonSection>
                                    <Col lg='5' md='8' sm='12' className='d-flex justify-content-end'>
                                        {question_blocks.length > 1 && <WhiteButton disabled={currentStep === 1} onClick={() => this.setState({ currentStep: currentStep - 1 })} className='my-0 mr-2'>{'<< Back'}</WhiteButton>}
                                        <Button disabled={saveInprogress} type='submit' className='my-0 col-lg-6'>{saveInprogress ? 'Saving...' : (question_blocks.length === currentStep ? 'Save' : 'Next >>')}</Button>
                                    </Col>
                                </FooterButtonSection>
                            </form>
                        }
                    </DetailsSection>

                </FormViewContainer>
            }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(FormView);