import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { CustomCard, HeaderContainer, HeaderText, SearchInput, TableContainer, TableHead } from './Style';
import { DropDownStyle } from '../../helpers';
import { Checkbox } from '../../components';
import closeGaryIcon from '../../assets/images/glyph-close-dark-gray.svg';


const UserSelection = ({ show, onClose, locations, loginUser, selectedUsers, afterSave }) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [peopleSearch, setSearchPeople] = useState('');
    const [selectedLocation, setSelectedLocation] = useState({ _id: 'all', name: 'All' });
    const [selectedUsersTemp, setSelectedUsersTemp] = useState([...selectedUsers]);
    const [selectAll, setSelectAll] = useState(false);

    const [roles, setRoles] = useState([]);
    const [error, setError] = useState('');


    useEffect(() => {
        getUsers('all');
    }, []);

    const getUsers = async (locationId) => {
        try {
            locationId === 'all' ? locationId = loginUser.adminLocationIds : [locationId];
            setLoading(true);
            const { data: { payload: roles } } = await axios.get(`/api/v2/master/roles_by_location/${locationId}`);
            setRoles(roles);
            const { data: { payload } } = await axios.get(`/api/v2/training/users/${locationId}`);
            setUsers(payload);
            setFilteredUsers(payload);
            setLoading(false);
            return;
        } catch (error) {
            console.error("🚀 ~ getUsers ~ error:", error);
            setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.');
            setLoading(false);
        }
    };

    const searchPeople = (value) => {
        setSearchPeople(value);
        const temp = !value ? users : users.filter(user => user.fullName.toLowerCase().includes(value.toLowerCase()));
        setFilteredUsers(temp);
    };

    const onLocationChange = async (e) => {
        try {
            setSelectAll(false);
            await getUsers(e.value);
            setSelectedLocation({ _id: e.value, name: e.label });
        } catch (error) {
            console.log("🚀 ~ onLocationChange ~ error:", error);
        }
    };

    const getPositions = (user) => {
        const temp = selectedLocation._id === 'all' ? loginUser.location._id : selectedLocation._id;
        if (user.adminLocations && user.adminLocations.length && user.adminLocations.includes(temp)) return 'Administration';
        return 'Non-administration';
    };

    const getRoleForCurrentLocation = (user) => {
        const role = roles.find(r => user.roles.includes(r._id)) || {};
        return role.label || '--';
    };

    const onCheckBoxChange = (checked, userId) => {
        if (!userId) {
            let tempSelectedUsers = selectedUsersTemp;
            if (checked) {
                setSelectAll(true);
                tempSelectedUsers.push(...users.map(user => user._id));
            } else {
                setSelectAll(false);
                tempSelectedUsers = [];
            };
            tempSelectedUsers = [...new Set(tempSelectedUsers)];
            setSelectedUsersTemp(tempSelectedUsers);
        } else {
            let tempSelectedUsers = selectedUsersTemp;
            setSelectAll(false);
            if (checked) {
                tempSelectedUsers.push(userId);
                tempSelectedUsers = [...new Set(tempSelectedUsers)];
                setSelectedUsersTemp(tempSelectedUsers);
            } else {
                tempSelectedUsers = tempSelectedUsers.filter(_id => _id !== userId);
                setSelectedUsersTemp([...tempSelectedUsers]);
            };
        };
    };

    const onSave = () => {
        afterSave(selectedUsersTemp);
    };

    const getLocationsNames = (user) => {
        const locationsNames = locations.filter(loc_obj => user.locations.includes(loc_obj.value));
        return locationsNames.map(loc_obj => loc_obj.label).join(', ');
    };

    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='xl' show={show}>
            <CustomCard className="border-0 rounded-0">
                <HeaderContainer className="mb-4 d-flex justify-content-between">
                    <HeaderText>Add People</HeaderText>
                    <Button onClick={onClose} type='button' variant='light'><img src={closeGaryIcon} alt="Cose" /></Button>
                </HeaderContainer>
                <div className="d-flex justify-content-between">
                    <div className="col">
                        <SearchInput value={peopleSearch} className='pl-5 col-lg-6 col-md-3 col-sm-12' onChange={(e) => searchPeople(e.target.value)} type='text' placeholder='Search...' />
                    </div>
                    <div className="d-flex col justify-content-end">
                        <Select
                            className="clo-lg-6 col-md-6 col-sm-12"
                            options={locations}
                            value={locations.find(lo => lo.value === selectedLocation._id)}
                            defaultValue={locations.find(lo => lo.value === selectedLocation._id)}
                            onChange={onLocationChange}
                            placeholder='Locations'
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={true}
                        />
                    </div>
                </div>
                <TableContainer className='mt-2 mx-2' maxHeight='60vh'>
                    <table className='table'>
                        <TableHead>
                            <tr>
                                <th className="w-5"><Checkbox checked={users.every(user => selectedUsersTemp.includes(user._id)) || selectAll} onChange={(e) => onCheckBoxChange(e.target.checked)} /></th>
                                <th className='w-15 pl-4'>Name</th>
                                <th className="w-15">Position</th>
                                <th className="w-10">Role</th>
                                <th className='w-10'>Phone</th>
                                <th className='w-20'>Email address</th>
                                <th className='w-20'>Practice location</th>
                            </tr>
                        </TableHead>
                        {!loading &&
                            <tbody>
                                {filteredUsers && filteredUsers.map((user) => (
                                    <tr className='justify-content-between align-items-center' key={user._id}>
                                        <td className='w-5'>
                                            <Checkbox checked={selectedUsersTemp.includes(user._id)} onChange={(e) => onCheckBoxChange(e.target.checked, user._id)} />
                                        </td>
                                        <td className='w-15 pl-4'>{user.fullName + (user._id === loginUser._id ? ' (Self)' : '')}</td>
                                        <td className="w-15">{getPositions(user)}</td>
                                        <td className="w-10">{getRoleForCurrentLocation(user)}</td>
                                        <td className='w-10'>{user.phone || '--'}</td>
                                        <td className='w-20'>{user.email || '--'}</td>
                                        <td className="w-20">{getLocationsNames(user) || '--'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                </TableContainer>
                <div className='text-right'>
                    <Button onClick={onClose} variant='light' size='lg' className='mx-3 px-5'>Cancel</Button>
                    <Button onClick={onSave} variant='success' size='lg' className='px-5'>Save</Button>
                </div>
            </CustomCard>
        </Modal>
    )
};

export default UserSelection;