/**
 * @namespace practiceReducer
 * @category Reducers
 */

/**
 * @memberOf practiceReducer
 */
const initialState = {};

/**
 * @method
 * @memberOf practiceReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const practiceReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
