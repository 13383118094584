import { useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { RegistrationFlowStepper } from "./Stepper";
import { RegisterComponentContainer } from "./Style";
import PersonalInfo from "./PersonalInfo";
import Verification from "./Verification";
import CompanyInfo from "./CompanyInfo";
import BillingInfo from "./BillingInfo";

const PracticeRegistration = () => {
    const [stepNumber, setStepNumber] = useState(1);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

    return (
        <RegisterComponentContainer>
            <div className="d-flex justify-content-center">
                <RegistrationFlowStepper currentStep={stepNumber} />
            </div>
            <div className="d-flex justify-content-center">
                {stepNumber === 1 && <PersonalInfo setStepNumber={setStepNumber} />}
                {stepNumber === 2 && <Verification setStepNumber={setStepNumber} />}
                {stepNumber === 3 && <CompanyInfo setStepNumber={setStepNumber} />}
                {stepNumber === 4 &&
                    <Elements stripe={stripePromise}>
                        <BillingInfo setStepNumber={setStepNumber} />
                    </Elements>
                }
            </div>
        </RegisterComponentContainer>
    )
};

export default PracticeRegistration;