import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Router, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import StaffList from './StaffList';
import TasksList from './TasksList';
import TaskView from './TaskView';
import SafetyEquipmentList from './SafetyEquipmentList';
import RolesList from './RolesList';
import Subscription from './Subscription';
import LocationList from './LocationList';
import LocationView from './LocationView';
import RoleView from './RoleView';
import SafetyEquipmentView from './SafetyEquipmentView';
import { AuthRoute } from '../../components/AuthRoute';
import StaffDetails from './StaffDetails';
import { NavHeader, NavTab, NavigationContainer, NavigationTabSection } from './Style';


const AdminContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border-radius:10px;
    @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width:85%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: 6.5rem 0.5rem 0rem 6rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:6.5rem 0.5rem 0rem 6rem !important;
        max-width:100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    .min-w-15{
        min-width:15% !important;
    }
    .min-w-25{
        min-width:25% !important;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;


class Admin extends PureComponent {

    componentDidMount() {
        const { user, history } = this.props;
        if (!user.isAdmin) history.push('/');
    };

    componentWillReceiveProps = (updatedProps) => {
        if (!updatedProps.user.isAdmin) updatedProps.history.push('/');
    };

    navigate = (route) => {
        const { history } = this.props;
        history.push(route);
    };

    render() {
        const { location: { pathname }, history, user, authenticated } = this.props;
        return (
            <AdminContainer>
                <NavigationContainer>
                    <NavHeader>Administration</NavHeader>
                    <NavigationTabSection >
                        <NavTab onClick={() => this.navigate('/admin/staff-list')} lg='2' sm='12' md='6' title="Staff Members" className={['/admin/staff-list', '/admin/staff-view', '/admin'].includes(pathname) ? 'active' : ''}>
                            Staff Members
                        </NavTab>
                        <NavTab className={['/admin/role-list', '/admin/role-view'].includes(pathname) ? 'active' : ''} lg='2' sm='12' md='6' title="Roles" onClick={() => this.navigate('/admin/role-list')}>
                            Roles
                        </NavTab>
                        <NavTab className={['/admin/ppe-list', '/admin/ppe-view'].includes(pathname) ? 'active' : ''} lg='2' sm='12' md='6' title="Safety Equipments" onClick={() => this.navigate('/admin/ppe-list')}>
                            PPE
                        </NavTab>
                        <NavTab className={['/admin/task-list', '/admin/task-view'].includes(pathname) ? 'active' : ''} lg='2' sm='12' md='6' title="Task" onClick={() => this.navigate('/admin/task-list')}>
                            Tasks
                        </NavTab>
                        <NavTab className={['/admin/location-view', '/admin/location-list'].includes(pathname) ? 'active' : ''} lg='2' sm='12' md='6' title="Practice Locations" onClick={() => this.navigate('/admin/location-list')}>
                            Practice Locations
                        </NavTab>
                        <NavTab className={pathname === '/admin/subscription' ? 'active' : ''} lg='2' sm='12' md='6' title="Subscription & Billing" onClick={() => this.navigate('/admin/subscription')}>
                            Subscription & Billing
                        </NavTab>
                    </NavigationTabSection>
                </NavigationContainer>
                <Router history={history}>
                    <Switch>
                        <AuthRoute
                            exact
                            authenticated={authenticated}
                            user={user}
                            component={StaffList}
                            path='/admin'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={StaffList}
                            path='/admin/staff-list'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={StaffDetails}
                            path='/admin/staff-view'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={RolesList}
                            path='/admin/role-list'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={RoleView}
                            path='/admin/role-view'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={SafetyEquipmentList}
                            path='/admin/ppe-list'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={SafetyEquipmentView}
                            path='/admin/ppe-view'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={TasksList}
                            path='/admin/task-list'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={TaskView}
                            path='/admin/task-view'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={LocationList}
                            path='/admin/location-list'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={LocationView}
                            path='/admin/location-view'
                        />
                        <AuthRoute
                            authenticated={authenticated}
                            user={user}
                            component={Subscription}
                            path='/admin/subscription'
                        />
                    </Switch>
                </Router>

            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    authenticated: state.user.authenticated,
});

export default withRouter(connect(mapStateToProps)(Admin));