import axios from 'axios';
import actions from './actionTypes';
import { toast } from 'react-toastify';
import { apiUrl } from '../helpers';
import { loadUser } from './userActions';

/**
 * @namespace accountActions
 * @category Actions
 */

/**
 * @method
 * @memberOf accountActions
 * @param {*} user
 */
export const updateUserSuccess = (user) => ({
  type: actions.UPDATE_USER_SUCCESS,
  user
});

const getSafetyOfficersSuccess = (safetyOfficers) => ({
  type: actions.LOAD_SAFETY_OFFICERS_FOR_LOCATION_SUCCESS,
  safetyOfficers
});

/**
 * @method
 * @async
 * @memberOf accountActions
 * @param {*} updatedUser
 */

export const getSafetyOfficer = (locationId) => async (dispatch) => {
  try {
    const { data } = await axios.get(apiUrl(`/api/v1/safetyOfficers/${locationId}`));
    dispatch(getSafetyOfficersSuccess(data))
    return data;
  } catch (error) {
    return false;
  }
};

export const updateUser = (updatedUser, displayToast = true) => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    const { data } = await axios.put(apiUrl(`/api/v1/users/${currentUser._id}`), { ...updatedUser });
    await dispatch(loadUser(data));
    await dispatch(updateUserSuccess(data));
    if (updatedUser.updated) {
      displayToast && toast.success('User updated successfully');
    } else {
      displayToast && toast.success('User created successfully');
    };
    return data;
  } catch (err) {
    if (err.response && err.response.status === 409) {
      toast.error('User email already exists');
    } else {
      console.error('======================================');
      console.error('rip');
      console.error('======================================');
    };
    return false;
  }
};

export const selectPanel = (newPanel) => ({
  type: actions.SELECT_ACCOUNT_TAB,
  newPanel
});
