import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { TailSpin } from 'react-loader-spinner';

export class Spinner extends React.Component {
    render() {
        return (
            <Modal.Dialog>
                <Modal
                    show={true}
                    size="sm"
                    dialogClassName="spinner-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className='d-flex justify-content-center'>
                        <TailSpin color="gray" />
                    </div>
                </Modal>
            </Modal.Dialog>
        );
    }
};