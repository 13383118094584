import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert, Col, Row } from 'react-bootstrap';
import { CardHolderName, CardNumberInputContainer, StyledLabel } from './CardElement';
import { Button } from '../Button';
import { useState } from 'react';

const UpdateCardElement = ({ closePopUpModal, save, loading }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const cardNumberElement = elements.getElement(CardNumberElement);
            const { token, error } = await stripe.createToken(cardNumberElement);
            if (error) {
                setError(error.message);
                return;
            };
            setError('');
            save({ cardHolderName, id: token.id });
        } catch (error) {
            console.log("=====", error)
        }
    };

    return (
        <>
            <Row>
                <Col lg={6}>
                    <StyledLabel>Cardholder Name</StyledLabel>
                    <CardHolderName placeholder='Cardholder Name' type="text" onChange={(e) => setCardHolderName(e.target.value)} value={stripe?.name} />
                </Col>
                <CardNumberInputContainer lg={6}>
                    <StyledLabel>Card Number</StyledLabel>
                    <CardNumberElement
                        id="number"
                        options={{ showIcon: true }}
                    />
                </CardNumberInputContainer>
            </Row>
            <Row>
                <CardNumberInputContainer lg={6}>
                    <StyledLabel>Expiration Date</StyledLabel>
                    <CardExpiryElement />
                </CardNumberInputContainer>
                <CardNumberInputContainer lg={6}>
                    <StyledLabel>CVC</StyledLabel>
                    <CardCvcElement />
                </CardNumberInputContainer>
            </Row>
            {error &&
                <Row className='mt-4'>
                    <Col>
                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                            <p className='w-100 mb-0'>{error}</p>
                        </Alert>
                    </Col>
                </Row>
            }
            <Row className='mt-5'>
                <Col lg={6}>
                    <Button className='my-0' type='button' onClick={() => closePopUpModal()}>Cancel</Button>
                </Col>
                <Col lg={6}>
                    <Button disabled={loading} className='my-0' type='button' onClick={(e) => handleSubmit(e)}>{loading ? 'Saving...' : 'Save'}</Button>
                </Col>
            </Row>
        </>
    );
};

export default UpdateCardElement;
