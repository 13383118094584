import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';

const HorizontalProgressBarContainer = styled.div`
    .progress{
        height: 6px;
    }
`;
const ProgressLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
`;

const HorizontalProgressBar = ({ total, completed }) => {
    completed = completed ? completed / 60 : 0;
    total = total ? total / 60 : 0;
    completed = completed >= total ? total : completed;
    const percentage = (completed / total) * 100;
    return (
        <HorizontalProgressBarContainer>
            <ProgressLabelContainer>
                <span>{completed.toFixed(2)} / {total.toFixed(2)} min</span>
                <span className='text-success'>{percentage > 100 ? 100 : percentage.toFixed(2)} %</span>
            </ProgressLabelContainer>
            <ProgressBar variant="success" now={percentage} />
        </HorizontalProgressBarContainer>
    );
}

export default HorizontalProgressBar;