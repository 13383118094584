import actions from '../actions/actionTypes';

/**
 * @namespace libraryReducer
 * @category Reducers
 */

/**
 * @memberOf libraryReducer
 */
const initialState = {
  tree: {},
};

/**
 * @method
 * @memberOf libraryReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const libraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_LIBRARY_SUCCESS:
      // Delete the first content because it's empty
      delete action.library.content;
      return {
        ...state,
        tree: action.library
      };
    default:
      return state;
  }
};
