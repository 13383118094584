import actions from '../actions/actionTypes';

/**
 * @namespace userReducer
 * @category Reducers
 */

/**
 * @memberOf userReducer
 */
const initialState = {
  loading: false,
  authenticated: false,
  passwordRequested: false,
  error: null,
  currentUser: {},
  accountCreated: false,
  registering: false,
  resumedRegistration: false
};

/**
 * @method
 * @memberOf userReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REGISTER_NEW_ACCOUNT_SUCCESS:
      return {
        ...state,
        currentUser: action.user,
        accountCreated: true,
        authenticated: true
      };
    case actions.REGISTER_NEW_ACCOUNT_FAILURE:
      return {
        ...state,
        currentUser: {},
        accountCreated: false
      };

    case actions.REGISTER_NEW_PRACTICE_SUCCESS:
      return {
        ...state,
        currentUser: action.user,
        practiceCreated: true,
        authenticated: true
      };
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.UPDATE_PRACTICE_SUCCESS:
      state.currentUser.practice = action.practice;
      return {
        ...state,
        currentUser: state.currentUser
      };
    case actions.UPDATE_PRACTICE_FAILURE:
      return {
        ...state,
        currentPractice: {}
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: action.error
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        currentUser: action.user
      };
    case actions.REQUEST_PASSWORD:
      return {
        ...state,
        loading: true
      };
    case actions.REQUEST_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordRequested: true
      };
    case actions.REQUEST_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actions.SIGNOUT_SUCCESS:
      return {
        authenticated: false,
        currentUser: {}
      };
    case actions.RESUME_REGISTRATION:
      return {
        ...state,
        currentUser: action.user,
        resumedRegistration: true,
        registering: true
      };
    case actions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.user
      };
    case actions.LOAD_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.user
      };
    case actions.CHANGE_LOCATION:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          location: action.location,
          practice: action.practice
        }
      };
    case actions.UPDATE_USER_LOCATION:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          location: action.location
        }
      };
    case actions.LOAD_ADMINS_FOR_LOCATION_SUCCESS:
      return {
        ...state,
        adminsForCurrentLocation: action.admins
      };
    case actions.LOAD_SAFETY_OFFICERS_FOR_LOCATION_SUCCESS:
      return {
        ...state,
        safetyOfficers: action.safetyOfficers
      };
    default:
      return state;
  }
};
