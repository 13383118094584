import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Card, Figure, Row, Col, Alert, Spinner, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import clock from '../../assets/images/clock.svg'
import calendar from '../../assets/images/calendar.svg';
import violenceIncidentForm from '../../assets/forms-icon/violenceIncidentForm.svg';
import exposureIncidentForm from '../../assets/forms-icon/exposureIncidentForm.svg';
import sharpsInjuryForm from '../../assets/forms-icon/sharpsInjuryForm.svg';
import chemicalExposureForm from '../../assets/forms-icon/chemicalExposureForm.svg';
import eval_sharps_disposal from '../../assets/forms-icon/eval_sharps_disposal.svg';
import hepatitisBVaccineConsentForm from '../../assets/forms-icon/hepatitisBVaccineConsentForm.svg';
import sharpsDisposalContainerEvaluationForm from '../../assets/forms-icon/sharpsDisposalContainerEvaluationForm.svg';
import ECRIsNeedleStickPreventionDeviceEvaluationForm from '../../assets/forms-icon/ECRIsNeedleStickPreventionDeviceEvaluationForm.svg';
import safetyDentalSyringesForm from '../../assets/forms-icon/safetyDentalSyringesForm.svg';
import SlipTripandFallForm from '../../assets/forms-icon/SlipTripandFallForm.svg';
import quarterlySafetyReportForm from '../../assets/forms-icon/quarterlySafetyReportForm.svg';
import needlestickSafetyAndPreventionAnnualRiskAssessment from '../../assets/forms-icon/needlestickSafetyAndPreventionAnnualRiskAssessment.svg';
import tbRiskAssessment from '../../assets/forms-icon/tbRiskAssessment.svg';
import uploadHealthDocumentForm from '../../assets/forms-icon/uploadHealthDocumentForm.svg';

import styled from 'styled-components';
import { Button } from '../../components';

const icons = { violenceIncidentForm, sharpsDisposalContainerEvaluationForm, exposureIncidentForm, sharpsInjuryForm, chemicalExposureForm, eval_sharps_disposal, hepatitisBVaccineConsentForm, ECRIsNeedleStickPreventionDeviceEvaluationForm, safetyDentalSyringesForm, SlipTripandFallForm, quarterlySafetyReportForm, needlestickSafetyAndPreventionAnnualRiskAssessment, tbRiskAssessment, uploadHealthDocumentForm };

const ContentHeader = styled.h5`
    border-bottom: 1px solid #ddd;
    width: 100%;
    margin: 0 1.5rem 1rem;
    color:#676767;
    padding-bottom: 0.5rem;
    font-weight: bold;
    @media screen and (max-device-width:450px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:450px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;

const TaskContainer = styled.div``;

const FormHeader = styled.div`
    @media screen and (max-device-width:4000px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;

const CustomCard = styled(Card)`
    cursor: pointer;
    border:1px solid #EFEFEF !important;
    border-radius:7px !important;
    margin-bottom: 0.5rem!important;
`;

const CardBody = styled(Card.Body)`
:hover {
    background: #4FB973;
    transition: all 0.2s ease-out;

    .text-success {
    color: #fff !important;
    }

    .figure-caption {
    color: #fff !important;
    }
}
.figure-caption {
    font-size:1rem;
    }
`;

const IconContainer = styled(Figure)`
    margin: 0;
    display: flex !important;
`;

const ImageContainer = styled(Figure.Image)`
    margin-right: 0.875rem;
    align-self: flex-start;
`;

const NameBlock = styled.span`
    padding:8px 8px
    border-radius:50%;
    background-color:#E7F3EB;
    color:#4FB973;
    margin-right: 1rem;
    font-size: 0.875em
    text-transform: uppercase;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
`;

const StyledCard = styled.div`
    background:#fff;
    border:1px solid #EFEFEF;
    padding:10px;
    box-shadow:none;
    border-radius:10px;
    color:#000 !important; 
    margin-bottom: 1rem!important;
    text-align: left;
    display: flex;
    width:100%;
    align-items: center;
    img{
        margin-right:1rem;
    }
    h6{
        @media screen and (max-device-width:450px) and (orientation: portrait) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 168px;
        }
        @media screen and (max-device-width:450px) and (orientation: landscape) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 168px;
        }
    }
`;

const DetailContainer = styled.div`
    overflow-y: auto;
    max-height:60vh;
`;

const NameBlockElement = ({ firstName, lastName }) => {
    return (
        <NameBlock>
            {firstName[0] + lastName[0]}
        </NameBlock>
    );
};

class TaskView extends Component {
    state = {
        loading: false,
        taskDetails: { name: '', instructions: '', users: [], due_date: '', frequency: '', forms: [] },
        members: [],
        errors: {}
    }

    componentDidMount() {
        this.getTask();
    };

    getTask = async () => {
        try {
            this.setState({ loading: true });
            const { history: { location } } = this.props;
            const searchParams = new URLSearchParams(location.search);
            const taskId = searchParams.get('taskId');
            const { data: { payload } } = await axios.get(`/api/v2/task/${taskId}`);
            if (payload.forms && payload.forms.length) {
                const { data: d } = await axios.get('/api/v2/form/' + payload.forms[0]);
                payload.forms = d.payload;
            } else {
                payload.forms = undefined;
            };
            await this.getMasterData(payload);
            this.setState({ taskDetails: payload, loading: false });
        } catch (error) {
            console.error(error);
            this.setState({ loading: false, errors: { apiError: true, } });
            return {};
        }
    };

    getMasterData = async (task) => {
        try {
            if (task.users && task.users.length) {
                const { user } = this.props;
                let { data: { payload: members } } = await axios.get(`/api/v2/master/users_by_location/${user.location._id}`);
                members = members.filter((member) => task.users.includes(member.value));
                this.setState({ members, loading: false });
            } else {
                this.setState({ members: [], loading: false });
            }
        } catch (error) {
            this.setState({ loading: false, errors: { apiError: true, errorMessage: 'Error at getting task.' } });
            console.error(error);
        }
    };

    render() {
        const { history } = this.props;
        const { loading, taskDetails: { name, instructions, users, due_date, frequency, forms, formName }, members } = this.state;
        const fre = { 0: 'Weekly', 1: 'Monthly', 2: 'Quarterly', 3: 'Yearly', 99: 'Once' };
        return (
            loading ?
                <div style={{ minHeight: '30px' }} className='text-center'>
                    <Spinner animation="border" variant="success" />
                </div> :
                <TaskContainer>
                    <div className="d-flex justify-content-between mx-2">
                        <FormHeader >
                            <h2>{name}</h2>
                            <p>{instructions}</p>
                            <p><img src={clock} alt="" className='mr-3' />{fre[frequency]}</p>
                            <p><img src={calendar} alt="" className='mr-3' />{moment(due_date).format('MM / DD / YYYY')}</p>
                        </FormHeader>
                        <Button onClick={() => { history.goBack() }} width='250' className='my-0'>{'<< Back'}</Button>
                    </div>
                    <DetailContainer className='mt-4'>
                        <Row className="mx-2">
                            <ContentHeader>Required Forms To Complete</ContentHeader>
                            {forms ?
                                <Col xs={12} md={6} lg={6}  >
                                    <CustomCard>
                                        <CardBody>
                                            <IconContainer className="justify-content-start align-items-center">
                                                <ImageContainer
                                                    width={50}
                                                    height={50}
                                                    alt="50x50"
                                                    src={icons[forms.id]}
                                                />
                                                <Figure.Caption>
                                                    <div className="text-success">
                                                        <h5>{forms.name}</h5>
                                                    </div>
                                                    {forms.description}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                                :
                                <Col>
                                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="info">
                                        <p className='w-100 mb-0'>No Forms Required To Complete.</p>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                        <Row className="mx-2">
                            <ContentHeader>Staff Members</ContentHeader>
                            {(members && members.length) ? members.map(({ firstName, lastName, label, value }) => (
                                <Col xs={12} md={4} lg={3} key={value}>
                                    <StyledCard>
                                        <NameBlockElement firstName={firstName} lastName={lastName} /> <h6>{label}</h6>
                                        <Tooltip placement="bottom" className="in" id="tooltip-bottom">
                                            {label}
                                        </Tooltip>
                                    </StyledCard>
                                </Col>
                            )) :
                                <Col>
                                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="info">
                                        <p className='w-100 mb-0'>This task has not been assigned to any staff member.</p>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                    </DetailContainer>
                </TaskContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(TaskView);