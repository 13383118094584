import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
const { REACT_APP_APPLICATION_ID, REACT_APP_CLIENT_TOKEN, REACT_APP_SERVICE, REACT_APP_DATADOG_ENV } = process.env;
axios.interceptors.response.use(res => {
  return res;
}, err => {
  if (err.response?.status === 401 && err.response.data.error !== 'Invalid email or password') {
    Cookies.remove('token');
    localStorage.clear('state');
    window.location.href = '/signin';
  }
  return Promise.reject(err);
}
);

datadogRum.init({
  applicationId: REACT_APP_APPLICATION_ID,
  clientToken: REACT_APP_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: REACT_APP_SERVICE,
  env: REACT_APP_DATADOG_ENV,
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
