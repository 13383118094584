import { Alert, Button, Container, Modal, Spinner } from "react-bootstrap";
import { CrosseArrowButton, GroupTrainingContainer, HeaderContainer, HeaderText, TableContainer, TableHead } from "./Style";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import pencil from '../../assets/images/pencil-green.svg';

import Trash from '../../assets/images/trash.svg';
import PlayButton from '../../assets/images/glyph-play-green.svg';
import { formatTimeInHmm } from "../../helpers";
import Swal from "sweetalert2";
import { TransparentButton } from "../../components";


const GroupTrainingList = () => {
    const loginUser = useSelector(state => state.user.currentUser);

    const [trainingList, setTrainingList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [userViewModalOpen, setUserViewModal] = useState(false);
    const [selectedTrainingDetails, setSelectedTrainingDetails] = useState({ users: [] });
    const history = useHistory();

    useEffect(() => {
        getTrainingList();
    }, []);


    const getTrainingDetails = async (id) => {
        try {
            setUserViewModal(true);
            setUserLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/group_training_details/${id}`);
            setSelectedTrainingDetails(payload);
            setUserLoading(false);
        } catch (error) {
            setUserLoading(false);
            console.log("🚀 ~ trainingDetails ~ error:", error);
        }
    }

    const getTrainingList = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/group_training/list/${loginUser.practice._id}/${loginUser.location._id}`);
            setTrainingList(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getTrainingStatus = (tr) => {
        if (!tr.started) {
            return "Not started";
        } else if (tr.completed) {
            return "Completed";
        } else if (tr.paused) {
            return "Paused";
        } else {
            return "Started";
        };
    };

    const deleteGroupTraining = async (id) => {
        try {
            await axios.put(`/api/v2/group_training/delete/${id}`);
            Swal.fire({
                icon: 'success',
                title: 'Training has been deleted successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            });
            await getTrainingList();
        } catch (error) {
            console.log("🚀 ~ deleteGroupTraining ~ error:", error)
        }
    };

    return (
        <GroupTrainingContainer>
            <HeaderContainer className="mb-4 pb-2">
                <HeaderText>Group Training List</HeaderText>
                <div className="d-flex justify-content-end">
                    <Button onClick={() => history.push('/training')} className="px-4 mr-2" variant="light" type='button'>{"<< Back"}</Button>
                    <Button onClick={() => history.push('/training/group')} className="px-4" variant="success" type='button'>Create New</Button>
                </div>
            </HeaderContainer>
            <TableContainer className='mt-2 mx-2' maxHeight='60vh'>
                <table className='table'>
                    <TableHead>
                        <tr>
                            <th className='w-15 pl-4'>Training Name</th>
                            <th className='w-10'>Completed Duration</th>
                            <th className='w-10'>Total Duration</th>
                            <th className="w-10">Users</th>
                            <th className="w-10">Start Date</th>
                            <th className='w-15'>End Date</th>
                            <th className='w-15'>Status</th>
                            <th className='w-10'>Action</th>
                        </tr>
                    </TableHead>
                    {!loading &&
                        <tbody>
                            {trainingList.map((training) => (
                                <tr className='justify-content-between align-items-center' key={training._id}>
                                    <td className='w-15 pl-4'>{training.trainingName}</td>
                                    <td className="w-10">{formatTimeInHmm(training.completedDuration) || '0 min'}</td>
                                    <td className="w-10">{formatTimeInHmm(training.totalDuration) || '0 min'}</td>
                                    <td className="w-10">
                                        <CrosseArrowButton onClick={() => getTrainingDetails(training._id)}>Show </CrosseArrowButton>
                                    </td>
                                    <td className="w-10">{moment(training.start_time).format('MM/DD/YYYY HH:mm A') || '--'}</td>
                                    <td className='w-15'>{training.end_time ? moment(training.end_time).format('MM/DD/YYYY HH:mm A') : '--'}</td>
                                    <td className='w-15'>{getTrainingStatus(training) || 'Pending'}</td>
                                    <td className='w-10'>
                                        <TransparentButton disabled={training.completed} onClick={() => history.push('/training/group/view?id=' + training._id)} className='mr-2' type='button'>
                                            <img src={PlayButton} alt="Resume training" />
                                        </TransparentButton>
                                        {!training.started &&
                                            <>
                                                <TransparentButton disabled={training.completed} onClick={() => history.push('/training/group?id=' + training._id)} className='mr-2' type='button'>
                                                    <img src={pencil} alt="Edit training" />
                                                </TransparentButton>
                                                <TransparentButton onClick={() => deleteGroupTraining(training._id)} className='mr-2' type='button'>
                                                    <img src={Trash} alt="Remove user" />
                                                </TransparentButton>
                                            </>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
                {(!loading && !trainingList.length) ?
                    <Alert className='my-2' style={{ 'maxWidth': '100%' }} variant="info">
                        <p className='w-100 mb-0 text-center'>No group training created yet.</p>
                    </Alert> : null
                }
            </TableContainer>
            {
                userViewModalOpen &&
                <Modal
                    centered
                    animation={true}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                    show={userViewModalOpen}>
                    {userLoading ?
                        <Container className='text-center my-5'>
                            <Spinner animation="border" variant="success" />
                        </Container> :
                        <>
                            <Modal.Header>
                                <Modal.Title>{selectedTrainingDetails.trainingName}'s Assigned users</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <table className='table'>
                                        <TableHead>
                                            <tr>
                                                <th className='w-100 pl-4 text-center'>Name</th>
                                            </tr>
                                        </TableHead>
                                        {!loading &&
                                            <tbody>
                                                {selectedTrainingDetails.users.map((user) => (
                                                    <tr key={user._id}>
                                                        <td className='w-100 pl-4 text-center'>{user.fullName + (user._id === loginUser._id ? ' (Self)' : '')}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        }
                                    </table>
                                </Container>
                            </Modal.Body>
                        </>
                    }
                    <Container className="text-center mb-4">
                        <Button type="button" onClick={() => { setSelectedTrainingDetails({ users: [] }); setUserViewModal(false) }} variant="danger">Close</Button>
                    </Container>
                </Modal>
            }
        </GroupTrainingContainer>
    )
}

export default GroupTrainingList;