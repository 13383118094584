import actions from './actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiUrl } from '../helpers';
import { loadUser } from './userActions';
import { getSafetyOfficer } from './accountActions';

export const adminRequest = (loading) => ({
  type: actions.ADMIN_REQUEST,
  loading
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} members
 */
const loadMembersSuccess = (members) => ({
  type: actions.LOAD_MEMBERS_SUCCESS,
  members
});

/**
 * @method
 * @memberOf adminActions
 */
const loadMembersFailure = () => ({
  type: actions.LOAD_MEMBERS_FAILURE
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} roles
 */
const loadRolesSuccess = (roles) => ({
  type: actions.LOAD_ROLES_SUCCESS,
  roles
});

/**
 * @method
 * @memberOf adminActions
 */
const loadRolesFailure = () => ({
  type: actions.LOAD_ROLES_FAILURE
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} roles
 */
const loadAdminRolesSuccess = (roles) => ({
  type: actions.LOAD_ADMIN_ROLES_SUCCESS,
  roles
});

/**
 * @method
 * @memberOf adminActions
 */
const loadAdminRolesFailure = () => ({
  type: actions.LOAD_ADMIN_ROLES_FAILURE
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} equipment
 */
const loadEquipmentSuccess = (equipment) => ({
  type: actions.LOAD_EQUIPMENT_SUCCESS,
  equipment
});

/**
 * @method
 * @memberOf adminActions
 */
const loadEquipmentFailure = () => ({
  type: actions.LOAD_EQUIPMENT_FAILURE
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} locations
 */
const loadLocationsSuccess = (locations) => ({
  type: actions.LOAD_LOCATIONS_SUCCESS,
  locations
});

/**
 * @method
 * @memberOf adminActions
 */
const loadLocationsFailure = () => ({
  type: actions.LOAD_EQUIPMENT_FAILURE
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} role
 */
export const selectRole = (role) => ({
  type: actions.SELECT_ROLE,
  role
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} equipment
 */
export const selectEquipment = (equipment) => ({
  type: actions.SELECT_EQUIPMENT,
  equipment
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} equipment
 */
export const selectTask = (task) => ({
  type: actions.SELECT_TASK,
  task
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} location
 */
export const selectLocation = (location) => ({
  type: actions.SELECT_LOCATION,
  location
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} newSelectedMember
 */
const sendNewCodeSuccess = (newSelectedMember) => ({
  type: actions.SEND_NEW_CODE_SUCCESS,
  newSelectedMember
});

/**
 * @method
 * @memberOf adminActions
 * @param {*} newTab
 */
export const selectTab = (newTab) => ({
  type: actions.SELECT_TAB,
  newTab
});

/**
 * @method
 * @memberOf adminActions
 */
export const deleteLocationSuccess = () => ({
  type: actions.DELETE_LOCATION_SUCCESS
});

/**
 * @method
 * @memberOf adminActions
 */
const updateLocationSuccess = (location) => ({
  type: actions.UPDATE_LOCATION_SUCCESS,
  location
});

/**
 * @method
 * @memberOf adminActions
 */
const updateLocationFailure = () => ({
  type: actions.UPDATE_LOCATION_FAILURE
});

/**
 * @method
 * @memberOf adminActions
 */
const getPracticeStripeDataSuccess = (stripe) => ({
  type: actions.GET_PRACTICE_STRIPE_DATA_SUCCESS,
  stripe
});

/**
 * @method
 * @memberOf adminActions
 */
const getPracticeStripeDataFailure = () => ({
  type: actions.GET_PRACTICE_STRIPE_DATA_FAILURE
});

/**
 * @method
 * @memberOf adminActions
 */
const getPracticeStripeInvoicesSuccess = (invoices) => ({
  type: actions.GET_PRACTICE_STRIPE_INVOICES_SUCCESS,
  invoices
});

/**
 * @method
 * @memberOf adminActions
 */
const getPracticeStripeInvoicesFailure = () => ({
  type: actions.GET_PRACTICE_STRIPE_INVOICES_FAILURE
});

/**
 * @method
 * @async
 * @memberOf adminActions
 */
export const loadMembers = () => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    const { data: members } = await axios.get(
      apiUrl(`/api/v1/users?locationId=${currentUser.location._id}`)
    );
    dispatch(loadMembersSuccess(members));
  } catch (err) {
    console.error('failed loadMembers: ', err);
    toast.error(`error in loadMembers: ${err}`);
    dispatch(loadMembersFailure());
  }
};

/**
 * returns all of the roles that the admin has access to
 * @method
 * @async
 * @memberOf adminActions
 */
export const loadAdminRoles = () => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    const { data: roles } = await axios.get(
      apiUrl(`/api/v1/roles/master/${currentUser._id}`)
    );
    dispatch(loadAdminRolesSuccess(roles));
  } catch (err) {
    console.error(`error is ${err}`);
    dispatch(loadAdminRolesFailure());
  }
};

/**
 * returns the roles for a location
 * @method
 * @async
 * @memberOf adminActions
 */
export const loadRoles = () => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    const { data: roles } = await axios.get(
      apiUrl(`/api/v1/roles?locationId=${currentUser.location._id}`)
    );
    dispatch(loadRolesSuccess(roles));
  } catch (err) {
    console.error(`error is ${err}`);
    dispatch(loadRolesFailure());
  }
};

/**
 * @method
 * @async
 * @memberOf adminActions
 */
const loadSafetyEquipment = () => async (dispatch, getState) => {
  try {
    dispatch(adminRequest(true));
    const {
      user: { currentUser }
    } = getState();
    const { data: equipment } = await axios.get(
      apiUrl(`/api/v1/safetyEquipment?locationId=${currentUser.location._id}`)
    );
    dispatch(adminRequest(false));
    dispatch(loadEquipmentSuccess(equipment));
  } catch (err) {
    dispatch(adminRequest(false));
    dispatch(loadEquipmentFailure());
  }
};

/**
 * @method
 * @async
 * @memberOf adminActions
 */
export const loadLocations = () => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    const { data: locations } = await axios.get(apiUrl(`/api/v1/locations?practiceId=${currentUser.practice._id}`));
    dispatch(loadLocationsSuccess(locations));
  } catch (err) {
    dispatch(loadLocationsFailure());
  }
};

/**
 * @method
 * @async
 * @memberOf adminActions
 * @param {String} item The type of default items you want, e.g., locationTypes, options, roles
 */
export const getDefaults = (item) => async (dispatch) => {
  try {
    const { data } = await axios.get(apiUrl(`/api/v1/locations/defaults/${item}`));
    dispatch(getDefaultsSuccess(item.replace(/^\w/, (c) => c.toLowerCase()), data));
  } catch (err) {
    dispatch(getDefaultsFailure());
    toast.error(`error getting defaults for ${item}: ${err}`);
  }
};

/**
 * @method
 * @memberOf adminActions
 * @param {*} defaultOptions
 */
const getDefaultsSuccess = (item, defaults) => ({
  type: actions.GET_DEFAULTS_SUCCESS,
  item: item,
  defaults
});
/**
 * @method
 * @memberOf adminActions
 * @param {*}
 */
const getDefaultsFailure = () => ({
  type: actions.GET_DEFAULTS_FAILURE
});

/**
 * @method
 * @async
 * @memberOf adminActions
 */
export const sendNewCode = () => async (dispatch, getState) => {
  try {
    const {
      admin: { selectedMember }
    } = getState();
    let newSelectedMember;
    await axios.put(apiUrl(`/api/v1/users/${selectedMember._id}/newJoinCode`));
    await dispatch(loadMembers());
    const {
      admin: { members }
    } = getState();
    members.forEach((member) => {
      if (member._id === selectedMember._id) {
        return (newSelectedMember = { ...member });
      } else return;
    });
    return dispatch(sendNewCodeSuccess(newSelectedMember));
  } catch (err) {
    console.error('====================================');
    console.error(err);
    console.error('====================================');
  }
};

/**
 * @method
 * @memberOf adminActions
 */
const removeFromPracticeSuccess = () => ({
  type: actions.REMOVE_FROM_PRACTICE_SUCCESS
});

/**
 * @method
 * @async
 * @memberOf adminActions
 */
export const removeUser = (userId) => async (dispatch, getState) => {
  try {
    await dispatch(adminRequest(true));
    const {
      user: { currentUser }
    } = getState();
    await axios.delete(apiUrl(`/api/v1/users/${userId}`));
    toast.success('User deleted successfully.');

    await dispatch(loadMembers());
    await dispatch(getSafetyOfficer(currentUser.location._id));
    await dispatch(removeFromPracticeSuccess());
    await dispatch(adminRequest(false));
    return true
  } catch (err) {
    if (err.response.status === 405) {
      toast.error(err.response.data.message);
    };
    await dispatch(adminRequest(false));
    return false;
  }
};

/**
 * @method
 * @memberOf adminActions
 * @param {*} member
 */
export const selectMember = (member) => {
  return {
    type: actions.SELECT_MEMBER,
    member
  };
};

/**
 * @method
 * @async
 * @memberOf adminActions
 * @param {*} id
 * @param {*} userObj
 */
export const saveEdits = (id, userObj) => async (dispatch, getState) => {
  try {
    await dispatch(adminRequest(true));
    const { user: { currentUser } } = getState();
    userObj.roles = userObj.newRoles;
    userObj.locations = userObj.newLocations;
    if (userObj.currentLocationIndex > userObj.locations.length - 1) {
      userObj.currentLocationIndex = userObj.locations.length - 1;
    };
    const { data } = await axios.put(apiUrl(`/api/v1/users/${id}`), { ...userObj });
    await dispatch(loadUser(currentUser));
    await dispatch(loadAdmin());
    await dispatch(selectMember(data));
    toast.success('User details updated successfully.');
    await dispatch(adminRequest(false));
    return true;
  } catch (err) {
    if (err.response && err.response.status === 409) {
      toast.error('User email already exists');
    } else {
      toast.error(`error in saveEdits: ${err}`);
    }
    await dispatch(adminRequest(false));
    return false
  }
};

/**
 * @method
 * @memberOf adminActions
 */
export const loadAdmin = () => (dispatch) => {
  try {
    dispatch(loadMembers());
    dispatch(loadRoles());
    dispatch(loadAdminRoles());
    dispatch(loadSafetyEquipment());
    dispatch(loadLocations());
  } catch (err) {
    console.error('====================================');
    console.error(err);
    console.error('====================================');
  }
};

/**
 * @method
 * @memberOf taskActions
 * @param {*} allTasksForCurrentLocation
 */
const loadAllTasksForCurrentLocationSuccess = (allTasksForCurrentLocation) => ({
  type: actions.LOAD_ALL_TASKS_SUCCESS,
  allTasksForCurrentLocation
});
/**
 * @method
 * @async
 * @memberOf taskActions
 * @param {*} locationId
 */
export const getAllTasksForLocation = (locationId) => async (dispatch) => {
  try {
    const { data: allTasksForCurrentLocation } = await axios.get(
      apiUrl(`/api/v1/allTask?locationId=${locationId}`)
    );
    await allTasksForCurrentLocation.map(async (task) => {
      task.user = [];
      task.isCancelled = true;
      await task.userWithActiveTag.map((el) => {
        if (el.isCancelled === false && el.userId) {
          task.user.push(el.userId);
          task.isCancelled = false;
        }
      });
    });
    await dispatch(loadAllTasksForCurrentLocationSuccess(allTasksForCurrentLocation));
    return;
  } catch (error) {
    return error;
  }
};
/**
 * @method
 * @async
 * @memberOf taskActions
 * @param {*} task{}
 */
export const createNewCustomTask = (task) => async (dispatch) => {
  try {
    dispatch(adminRequest(true));
    const { data } = await axios.post(apiUrl(`/api/v1/allTask`), { ...task, frequency: '99', interval: 0 });
    // dispatch(getAllTasksForLocation(data.location));
    dispatch(adminRequest(false));
    return data;
  } catch (err) {
    if (err.response.status === 409) {
      toast.error(`Task with the same name already exist.`);
    };
    dispatch(adminRequest(false));
    return false;
  }
};

export const updateCustomTask = (task) => async (dispatch, getState) => {
  try {
    await dispatch(adminRequest(true));
    const { data } = await axios.put(apiUrl(`/api/v1/updateTask`), { ...task, frequency: '99', interval: 0 });
    // await dispatch(getAllTasksForLocation(data.location));
    await dispatch(adminRequest(false));
    return data;
  } catch (error) {
    await dispatch(adminRequest(false));
    return false;
  }
};

export const enableDisableTask = (task) => async (dispatch) => {
  try {
    await dispatch(adminRequest(true));
    const { data } = await axios.put(apiUrl(`/api/v1/enableDisableTask`), task);
    // dispatch(getAllTasksForLocation(task.location));
    await dispatch(adminRequest(false));
    return data;
  } catch (error) {
    await dispatch(adminRequest(false));
    return error;
  }
};
