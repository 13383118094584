import axios from 'axios';
import { useEffect, useState } from 'react';
import { Alert, Form, Col, Container, Modal, Row, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';
import moment from 'moment';
import Swal from 'sweetalert2';

import { Button } from '../Button';
import { Input } from '../Input';
import { DropDownStyle } from '../../helpers';
import { DateInput } from '../DateInput';

const StyledLabel = styled.label`
    font-weight: 600;
    font-size: 11px;
    color: var(--light-text-color);
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
`;

const SubHeaderSection = styled.div`
    display: flex;
    justify-content:space-between;
    padding:0px !important;
    margin-left:15px !important;
    margin-right:15px !important;
    border-bottom: 1px solid #4FB973;
`;

const SubHeader = styled.h6`
    color:#4FB973;
    margin:0px !important;
    font-weight: 600 !important;
`;

const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight:600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
`;

const AddEditTask = ({ task, closePopUpModal, show, user, afterTaskUpdateOrAdd }) => {

    const [taskDetails, setTaskDetails] = useState({ name: '', instructions: '', frequency: undefined, taskType: undefined, users: [], location: user.location._id, practice: user.practice._id, custom: true });

    const [loading, setLoading] = useState(false);
    const [saveOrUpdateInProgress, setSaveOrUpdateInProgress] = useState(false);
    const [members, setMembers] = useState([]);
    const [error, setError] = useState({ apiError: false });
    const [formValidate, setFormValidate] = useState(false);

    useEffect(() => {
        getMasterData();
    }, []);

    const getTask = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/task/' + task);
            if (!payload.users) payload.users = [];
            setTaskDetails(payload);
            setLoading(false);
        } catch (error) {
            setError({ apiError: true });
            setLoading(false);
        }
    };

    const getMasterData = async () => {
        try {
            setLoading(true);

            const { data: { payload: members } } = await axios.get(`/api/v2/master/users_by_location/${user.location._id}`);
            setMembers(members);

            if (task) {
                await getTask();
            } else {
                setLoading(false);
            };
        } catch (error) {
            setError({ apiError: true });
            setLoading(false);
        }
    };

    const onModelChange = (name, value) => {
        const temp = { ...taskDetails };
        if (name === 'date') {
            temp.due_date = moment(value).format('MM/DD/YYYY');
        } else if (name === 'users') {
            value = Array.isArray(value) ? value : [value];
            temp.users = value.map(u => u.value);
        } else {
            temp[name] = value;
        };
        setTaskDetails(temp);
    };

    const saveOrUpdate = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        try {
            if (!form.checkValidity()) {
                e.preventDefault();
                e.stopPropagation();
                setFormValidate(true);
            } else if (taskDetails.frequency !== undefined && taskDetails.taskType !== undefined && (taskDetails.frequency !== '99' || taskDetails.due_date)) {
                setSaveOrUpdateInProgress(true);
                const task = taskDetails;
                if (task.taskType === 'private') {
                    task.users = [task.users[0]];
                };
                if (!taskDetails._id) {
                    await axios.post('/api/v2/task', taskDetails);
                    await afterTaskUpdateOrAdd();
                    setSaveOrUpdateInProgress(false);
                    closePopUpModal(true);
                } else {
                    await axios.put(`/api/v2/task/${taskDetails._id}`, taskDetails);
                    await afterTaskUpdateOrAdd();
                    setSaveOrUpdateInProgress(false);
                    closePopUpModal(true);
                };
                setFormValidate(false);
                if (taskDetails._id) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Task has been updated successfully',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Task has been added successfully',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                }
            };
        } catch (error) {
            console.log("🚀 ~ saveOrUpdate ~ error:", error);
            if (error.response?.status === 409) {
                setError({ apiError: true, errorMsg: error.response.data.error });
            } else {
                setError({ apiError: true });
            };
            setSaveOrUpdateInProgress(false);
        }
    };

    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='lg' show={show}>
            <Modal.Header >
                <Modal.Title>{task ? 'Edit' : 'Add new'} task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {loading ?
                        <Container className='text-center my-3'>
                            <Spinner animation="border" variant="success" />
                        </Container> :
                        <>
                            <SubHeaderSection>
                                <SubHeader>Task Details</SubHeader>
                            </SubHeaderSection>
                            <Form noValidate validated={formValidate} onSubmit={saveOrUpdate}>
                                <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                                    <Row>
                                        <Col>
                                            <Input disable={!taskDetails.custom} required={true} onChange={(e) => onModelChange('name', e.target.value)} label='Task Name' type='text' placeholder='Task Name' value={taskDetails.name} />
                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <StyledLabel>Instruction <span style={{ color: "red", marginLeft: "3px" }}>*</span></StyledLabel>
                                            <br />
                                            <textarea
                                                disabled={!taskDetails.custom}
                                                name='instructions'
                                                placeholder='Instruction'
                                                value={taskDetails.instructions}
                                                rows='5'
                                                wrap='off'
                                                className='w-100 px-3 py-2'
                                                onChange={(e) => { onModelChange('instructions', e.target.value) }}
                                                required
                                            />
                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6' md='6' sm='12'>
                                            <StyledLabel>Task Frequency <span style={{ color: "red", marginLeft: "3px" }}>*</span></StyledLabel>
                                            <br />
                                            <ToggleButtonGroup required name='frequency' type="radio" value={taskDetails.frequency} onChange={(val) => onModelChange('frequency', val)}>
                                                <ToggleButton disabled={!taskDetails.custom && taskDetails.frequency != 99} required variant="outline-success" name='Once' id="tbg-btn-1" value='99'>
                                                    Once
                                                </ToggleButton>
                                                <ToggleButton disabled={!taskDetails.custom && taskDetails.frequency != 0} required variant="outline-success" name='Weekly' id="tbg-btn-2" value='0'>
                                                    Weekly
                                                </ToggleButton>
                                                <ToggleButton disabled={!taskDetails.custom && taskDetails.frequency != 1} required variant="outline-success" name='Monthly' id="tbg-btn-3" value='1'>
                                                    Monthly
                                                </ToggleButton>
                                                <ToggleButton disabled={!taskDetails.custom && taskDetails.frequency != 2} required variant="outline-success" name='Quarterly' id="tbg-btn-4" value='2'>
                                                    Quarterly
                                                </ToggleButton>
                                                <ToggleButton disabled={!taskDetails.custom && taskDetails.frequency != 3} required variant="outline-success" name='Yearly' id="tbg-btn-5" value='3'>
                                                    Yearly
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                            {(taskDetails.frequency === undefined && formValidate) && <p style={{ fontWeight: '600', fontSize: '1rem' }} className='my-0 text-danger'>Required</p>}
                                        </Col>
                                        <Col lg='6' md='6' sm='12'>
                                            <StyledLabel>Task Type <span style={{ color: "red", marginLeft: "3px" }}>*</span></StyledLabel>
                                            <br />
                                            <ToggleButtonGroup required name='taskType' type="radio" value={taskDetails.taskType} onChange={(val) => onModelChange('taskType', val)}>
                                                <ToggleButton disabled={!taskDetails.custom && taskDetails.taskType !== 'global'} required variant="outline-success" name='Global' id="tbg-btn-1" value='global'>
                                                    Global
                                                </ToggleButton>
                                                <ToggleButton disabled={!taskDetails.custom && taskDetails.taskType !== 'private'} required variant="outline-success" name='Private' id="tbg-btn-2" value='private'>
                                                    Private
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                            {(taskDetails.taskType === undefined && formValidate) && <p style={{ fontWeight: '600', fontSize: '1rem' }} className='my-0 text-danger'>Required</p>}
                                        </Col>
                                    </Row>
                                    {taskDetails.frequency == '99' &&
                                        <Row>
                                            <Col>
                                                <StyledLabel>Due Date <span style={{ color: "red", marginLeft: "3px" }}>*</span></StyledLabel>
                                                <DateInput
                                                    disabled={!taskDetails.custom}
                                                    minDate={new Date()}
                                                    value={taskDetails.due_date ? new Date(taskDetails.due_date) : ''}
                                                    placeholder='mm-dd-yyyy'
                                                    width={'100%'}
                                                    placeholderText='Any'
                                                    dateFormat='MM-dd-yyyy'
                                                    onChange={(e) => onModelChange('date', e)}
                                                    required={true}
                                                />
                                                {(!taskDetails.due_date && formValidate) && <p style={{ fontWeight: '600', fontSize: '1rem' }} className='my-0 text-danger'>Required</p>}
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <StyledLabel>Staff Members <span style={{ color: "red", marginLeft: "3px" }}>*</span></StyledLabel>
                                            <Select
                                                name='user'
                                                label='ASSIGN TO'
                                                options={members}
                                                onChange={(e) => onModelChange('users', e)}
                                                isMulti={taskDetails.taskType === 'global' ? true : false}
                                                defaultValue={members.filter((mem) => taskDetails.users.includes(mem.value))}
                                                menuPosition="fixed"
                                                styles={DropDownStyle}
                                                required
                                            />
                                            {(!taskDetails.users.length && formValidate) && <p style={{ fontWeight: '600', fontSize: '1rem' }} className='my-0 text-danger'>Required</p>}
                                        </Col>
                                    </Row>
                                    {!taskDetails.custom &&
                                        <Row>
                                            <Col>
                                                <Alert className='my-2' style={{ 'maxWidth': '100%' }} variant="info">
                                                    <p className='w-100 mb-0'>This is a default task that you can only assign to a user, not edit.</p>
                                                </Alert>
                                            </Col>
                                        </Row>
                                    }
                                </Container>
                                <Container>
                                    {error.apiError &&
                                        <Row>
                                            <Col>
                                                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                                    onClose={() => setError({ ...error, apiError: false, errorMsg: '' })} dismissible>
                                                    <p className='w-100 mb-0'>{error.errorMsg ? error.errorMsg : taskDetails._id ? 'Error at updating task.' : 'Error at adding task.'}</p>
                                                </Alert>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className='mt-3'>
                                        <Col>
                                            <Button className='my-0' type='button' onClick={() => closePopUpModal()}>Cancel</Button>
                                        </Col>
                                        <Col>
                                            <Button disabled={saveOrUpdateInProgress} className='my-0' type='submit' >{taskDetails._id ? (saveOrUpdateInProgress ? 'Updating...' : 'Update') : (saveOrUpdateInProgress ? 'Saving...' : 'Save')}</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </>
                    }
                </Container>
            </Modal.Body>
        </Modal >
    );
}

export default AddEditTask;