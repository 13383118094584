import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Card, Figure, Row, Col, Alert, Spinner } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

import check from '../../assets/roles-icon/check.svg';
import fluidResistantGown from '../../assets/safety-equipment/fluidResistantGown.svg';
import safetyGoggles from '../../assets/safety-equipment/safetyGoggles.svg';
import latexGloves from '../../assets/safety-equipment/latexGloves.svg';
import heavyDutyGloves from '../../assets/safety-equipment/heavyDutyGloves.svg';
import mask from '../../assets/safety-equipment/mask.svg';
import customSafetyEquipment from '../../assets/safety-equipment/customSafetyEquipment.svg';
import faceShieldApron from '../../assets/safety-equipment/faceShieldApron.svg';
import leadApron from '../../assets/safety-equipment/leadApron.svg';
import clinicalJacket from '../../assets/safety-equipment/clinicalJacket.svg';
import pencil from '../../assets/images/white_pencil.svg';

import styled from 'styled-components';
import { AddEditRole, Button } from '../../components';
import { WhiteButton } from './Style';

const ContentHeader = styled.h5`
    border-bottom: 1px solid #ddd;
    width: 100%;
    margin: 0 1.5rem 1rem;
    color:#676767;
    padding-bottom: 1rem;

    @media screen and (max-device-width:450px) and (orientation: portrait) {
    margin-left: 1.5rem;
    }
    @media screen and (max-device-width:450px) and (orientation: landscape) {
    margin-left: 1.5rem;
    }
`;

const RoleContainer = styled.div``;

const FormHeader = styled.div`
    @media screen and (max-device-width:4000px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;

const CustomCard = styled(Card)`
    cursor: pointer;
    border:1px solid #EFEFEF !important;
    border-radius:7px !important;
    margin-bottom: 0.5rem!important;
`;

const CardBody = styled(Card.Body)`
:hover {
    background: #4FB973;
    transition: all 0.2s ease-out;

    .text-success {
    color: #fff !important;
    }

    .figure-caption {
    color: #fff !important;
    }
}
.figure-caption {
    font-size:1rem;
    }
`;

const IconContainer = styled(Figure)`
    margin: 0;
    display: flex !important;
`;

const ImageContainer = styled(Figure.Image)`
    margin-right: 0.875rem;
    align-self: flex-start;
`;

const NameBlock = styled.span`
    padding:8px 8px
    border-radius:50%;
    background-color:#E7F3EB;
    color:#4FB973;
    margin-right: 1rem;
    font-size: 0.875em
    text-transform: uppercase;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
`;

const StyledCard = styled.div`
    background:#fff;
    border:1px solid #EFEFEF;
    padding:10px;
    box-shadow:none;
    border-radius:10px;
    color:#000 !important; 
    margin-bottom: 1rem!important;
    text-align: left;
    display: flex;
    width:100%;
    align-items: center;
    img{
        margin-right:1rem;
    }
    h6{
        @media screen and (max-device-width:450px) and (orientation: portrait) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 168px;
        }
        @media screen and (max-device-width:450px) and (orientation: landscape) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 168px;
        }
    }
`;

const DetailContainer = styled.div`
    overflow-y: auto;
    max-height:60vh;
`;

const NameBlockElement = ({ firstName, lastName }) => {
    return (
        <NameBlock>
            {firstName[0] + lastName[0]}
        </NameBlock>
    );
};

class RoleView extends Component {
    state = {
        loading: false,
        roleDetails: { name: '', description: '', jobTasks: [], defaultEquipment: [] },
        members: [],
        safetyEquipments: [],
        errors: {},
        addOrEditRole: false
    }

    componentDidMount() {
        this.getRole();
    };

    getRole = async () => {
        try {
            this.setState({ loading: true });
            const { history: { location } } = this.props;
            const searchParams = new URLSearchParams(location.search);
            const roleId = searchParams.get('roleId');
            const { data: { payload } } = await axios.get(`/api/v2/role/${roleId}`);
            await this.getMasterData(payload);
            this.setState({ roleDetails: payload, loading: false });
        } catch (error) {
            this.setState({ loading: false, errors: { apiError: true, } });
            return {};
        }
    };

    getMasterData = async (role) => {
        try {
            const { user } = this.props;
            const { data: { payload: members } } = await axios.get(`/api/v2/master/users_by_role/${role._id}`);
            let { data: { payload: safetyEquipments } } = await axios.get(`/api/v2/master/safetyequipments/${user.location._id}`);
            safetyEquipments = safetyEquipments.filter(({ value }) => role.defaultEquipment && role.defaultEquipment.includes(value)) || [];
            this.setState({ members, safetyEquipments, loading: false });
        } catch (error) {
            this.setState({ loading: false, errors: { apiError: true, errorMessage: 'Error at getting role.' } });
        }
    };

    editAndAddRole = async (e) => {
        e.stopPropagation();
        this.setState({ addOrEditRole: true });
    };

    closePopUpModal = () => {
        this.getRole();
        this.setState({ addOrEditRole: false });
    };

    render() {
        const { history } = this.props;
        const { loading, roleDetails: { name, description, jobTasks, default: roleType }, roleDetails, members, safetyEquipments, addOrEditRole, } = this.state;

        const icons = { faceShieldApron, fluidResistantGown, laserSafetyGoggles: safetyGoggles, eyewear: safetyGoggles, safetyGoggles, latexGloves, heavyDutyGloves, respiratoryMaskN95: mask, fitTestedRespiratoryMaskN95: mask, mask, customSafetyEquipment, leadApron, clinicalJacket };

        return (
            loading ?
                <div style={{ minHeight: '30px' }} className='text-center'>
                    <Spinner animation="border" variant="success" />
                </div> :
                <RoleContainer>
                    <div className="d-flex justify-content-between mx-2">
                        <FormHeader>
                            <h2>{name}</h2>
                            <p>{description}</p>
                        </FormHeader>
                        <div className='d-flex justify-content-between'>
                            {!roleType && <Button onClick={this.editAndAddRole} width='250' className='my-0 mr-2'>  <img style={{ cursor: 'pointer' }} src={pencil} className='mr-2' alt="Edit" />Edit</Button>}
                            <WhiteButton onClick={() => { history.goBack() }} width='250' className='my-0'>{'<< Back'}</WhiteButton>
                        </div>
                    </div>
                    <DetailContainer>
                        <Row className="mx-2">
                            <ContentHeader>Members Assigned</ContentHeader>
                            {(members && members.length) ? members.map(({ firstName, lastName, _id, fullName }) => (
                                <Col xs={12} md={4} lg={3} key={_id}>
                                    <StyledCard>
                                        <NameBlockElement firstName={firstName} lastName={lastName} /> <h6>{fullName}</h6>
                                        <Tooltip placement="bottom" className="in" id="tooltip-bottom">
                                            {fullName}
                                        </Tooltip>
                                    </StyledCard>
                                </Col>
                            )) :
                                <Col>
                                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="info">
                                        <p className='w-100 mb-0'>This role has not been assigned to any member.</p>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                        <Row className="mx-2">
                            <ContentHeader>Role Duties</ContentHeader>
                            {(jobTasks && jobTasks.length) ? jobTasks.map((task) => (
                                <Col xs={12} md={4} lg={4} className="mb-2 text-start" key={task}>
                                    <StyledCard>
                                        <img src={check} alt="Back" /> {task}
                                    </StyledCard>
                                </Col>
                            )) :
                                <Col>
                                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="info">
                                        <p className='w-100 mb-0'>This role does not have any tasks.</p>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                        <Row className="justify-content-start mx-2">
                            <ContentHeader>Safety Equipments</ContentHeader>
                            {(safetyEquipments && safetyEquipments.length) ? safetyEquipments.map((safetyEquipment) => (
                                <Col xs={12} md={4} lg={4} key={safetyEquipment._id} >
                                    <CustomCard>
                                        <CardBody>
                                            <IconContainer className="justify-content-start align-items-center">
                                                <ImageContainer
                                                    width={50}
                                                    height={50}
                                                    alt="50x50"
                                                    src={icons[safetyEquipment.id] || customSafetyEquipment}
                                                />
                                                <Figure.Caption>
                                                    {safetyEquipment.label}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            )) :
                                <Col>
                                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="info">
                                        <p className='w-100 mb-0'>This role does not have any default safety equipments.</p>
                                    </Alert>
                                </Col>
                            }
                        </Row>
                    </DetailContainer>
                    {
                        addOrEditRole &&
                        <AddEditRole addOrEditRole={addOrEditRole} closePopUpModal={this.closePopUpModal} role={roleDetails} user={this.props.user} />
                    }
                </RoleContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(RoleView);