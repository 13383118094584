import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import { Col, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import OtpInput from 'react-otp-input';

import { validateEmail } from '../../helpers';
import { Card, Input } from '../';
import { loadUser, } from '../../actions';
import LockIcon from '../../assets/images/big-lock.svg';

const CustomPhoneInput = styled(PhoneInput)`
    width: 100%;
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    background: white;
    .PhoneInputCountry{
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        padding:  7px;
    }
    input{
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        padding: 9px;
        transition: border 0.15s linear, box-shadow 0.15s linear;
        width:100%;
        margin-top:2px;
        margin-bottom:2px;
    }
`;

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
  display: block;
`;

const StyledSubtext = styled.p`
  font-size: 11px;
  color: var(--light-text-color);
  margin-left: 8px;
  margin-top: 5px;
`;

const DetailsContainer = styled(Card)`
  display: inline-block;
  max-height:75vh;
  overflow-y:auto;
  flex-direction: column;
  justify-content: center;
  width: 528px;
  padding: 25px 50px 50px 50px;
 
  @media (max-width:991px) {
    display:block;
    width:100%;
    max-height:100%;
    padding:13px; 
   }
`;

const DetailsHeader = styled.div`
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
    
  @media (max-width:768px) {
    padding-top:0;
      
    }
  h2{
    @media (max-width:480px) {
    font-size:18px;
      
        }
  }
`;

const FormInput = styled.input`
 display: block;
 padding: 10px 14px;
 font-size: 16px;
 border: 1px solid #cccccc;
 /* Add a border to the input */
 border-radius: 4px;
 box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
 transition: box-shadow 150ms ease;
 line-height: normal;
 width:100%;
`;

export const Heading = styled.p`
    color: #4fb973;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0px;
`;

export const SubHeading = styled.p`
    color: #565656;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
`;

export const OtpContainer = styled.div`
display: flex;
justify-content: center;
margin-top: 3rem;
.otpInput {
    width: 50px !important;
    height: 50px !important;
    background: #fcfcfc;
    border-radius: 5px;
    padding: 10px;
    color: #4fb973;
    font-weight: 700;
    @media (max-width: 425px) {
        width: 30px !important;
        height: 30px !important;
    }
}
`;

const StyledWrapper = styled.div`
  p {
    margin-top:0px;
    margin-bottom:0px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2px;
  margin-bottom: 2px;
  bottom: 0; 
  position: absolute;
  @media (max-width:576px) {
    bottom: 0; 
    position: relative;
  }
`;
class BasicDetails extends React.Component {
  state = {
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    email: this.props.user.email,
    phone: this.props.user.phone,
    editing: true,
    emailOtp: '',
    phoneOtp: '',
    joinCodeHash: '',
    loading: false,
    countryCode: '1',
    verificationOf: 'both',
    verificationOn: false
  };

  componentDidMount() {
    const { user, dispatch } = this.props;
    if (user && user._id) dispatch(loadUser(user));
  };

  handleChange = ({ target: { value, name } }) => this.setState({ [name]: value?.trimStart() || '' });

  validate = () => {
    const { user: { practiceAdmin } } = this.props;
    const { email, phone } = this.state;

    if (!email && !phone) {
      toast.error('Email or Phone must be needed.');
      return false;
    };
    if (email && !email.match(/[^@]+@[^@]+\.[a-zA-Z]{2,6}/)) {
      toast.error('Invalid Email address.');
      return false;
    };
    if (phone && !isValidPhoneNumber(phone)) {
      toast.error('Invalid Phone number.');
      return false;
    };
    if (practiceAdmin && !email) {
      toast.error('As a practice admin, email is a required.');
      return false;
    };
    return true;
  };

  validateAndSendOtp = async (e) => {
    try {
      e.preventDefault();
      const tempVar = (this.state.email && this.state.phone) ? 'both' : this.state.email ? 'email' : 'phone';
      this.setState({ verificationOf: tempVar });
      if (this.state.email) {
        await this.checkEmailPresent();
        await this.sendOptOnEmail();
      };
      if (this.state.phone) {
        await this.checkPhonePresent();
        await this.sendOptOnPhone();
      };
      this.setState({ verificationOn: true });
    } catch (error) {
      console.log("🚀 ~ BasicDetails ~ validateAndSendOtp=async ~ error:", error);
      throw error;
    }
  }
  checkEmailPresent = async (email) => {
    try {
      if (this.state.email && !validateEmail(this.state.email)) await axios.post('/api/v2/checkEmailId', { email, _id: currentUser._id });
    } catch (error) {
      setDataError({ email: 'User email id already present.' });
      throw error;
    }
  };

  checkPhonePresent = async () => {
    try {
      if (this.state.phone && !isValidPhoneNumber(this.state.phone)) await axios.post('/api/v2/checkPhoneId', { phone: this.state.phone, _id: currentUser._id });
    } catch (error) {
      setDataError({ phone: 'User phone already present.' });
      throw error;
    }
  };

  sendOptOnEmail = async () => {
    try {
      const { email, firstName } = this.state;
      return await axios.post('/api/v2/sendOtpOnEmail', { email, firstName, type: 'basic_detail' });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  sendOptOnPhone = async () => {
    try {
      const { phone, firstName } = this.state;
      return await axios.post('/api/v2/sendOtpOnPhone', { phone, firstName, type: 'basic_detail' });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  handleSave = async (e) => {
    e.preventDefault();
    try {
      const { dispatch, user } = this.props;
      const { firstName, lastName, email, phone, countryCode, verificationOf, emailOtp, phoneOtp } = this.state;
      const valid = this.validate();
      if (!valid) return;
      this.setState({ loading: true });
      let emailVerify = false;
      if (verificationOf === 'email' || verificationOf === 'both') {
        await axios.post('/api/v2/verifyOtp', { method: 'email', email, otp: emailOtp, type: 'basic_detail' });
        emailVerify = true;
      };
      let phoneVerify = false;
      if (verificationOf === 'phone' || verificationOf === 'both') {
        await axios.post('/api/v2/verifyOtp', { method: 'phone', phone, otp: phoneOtp, type: 'basic_detail' });
        phoneVerify = true;
      };

      await axios.put('/api/v2/account/update_basic_details/' + user._id, { firstName, lastName, email, phone, phoneVerify, emailVerify, countryCode });

      await dispatch(loadUser(user));

      this.cancelEdit();
      this.setState({ loading: false });
      Swal.fire({
        icon: 'success',
        title: 'Details has been added successfully',
        allowEnterKey: true,
        allowEscapeKey: false,
        confirmButtonColor: '#4FB973',
        confirmButtonText: 'Close',
        allowOutsideClick: false
      }).then(async () => {
        this.cancelEdit()
      });
    } catch (error) {
      this.setState({ loading: false });
      console.log('error.response.data', error);
      toast.error(error.response.data);
    }
  };

  cancelEdit = () => {
    const { firstName, lastName, phone, email } = this.props.user;

    this.setState({ firstName, lastName, phone, email, editing: true, emailOtp: '', phoneOtp: '', joinCodeHash: '', verificationOf: '', verificationOn: false });
  };

  countryChange = (e) => {
    this.setState({ countryCode: getCountryCallingCode(e) });
  };

  verifyOtp = async () => {

  };

  render() {
    const { firstName, lastName, phone, email, editing, loading, verificationOn, verificationOf, phoneOtp, emailOtp } = this.state;

    const { isActive } = this.props;

    return (
      <>
        <form onSubmit={this.validateAndSendOtp}>
          <DetailsContainer isActive={isActive}>
            <DetailsHeader>
              <h2>Basic Details</h2>
              <div style={{ display: 'flex' }}>
                {!editing ?
                  <Button disabled={loading} variant='outline-light' onClick={this.cancelEdit} className='text-dark mx-1'>Cancel</Button>
                  :
                  <Button className='mx-1' variant='success' disabled={!editing} onClick={() => this.setState({ editing: false })}>Edit</Button>
                }
                <Button className='mx-1' variant='success' type='submit' disabled={editing || loading}>{loading ? 'Saving...' : 'Save'}</Button>
              </div>
            </DetailsHeader>
            <StyledWrapper>
              <Col lg='8' md='8' sm='12' className='px-0 my-2'>
                <Input
                  label='First Name'
                  type='text'
                  name='firstName'
                  onChange={this.handleChange}
                  value={firstName}
                  placeholder='First Name'
                  subtext='Your name is visible to others in your practice.'
                  disable={editing}
                />
              </Col>
              <Col lg='8' md='8' sm='12' className='px-0 my-2'>
                <Input
                  label='Last Name'
                  type='text'
                  name='lastName'
                  onChange={this.handleChange}
                  value={lastName}
                  placeholder='Last Name'
                  subtext='Your name is visible to others in your practice.'
                  required
                  disable={editing}
                />
              </Col>
              <Col lg='8' md='8' sm='12' className='px-0 my-2'>
                <Input
                  label='Email Address'
                  type='text'
                  name='email'
                  onChange={this.handleChange}
                  value={email}
                  placeholder='Email'
                  subtext="Only visible to your practice's administrator."
                  disable={editing}
                />
              </Col>
              <Col lg='8' md='8' sm='12' className='px-0 my-2'>
                <StyledLabel>Phone Number <span className='text-danger'>*</span></StyledLabel>
                <CustomPhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry={'US'}
                  countryCallingCodeEditable={false}
                  international={true}
                  autoComplete='off'
                  placeholder="Enter phone number"
                  onChange={(e) => this.handleChange({ target: { value: e, name: 'phone' } })}
                  onCountryChange={((e) => this.countryChange(e))}
                  value={phone}
                  disabled={editing}
                />
                <StyledSubtext>Only visible to your practice's administrator.</StyledSubtext>
              </Col>
            </StyledWrapper>
          </DetailsContainer>
        </form>
        <Modal
          centered
          animation={true}
          backdrop="static"
          keyboard={false}
          size='lg' show={verificationOn}>
          <Modal.Body>
            <div>
              <Heading className="mb-3"><img src={LockIcon} alt="" /></Heading>
              <Heading>Verification</Heading>
              <SubHeading className="px-5">We just sent a code to your {verificationOf === 'both' ? 'work email and phone' : verificationOf === 'phone' ? 'phone' : 'work email'}. Please check and enter the code here.</SubHeading>
            </div>
            {(verificationOf === 'email' || verificationOf === 'both') &&
              <OtpContainer>
                <div>
                  <StyledLabel>Email OTP</StyledLabel>
                  <OtpInput
                    inputType='text'
                    inputStyle='otpInput'
                    value={emailOtp}
                    onChange={(e) => { this.setState({ emailOtp: e }) }}
                    numInputs={4}
                    renderSeparator={<span className="mx-4">-</span>}
                    renderInput={(props) => <FormInput {...props} />}
                  />
                  <p className="mt-2 text-right">Didn’t receive a code? <span onClick={this.sendOptOnEmail} className="text-success cursor-pointer">Resend code</span></p>
                </div>
              </OtpContainer>
            }
            {(verificationOf === 'phone' || verificationOf === 'both') &&
              <OtpContainer>
                <div>
                  <StyledLabel>Phone OTP</StyledLabel>
                  <OtpInput
                    inputType='text'
                    inputStyle='otpInput'
                    value={phoneOtp}
                    onChange={(e) => { this.setState({ phoneOtp: e }) }}
                    numInputs={4}
                    renderSeparator={<span className="mx-4">-</span>}
                    renderInput={(props) => <FormInput {...props} />}
                  />
                  <p className="mt-2 text-right">Didn’t receive a code? <span onClick={this.sendOptOnPhone} className="text-success cursor-pointer">Resend code</span></p>
                </div>
              </OtpContainer>
            }
            <div className='d-flex justify-content-center'>
              <Button type='button' onClick={this.cancelEdit} variant='secondary' className='mx-3 mt-5 px-3' size='lg'>Close</Button>
              <Button disabled={((verificationOf === 'email' || verificationOf === 'both') && emailOtp.length !== 4) || ((verificationOf === 'phone' || verificationOf === 'both') && phoneOtp.length !== 4) || loading} type='button' onClick={this.handleSave} size="lg" className="mt-5" variant="success">{loading ? 'Loading...' : 'Verify & Save'}</Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default connect()(BasicDetails);
