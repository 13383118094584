import { Modal } from "react-bootstrap";
import { Button } from "../Button";
import styled from 'styled-components';

export const MainContainer = styled.div`
    width: 100%;
`;

export const FooterContainer = styled.div`
    display:flex;
    justify-content:end;
`;

export const CloseButton = styled(Button)`
  border-radius: 4px;
  text-decoration: none;
  background: #fbfbfb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: 0.5px solid #eaeaea;
  color: #7f808f;
  transition: box-shadow 0.2s;
`;

export const TableContainer = styled.div`
    overflow-y:auto;
    max-height: 70vh;
    .w-40 {
        width: 40%;
    }
`;

export const TableHead = styled.thead`
    top: 0;
    background-color: #fff;
    position: sticky;
    z-index: 9;
`;

export const TitleRow = styled.div`
    font-weight: bold;
    font-size: 1.3rem;
    background-color: #F3F9F5;
    padding: 1rem;
`;

export const BodyContainer = styled.div`

`;

export const BodyRow = styled.div`
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
`;

export const BodyRowItem = styled.div`
  
`;

export const ModalDialog = styled(Modal.Dialog)`
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms, transform 300ms;
}
`;