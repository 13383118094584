import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

export const AuthRedirect = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Cookies.get('token') && props.user ? <Redirect to='/' /> : <Component {...props} />
    }
  />
);
