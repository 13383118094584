import { Container } from "react-bootstrap";
import { Checkbox } from "../Checkbox";
import { useEffect, useState } from "react";
import { TableContainer, TableHead } from "../Staff/StyleComponents";
import axios from "axios";

const UserListWithAnnualDate = ({ location, locationDetails, selectedUsers, setSelectedUsers }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUsersByLocation();
    }, []);

    const getUsersByLocation = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/master/users_by_location/${location}`);
            const user_ids = payload.map(user => user._id);
            setSelectedUsers(user_ids);
            setUsers(payload);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getUsersByLocation ~ error:", error);
            setLoading(false);
        }
    };

    const conCheckBoxChange = (value, user_id, selectAll) => {
        if (selectAll) {
            setSelectedUsers(value ? users.map(user => user._id) : []);
            return;
        }
        let temp = [...selectedUsers];
        if (value) {
            temp.push(user_id);
        } else {
            temp = selectedUsers.filter(id => user_id !== id);
        };
        setSelectedUsers(temp);
    };

    return (
        <Container>
            <TableContainer className='mt-2 mx-2' maxHeight='60vh'>
                <table className='table'>
                    <TableHead>
                        <tr>
                            <th className=''>
                                <Checkbox onChange={(e) => conCheckBoxChange(e.target.checked, '', true)} checked={users.every(user => selectedUsers.includes(user._id))} />
                            </th>
                            <th className='w-40'>Staff Name</th>
                            <th className='w-40'>Current annual review date (dd/mm)</th>
                        </tr>
                    </TableHead>
                    <tbody>
                        {(users && users.length) ?
                            users.map((user) => (
                                <tr key={user._id}>
                                    <td>
                                        <Checkbox checked={selectedUsers.includes(user._id)} onChange={(e) => conCheckBoxChange(e.target.checked, user._id)} />
                                    </td>
                                    <td>{user.fullName}</td>
                                    <td>
                                        {
                                            selectedUsers.includes(user._id) ?
                                                <>
                                                    <span style={{ 'textDecoration': 'line-through' }} className="mr-2 text-muted">{user.annual_review_date}</span>
                                                    <span>{locationDetails.annual_review_date}</span>
                                                </>
                                                :
                                                <span class="">{user.annual_review_date}</span>
                                        }
                                    </td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={3} className="text-center">User's not found...</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </TableContainer>
        </Container>
    )
};

export default UserListWithAnnualDate;