import actions from './actionTypes';

/**
 * @namespace commonActions
 * @category Actions
 */

/**
 * @method
 * @memberOf commonActions
 * @param {*} user
 */
export const openPanel = (showPanel) => ({
    type: actions.OPEN_USER_PANEL_SUCCESS,
    showPanel
});
export const openNotificationPanel = (notificationPanel) => ({
    type: actions.OPEN_NOTIFICATION_PANEL,
    notificationPanel
});
