import actions from '../actions/actionTypes';

/**
 * @namespace adminReducer
 * @category Reducers
 */

/**
 * @memberOf adminReducer
 */
const initialState = {
  members: [],
  roles: [],
  adminRoles: [],
  equipment: [],
  locations: [],
  currentTab: '',
  selectedMember: {},
  selectedRole: {},
  selectedEquipment: {},
  selectedLocation: {},
  itemSelected: false,
  tabOpen: false,
  loading: false
};

/**
 * @method
 * @memberOf adminReducer
 * @param {*} [state = initialState]
 * @param {*} action
 * */
export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.members
      };
    case actions.LOAD_MEMBERS_FAILURE:
      return {
        ...state,
        members: []
      };
    case actions.LOAD_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.roles
      };
    case actions.LOAD_ALL_TASKS_SUCCESS:
      return {
        ...state,
        allTasksForCurrentLocation: action.allTasksForCurrentLocation
      };
    case actions.LOAD_ADMIN_ROLES_SUCCESS:
      return {
        ...state,
        adminRoles: action.roles
      };
    case actions.LOAD_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipment: action.equipment
      };
    case actions.LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations
      };
    case actions.REMOVE_FROM_PRACTICE:
      return {
        ...state,
        members: action.members
      };
    case actions.GET_PRACTICE_STRIPE_DATA_SUCCESS:
      return {
        ...state,
        stripe: action.stripe
      };
    case actions.GET_PRACTICE_STRIPE_DATA_FAILURE:
      return {
        ...state,
        stripe: {}
      };
    case actions.GET_PRACTICE_STRIPE_INVOICES_SUCCESS:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          invoices: action.invoices
        }
      };
    case actions.GET_PRACTICE_STRIPE_INVOICES_FAILURE:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          invocies: []
        }
      };
    case actions.REMOVE_FROM_PRACTICE_SUCCESS:
      return {
        ...state,
        selectedMember: {},
        itemSelected: false
      };
    case actions.SELECT_TAB:
      return {
        ...state,
        currentTab: action.newTab,
        tabOpen: true
      };
    case actions.SEND_NEW_CODE_SUCCESS:
      return {
        ...state,
        selectedMember: action.newSelectedMember
      };
    case actions.SELECT_MEMBER:
      return {
        ...state,
        selectedMember: action.member,
        itemSelected: true,
        currentTab: 'staff'
      };
    case actions.SELECT_ROLE:
      return {
        ...state,
        itemSelected: true,
        selectedRole: action.role,
        currentTab: 'roles'
      };
    case actions.SELECT_EQUIPMENT:
      return {
        ...state,
        itemSelected: true,
        selectedEquipment: action.equipment,
        currentTab: 'equipment'
      };
    case actions.SELECT_TASK:
      return {
        ...state,
        itemSelected: true,
        selectedTask: action.task,
        currentTab: 'addTaskForUser'
      };
    case actions.SELECT_LOCATION:
      return {
        ...state,
        itemSelected: true,
        selectedLocation: action.location,
        currentTab: 'locations'
      };
    case actions.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        selectedLocation: {},
        itemSelected: false
      };
    case actions.ADMIN_REQUEST:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
};
