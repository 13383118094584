import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { loadState, saveState } from './localStorage.js';

// if you don't want to persist the storage between browser sessions, switch these two lines:
const persistedState = loadState();
//const persistedState = {};
let composeEnhancers;
let store = createStore(
  reducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

if (typeof window !== 'undefined') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    reducer,
    persistedState,
    composeEnhancers(applyMiddleware(thunk))
  );
  store.subscribe(() => {
    saveState(store.getState());
  });
}

export default store;
