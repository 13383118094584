import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { CompanyInfoContainer, ErrorContainer, FormInput, FormLabel, HeaderContainer, Heading, SectionTitle, SubHeading } from "./Style"
import { DropDownStyle, states } from '../../helpers';
import InfoIcon from '../../assets/images/info-green.svg';
import { Checkbox } from '../../components/Checkbox';
import { registerPractice } from '../../actions';
import Agreement from './Agreement';



const CompanyInfo = ({ setStepNumber }) => {
    const locationOptions = [{ label: 'One Location', value: 'single' }, { label: 'More than 1 Location', value: 'many' }];

    const [companyInfo, setCompanyInfo] = useState({ locationName: '', practiceName: '', type: '', locationAmount: '', address: '', city: '', state: '', zip: '' });
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [termAndConditions, setTermAndConditions] = useState(false);
    const [agreementSigned, setAgreementSigned] = useState(false);
    const [locationType, setLocationType] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        getMasterData();
    }, []);

    const getMasterData = async () => {
        try {
            const { data: { payload } } = await axios.get('/api/v2/master/defaultlocationtypes');
            setLocationType(payload);
        } catch (error) {
            console.log("🚀 ~ getMasterData ~ error:", error);
        }
    };

    const onChange = (name, value) => {
        const tempCompanyInfo = { ...companyInfo };
        if (name === 'zip' && value && value.length > 5) {

        } else {
            tempCompanyInfo[name] = value.trimStart();
            setCompanyInfo(tempCompanyInfo);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else if (!agreementSigned) {
            return toast.error('Please accept the terms and conditions before proceeding');
        } else {
            try {
                setLoading(true);
                await dispatch(registerPractice({ practice: companyInfo }));
                setLoading(false);
                setStepNumber(4);
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ onSubmit ~ error:", error);
            }
        };
    };

    const dummyOnchange = () => { };

    const showTermsAndConditions = (e, action) => {
        e.stopPropagation();
        setTermAndConditions(action);
    };

    return (
        <CompanyInfoContainer>
            <HeaderContainer>
                <Heading>Company Details</Heading>
                <SubHeading>Tell Us About Your Company</SubHeading>
            </HeaderContainer>
            <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Row className='mt-5'>
                    <Col lg='12' md='12' sm='12' className='mb-3'>
                        <SectionTitle>Company Details</SectionTitle>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                        <FormLabel>Practice Type <span className="text-danger">*</span></FormLabel>
                        <Select
                            placeholder="Select practice type"
                            onChange={(e) => onChange('type', e.value)}
                            options={locationType}
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={true}
                            value={locationType.find(loc => loc.value === companyInfo.type)}
                        />
                        <input type="text" value={companyInfo.type} onChange={dummyOnchange} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                        <ErrorContainer style={{ 'marginTop': '-15px' }} type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                        <FormLabel>Location Count <span className="text-danger">*</span></FormLabel>
                        <Select
                            placeholder="Location count"
                            onChange={(e) => onChange('locationAmount', e.value)}
                            options={locationOptions}
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={true}
                            value={locationOptions.find(loc => loc.value === companyInfo.locationAmount)}
                        />
                        <input type="text" value={companyInfo.locationAmount} onChange={dummyOnchange} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                        <ErrorContainer style={{ 'marginTop': '-15px' }} type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                        <FormLabel>Company Name <span className="text-danger">*</span></FormLabel>
                        <FormInput onChange={(e) => onChange('practiceName', e.target.value)} required type="text" placeholder="Enter company name" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                        <FormLabel>First Location Name <span className="text-danger">*</span></FormLabel>
                        <FormInput onChange={(e) => onChange('locationName', e.target.value)} required type="text" placeholder="Enter location name" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='12' md='12' sm='12' className='my-3'>
                        <FormLabel> <img src={InfoIcon} alt="" /> You’ll add your other locations after sign up</FormLabel>
                    </Col>
                    <Col lg='12' md='12' sm='12' className='mb-3'>
                        <SectionTitle>Location Details</SectionTitle>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                        <FormLabel>Address <span className="text-danger">*</span></FormLabel>
                        <FormInput onChange={(e) => onChange('address', e.target.value)} required type="text" placeholder="Enter address" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                        <FormLabel>City <span className="text-danger">*</span></FormLabel>
                        <FormInput onChange={(e) => onChange('city', e.target.value)} required type="text" placeholder="Enter city" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                        <FormLabel>State <span className="text-danger">*</span></FormLabel>
                        <Select
                            value={states.find(loc => loc.value === companyInfo.state)}
                            placeholder="Choose State"
                            onChange={(e) => onChange('state', e.value)}
                            options={states}
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={true}
                        />
                        <input type="text" value={companyInfo.state} onChange={dummyOnchange} required style={{ height: 0, opacity: 0, border: 'none', padding: '0px' }} />
                        <ErrorContainer style={{ 'marginTop': '-15px' }} type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='6' md='6' sm='12'>
                        <FormLabel>Zip Code <span className="text-danger">*</span></FormLabel>
                        <FormInput value={companyInfo.zip} onChange={(e) => onChange('zip', e.target.value)} required type="text" placeholder="Enter zip code" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='12' md='12' sm='12' className='my-3'>
                        <div className="d-flex">
                            <Checkbox
                                onChange={() => setAgreementSigned(!agreementSigned)}
                                name='agreementSigned'
                                checked={agreementSigned}
                            />
                            I have read and agree to the <span onClick={(e) => showTermsAndConditions(e, !termAndConditions)} className="text-success cursor-pointer">Terms of Service and Privacy Policy</span>
                        </div>
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </Col>
                    <Col lg='12' md='12' sm='12' className='my-2'>
                        <Button disabled={loading} type='submit' size="lg" className="w-100" variant="success">{loading ? 'Loading...' : 'Next'}</Button>
                    </Col>
                </Row>
            </Form>
            {termAndConditions && <Agreement onClose={setTermAndConditions} />}
        </CompanyInfoContainer >
    )
}

export default CompanyInfo;