import { Card, Col, Figure, Row } from "react-bootstrap";
import styled from "styled-components";

import eyeglass from '../../assets/images/glyph-search-gray.svg';

export const RecordListContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    margin: 6.5rem 1rem 0rem 6.5rem !important;
    border-radius:10px;

    .cursor-pointer{
        cursor: pointer;
    }
    .label{
        font-weight: 600;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
    .w-48{
        width:48% !important;
    }
`;

export const TableContainer = styled(Col)`
    border: 1px solid #EFEFEF !important;
    border-radius: 7px !important;
    .heading {
        color: #343747;
        font-size: 25px;
        font-weight: 700;
    }
    tr {
        cursor: pointer;
        &:hover {
            background-color: #F9F9F9;
        }
    }
    .selected {
            background-color: #E7F3EB;
    }
`;

export const RecordDetailsContainer = styled.div`
padding: 3rem;
height: 100%;
width: ${(props) => (props.fullWidth ? '80vw' : '30vw')};
position: relative;
background-color: #FCFCFC;
overflow-x: hidden;
font-family: 'Haus Neue';
@media screen and (max-width: 768px) {
    width: 100vw;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 80vw;
}
`;

export const OverLayContainer = styled.div`
    background-color: rgb(0 0 0 / 32%);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
`;

export const ActionButtonContainer = styled(Row)`

`;

export const RecordHeading = styled.p`
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 5px;
    color:black;
`;

export const RecordSubHeading = styled.p`
    font-size: 16px;
    color: #676767;
`;

export const StyledLabel = styled.p`
    font-size: 15px;
    font-weight: 600 !important;
    border-bottom: 1px solid  #D3D6DD;
`;


export const CustomCard = styled(Card)`
    cursor: pointer;
`;

export const CustomCardBody = styled(Card.Body)`
    :hover {
        background: #4FB973;
        transition: all 0.2s ease-out;

        .text-success {
            color: #fff !important;
        }

        .figure-caption {
            color: #fff !important;
        }
    }
    .figure-caption{
        h6{
            @media screen and (max-device-width:1024px) and (orientation: portrait) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            @media screen and (max-device-width:768px) and (orientation: landscape) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
         
        }
    }
`;

export const IconContainer = styled(Figure)`
    margin: 0;
    display: flex !important;
`;

export const ImageContainer = styled(Figure.Image)`
    margin-right: 0.875rem;
    align-self: flex-start;
`;

export const SubSectionContainer = styled(Col)`
    overflow-y: auto;
    max-height: 69vh;
     @media screen and (max-width: 768px) {
        max-height: 100%;
        padding: 0px;
    }
    
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        max-height: 100%;
        padding: 0px;
    }
`;


export const SubSectionCard = styled(Card)`
    margin-bottom: 1rem!important;
    color: ${(props) => (props.active ? '#4FB973' : '#676767')};
    border:1px solid ${(props) => (props.active ? '#4FB973' : 'none')} !important;
    box-shadow: 0px 0px 2px 0px  ${(props) => (props.active ? '#4FB973' : '#676767')};
    font-weight:500 !important;
    ${(props) => (!props.disabled ? "&:hover { border: 1.5px solid #4FB973 !important;box-shadow: 0px 0px 2px 0px '#4FB973' !important; }" : '')};
    cursor:   ${(props) => (!props.disabled ? 'pointer' : 'not-allowed')} !important;
`;

export const SubSectionCardBody = styled(Card.Body)`
    display: flex;
    align-items: center;
`;

export const Label = styled.span`
    font-size:16px;
    font-weight:500;
    color: ${(props) => (props.textColor ? props.textColor : '#343747')}
    margin-bottom: 0px;
`;

export const FooterButtonsRow = styled(Row)`
    Justify-content: end;
`;

export const QuestionSectionContainer = styled(Col)`
    overflow-y: auto;
    height: 69vh;
    input[disabled] {
    cursor: not-allowed;
    opacity: 0.7; /* Optionally, you can reduce the opacity to indicate it's disabled */
}
    @media screen and (max-width: 768px) {
        height: 100%;
        padding: 0px;
    }
    
    @media screen and (min-width: 769px) and (max-width: 1024px) {
        height: 100%;
        padding: 0px;
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    background: url('${eyeglass}') white;
    background-repeat: no-repeat;
    background-position: 10px center;
    padding: 9px 9px 9px 30px; /* Adjust the padding as needed */
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    border: 0.5px solid #b9bcca;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    transition: border 0.15s linear, box-shadow 0.15s linear;
`;