import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getEquipment } from '../actions';
import { Checkbox } from '.';

const SafetyEquipmentContainer = styled.div`
  padding: 10px 0px 10px 10px;
  @media (max-width:768px) {
    padding-left:0;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 5px 0;
  }
`;

class SafetyEquipment extends React.Component {
  state = {
    defaults: this.props.selectedRole && this.props.selectedRole.defaultEquipmentIds || this.props.selected || [],
    isCheckedAll: false
  };
  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(getEquipment(user.currentUser.location._id));
    if ([...new Set(this.state.defaults)].length == 12) {
      this.setState({
        isCheckedAll: true
      })
    }
  }
  handleChangeAll = ({ target: { checked, name } }) => {
    if (checked) {
      const { equipment } = this.props;
      let eqs = [];
      equipment.map((eq) => {
        eqs.push(eq._id);
      });
      this.setState({
        defaults: eqs,
        isCheckedAll: true
      })
    } else {
      this.setState({
        defaults: [],
        isCheckedAll: false
      })
    }
  };
  handleChange = ({ target: { checked, name } }) => {
    const { defaults } = this.state;
    const defaultSelected = checked ? [...defaults, name] : defaults.filter((d) => d !== name);
    this.setState({
      isCheckedAll: [...new Set(defaultSelected)].length == 12 ? true : false
    })
    this.setState({ defaults: defaultSelected });
  };

  render() {
    const { equipment, onChange, editing } = this.props;
    const { defaults, isCheckedAll } = this.state;
    return (
      <SafetyEquipmentContainer>
        <CheckboxWrapper>
          <Checkbox
            onChange={(e) => {
              this.handleChangeAll(e);
              onChange(e);
            }}
            name='allEquipments'
            checked={isCheckedAll}
            disabled={editing}
          />
          <p>Select All</p>
        </CheckboxWrapper>
        <br></br>
        {equipment.length && equipment.map((option, i) => {
          return (
            <CheckboxWrapper key={i}>
              <Checkbox
                onChange={(e) => {
                  this.handleChange(e);
                  onChange(e);
                }}
                name={option._id}
                checked={this.state[option._id] || defaults.includes(option._id)}
                disabled={editing}
              />
              <p>{option.name}</p>
            </CheckboxWrapper>
          );
        })}
      </SafetyEquipmentContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  equipment: state.equipment.equipment || []
});

export default connect(mapStateToProps)(SafetyEquipment);