import axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap"
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import { HyperLinkButton, TransparentButton } from "../Button";
import styled from 'styled-components';
import { Input } from "../Input";
import { ModalDialog } from "./StyleComponents";

import pencil from '../../assets/images/pencil-green.svg';
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const InfoText = styled.p`
 font-size: 16px;
 font-weight: 500;
`;

const CustomPhoneInput = styled(PhoneInput)`
    width: 100%;
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    background: white;
    .PhoneInputCountry{
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        padding: 7px;
    }
    input{
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        padding: 9px;
        transition: border 0.15s linear, box-shadow 0.15s linear;
        width:100%;
        margin-top:2px;
        margin-bottom:2px;
    }
`;

const ResendInviteModal = ({ resendModalOpen, closeResendModal, userId, getUsers }) => {
    const currentUser = useSelector(state => state.user.currentUser);

    const [loading, setLoading] = useState(false);
    const [invitationSentLoading, setInvitationSentLoading] = useState(false);
    const [sendingOnMethod, setSendingOnMethod] = useState(''); // 'PHONE', 'EMAIL'.
    const [userDetails, setUserDetails] = useState({});
    const [editPhone, setEditPhone] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [usersNewDetails, setUsersNewDetails] = useState({ phone: '', email: '' });
    const [countryCode, setCountryCode] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/user_joining/${userId}`);
            setUserDetails(payload);
            setUsersNewDetails({ phone: payload.phone, email: payload.email });
            setCountryCode(payload.countryCode);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const validate = () => {
        setError('');
        if (sendingOnMethod === 'PHONE') {
            if (!usersNewDetails.phone) {
                setError('Please enter a phone number.');
                return false;
            } else if (!isValidPhoneNumber(usersNewDetails.phone)) {
                setError('Invalid phone number.');
                return false;
            };
        } else {
            if (!usersNewDetails.email) {
                setError('Please enter a Email address.');
                return false;
            } else if (!usersNewDetails.email.match(/[^@]+@[^@]+\.[a-zA-Z]{2,6}/)) {
                setError('Invalid email address.');
                return false;
            }
        };
        return true;
    };

    const sendInvite = async (userUpdated) => {
        try {
            const valid = validate();
            if (!valid) return;
            setInvitationSentLoading(true);
            setError('');
            await axios.post(`/api/v2/resend_invite/${sendingOnMethod}/${userId}`, { user: currentUser._id, practice: currentUser.practice._id, usersNewDetails: { ...usersNewDetails, countryCode } });
            setInvitationSentLoading(false);
            Swal.fire({
                icon: 'success',
                title: `Thank you, the invitation has been resent to ${sendingOnMethod === 'PHONE' ? usersNewDetails.phone : usersNewDetails.email} for ${userDetails.fullName}`,
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => {
                closeResendModal();
                if (userUpdated) {
                    getUsers();
                };
            });
        } catch (error) {
            console.log("🚀 ~ sendInvite ~ error:", error)
            setInvitationSentLoading(false);
            if (error.response && error.response.status === 409) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred while resending the invitation.');
            }
        }
    };

    const selectMethodToSendInvitation = (method) => {
        if (method === 'PHONE') {
            setEditEmail(false);
            setUsersNewDetails({ phone: userDetails.phone, email: userDetails.email });
            setCountryCode(userDetails.countryCode);
        } else {
            setEditPhone(false);
            setUsersNewDetails({ phone: userDetails.phone, email: userDetails.email });
            setCountryCode(userDetails.countryCode);
        };
        setError('');
        setSendingOnMethod(method);
    };

    const onModelChange = (name, value) => {
        setUsersNewDetails({ ...usersNewDetails, [name]: value });
    };

    const countryChange = (e) => {
        setCountryCode(getCountryCallingCode(e));
    };

    return (
        <Modal show={resendModalOpen} onHide={closeResendModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Resend Invite</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="text-center my-3">
                        <Spinner animation="border" variant="success" />
                    </div>
                ) : (
                    <ModalDialog>
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={sendingOnMethod || 'initial'}
                                timeout={300}
                                classNames="fade"
                                unmountOnExit
                            >
                                {!sendingOnMethod ? (
                                    <div className="text-center m-3">
                                        <InfoText>
                                            How would you like to re-send the invite to <strong>{userDetails.fullName}</strong>? If you choose to send it via email, they will use their email address to sign up. If you choose to send it via SMS to their phone, they will use their phone number to sign up.
                                        </InfoText>
                                        <HyperLinkButton onClick={() => selectMethodToSendInvitation('EMAIL')}>
                                            Send Via Email
                                        </HyperLinkButton>
                                        <br />
                                        <HyperLinkButton onClick={() => selectMethodToSendInvitation('PHONE')} className='my-2'>
                                            Send Via Phone
                                        </HyperLinkButton>
                                    </div>
                                ) : sendingOnMethod === 'PHONE' ? (
                                    <div className="text-center m-3">
                                        {userDetails.phone ? (
                                            <div>
                                                <InfoText>
                                                    This is the user's phone number, which will be used to send the invitation and allow them to sign up using this phone number.
                                                </InfoText>
                                                {!editPhone && (
                                                    <div className="text-right">
                                                        <TransparentButton className="text-success" onClick={() => setEditPhone(true)}>
                                                            <img src={pencil} alt="Edit" /> Edit
                                                        </TransparentButton>
                                                    </div>
                                                )}
                                                <CustomPhoneInput
                                                    countrySelectProps={{ unicodeFlags: true }}
                                                    defaultCountry="US"
                                                    smartCaret={true}
                                                    countryCallingCodeEditable={false}
                                                    international={true}
                                                    autoComplete="off"
                                                    placeholder="Enter phone number"
                                                    onChange={(e) => onModelChange('phone', e)}
                                                    onCountryChange={(e) => countryChange(e)}
                                                    value={usersNewDetails.phone}
                                                    disabled={!editPhone}
                                                />
                                                {editPhone ? (
                                                    <Button onClick={() => sendInvite(true)} className="mt-2" variant="success">
                                                        Update and Send Invite
                                                    </Button>
                                                ) : (
                                                    <Button onClick={() => sendInvite()} className="mt-2" variant="success">
                                                        {invitationSentLoading ? 'Sending...' : 'Send Invite'}
                                                    </Button>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <InfoText>
                                                    The user does not have a phone number associated with their account. Please add a number and send an invite.
                                                </InfoText>
                                                <CustomPhoneInput
                                                    countrySelectProps={{ unicodeFlags: true }}
                                                    defaultCountry="US"
                                                    smartCaret={true}
                                                    countryCallingCodeEditable={false}
                                                    international={true}
                                                    autoComplete="off"
                                                    placeholder="Enter phone number"
                                                    onChange={(e) => onModelChange('phone', e)}
                                                    onCountryChange={(e) => countryChange(e)}
                                                    value={usersNewDetails.phone}
                                                />
                                                <Button disabled={invitationSentLoading} onClick={() => sendInvite(true)} className="mt-2" variant="success">
                                                    Add and Send Invite
                                                </Button>
                                            </div>
                                        )}
                                        <HyperLinkButton disabled={invitationSentLoading} onClick={() => selectMethodToSendInvitation('EMAIL')}>
                                            Send Via Email
                                        </HyperLinkButton>
                                    </div>
                                ) : (
                                    <div className="text-center m-3">
                                        {userDetails.email ? (
                                            <div>
                                                <InfoText>
                                                    This is the user's email address, which will be used to send the invitation and allow them to sign up using this email.
                                                </InfoText>
                                                {!editEmail && (
                                                    <div className="text-right">
                                                        <TransparentButton className="text-success" onClick={() => setEditEmail(true)}>
                                                            <img src={pencil} alt="Edit" /> Edit
                                                        </TransparentButton>
                                                    </div>
                                                )}
                                                <Input
                                                    onChange={(e) => onModelChange('email', e.target.value)}
                                                    type="text"
                                                    placeholder="Email Address"
                                                    value={usersNewDetails.email}
                                                    disable={!editEmail}
                                                />
                                                {editEmail ? (
                                                    <Button onClick={() => sendInvite(true)} disabled={invitationSentLoading} className="mt-2" variant="success">
                                                        Update and Send Invite
                                                    </Button>
                                                ) : (
                                                    <Button disabled={invitationSentLoading} onClick={() => sendInvite()} className="mt-2" variant="success">
                                                        {invitationSentLoading ? 'Sending...' : 'Send Invite'}
                                                    </Button>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                <InfoText>
                                                    The user does not have an email address associated with their account. Please add an email and send an invite.
                                                </InfoText>
                                                <Input
                                                    onChange={(e) => onModelChange('email', e.target.value)}
                                                    type="text"
                                                    placeholder="Email Address"
                                                    value={usersNewDetails.email}
                                                />
                                                <Button onClick={() => sendInvite(true)} disabled={invitationSentLoading} className="mt-2" variant="success">
                                                    Add and Send Invite
                                                </Button>
                                            </div>
                                        )}
                                        <HyperLinkButton disabled={invitationSentLoading} onClick={() => selectMethodToSendInvitation('PHONE')}>
                                            Send Via Phone
                                        </HyperLinkButton>
                                    </div>
                                )}
                            </CSSTransition>
                        </SwitchTransition>
                        {error && <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                            <p className='w-100 mb-0'>{error}</p>
                        </Alert>
                        }
                    </ModalDialog>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={invitationSentLoading} variant="secondary" onClick={closeResendModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ResendInviteModal;