const { useState, useEffect } = require("react");
import { Link } from 'react-router-dom';
import { Button, Input } from '../components';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import styled from 'styled-components';

import OcoordLogo from '../assets/images/logo-icon-flat.svg';

const CustomPhoneInput = styled(PhoneInput)`
    width: 100%;
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    background: white;
    .PhoneInputCountry{
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        padding: 0px 8px;
    }
    input{
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        padding: 8px 8px;
        transition: border 0.15s linear, box-shadow 0.15s linear;
        width:100%;
    }
`;

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
  text-align: left;
`;

const FormContainer = styled(Col)`
    text-align: center;
    padding: 45px;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    margin: 3%;
`;

const LoginHeader = styled.div`
  text-align: center;
   img {
    max-height: 50px;
  }
`;

const InputForm = styled.form`
    text-align: left;
    width: 100%;
    p {
        text-align: center;
    }
`;

const StyledLink = styled(Link)`
  margin-bottom: 20px;
`;

const NavigationTabSection = styled(Row)`
    width: 100%;
    color:#676767 !important;
    margin-bottom: 1rem!important;
    margin-right: 0px !important; 
    margin-left: 0px !important;
    line-height: 30px;
    text-align: center;
`;

const NavTab = styled(Col)`
    font-size: 1.2rem;
    font-weight: 600 !important;
    text-align: center;
    cursor:pointer;
    border-bottom: 1px solid #676767;
    @media screen and (max-device-width: 1024px) and (orientation: portrait) {
        line-height: 3rem;
        white-space: pre;
    }
    @media screen and (max-device-width:1024px) and (orientation: landscape) {
        line-height: 3rem;
        white-space: pre;
    }
    
    :hover{
        background: #e3ffec !important;
    }  
    &.active {
        color:#42A764 !important;
        border-bottom: 2px solid #42A764;
    }
`;

const ForgotPassword = () => {
    const [userName, setUserName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();

    useEffect(() => {

    }, []);

    const sendLink = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setError('');
            await axios.post('/api/v2/users/requestToPasswordChange', { userName });
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: 'Link sent successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => history.push('/'));
        } catch (error) {
            setLoading(false);
            if (error.response.status === 500) {
                setError('Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.');
            };
            if (error.response.status === 404) {
                setError(`Sorry, we couldn't find an account associated with the provided username. Please double-check your username`);
            };
            console.log("🚀 ~ file: ForgotPassword.jsx:116 ~ sendLink ~ error:", error);
        }
    };

    return (
        <FormContainer lg='3' md='6' sm='10' className=''>
            <LoginHeader>
                <img src={OcoordLogo} alt='ocoord-logo' />
                <h2>Forgot Password</h2>
                <p>Enter the username of your account. We’ll send you a link to reset your password..</p>
            </LoginHeader>

            <InputForm onSubmit={sendLink}>
                <StyledLabel>Username</StyledLabel>
                <Input
                    name='userName'
                    label=''
                    type='text'
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                    placeholder='Username'
                    required={true}
                />
                {error &&
                    <Alert id='error-alert' className='my-2' style={{ 'maxWidth': '100%' }} variant="danger">
                        <p className='w-100 mb-0'>{error}</p>
                    </Alert>
                }
                <Button disabled={loading} type='submit'>{loading ? 'Loading...' : 'Continue'}</Button>
            </InputForm>
            <StyledLink to='/signin'>Sign In</StyledLink>
        </FormContainer>
    )
}

export default ForgotPassword;
