import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import { CustomCard, HeaderContainer, HeaderText } from './Style';
import { Checkbox } from '../../components';
import closeGaryIcon from '../../assets/images/glyph-close-dark-gray.svg';
import styled from 'styled-components';

const CheckBoxContainer = styled.label`
    font-size: 14px;Zog
    font-weight: 600;
    height: 22px;
    margin-bottom:1rem;
`;

const StartGroupTrainingConfirmation = ({ save, show, onClose, edit }) => {

    const [termsAndConditions, setTermsAndConditions] = useState(false);

    useEffect(() => {
    }, []);


    const onSave = () => {
        save();
    };

    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            show={show}>
            <CustomCard className="border-0 rounded-0">
                <HeaderContainer className="mb-4 d-flex justify-content-between">
                    <HeaderText>Confirmation of the training</HeaderText>
                    <Button onClick={() => onClose(false)} type='button' variant='light'><img src={closeGaryIcon} alt="Cose" /></Button>
                </HeaderContainer>
                <div>
                    <p>
                        Please confirm the list of individuals selected for the training group. Your verification ensures the accuracy and completeness of the participant roster. Kindly indicate your approval.
                    </p>
                    <p>
                        Your confirmation is vital for ensuring that all selected individuals are properly accounted for and included in the training program. Thank you for your attention to this matter.
                    </p>
                    <div>
                        <CheckBoxContainer htmlFor="agree"><Checkbox onChange={() => setTermsAndConditions(!termsAndConditions)} name="agree" checked={termsAndConditions} /> I read provided information and confirm of starting training.</CheckBoxContainer>
                    </div>
                </div>
                <div className='text-right'>
                    <Button onClick={() => onClose(false)} variant='light' size='lg' className='mx-3 px-5'>Cancel</Button>
                    <Button disabled={!termsAndConditions} onClick={onSave} variant='success' size='lg' className='px-5'>{edit ? 'Update' : 'Save'}</Button>
                </div>
            </CustomCard>
        </Modal>
    )
};

export default StartGroupTrainingConfirmation;