import axios from 'axios';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { Button, Input } from '../../components';
import { StyledLabel, StyledTextArea } from '../Administration/Style';
import ethanol_gel from '../../assets/images/ethanol_gel.svg';
import trash from '../../assets/images/trash.svg';
import { toast } from 'react-toastify';
import MultiTextInput from '../../components/MultiTextInput';

const SubHeaderSection = styled.div`
    display: flex;
    justify-content:space-between;
    padding:0px !important;
    margin-left:15px !important;
    margin-right:15px !important;
    border-bottom: 1px solid #4FB973;
`;

const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight:600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
`;

const SubHeader = styled.h6`
    color:#4FB973;
    margin:0px !important;
    font-weight: 600 !important;
`;

const FileUploadBtn = styled.label`
    border-radius: 4px;
    padding: 6px 10px;
    min-width: 50px;
    height: 30px;
    font-size: 12px;
    font-weight: 600;
    background: #fbfbfb;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14);
    border: 0.5px solid #eaeaea;
    color: #7f808f;
    text-align: center;
    -webkit-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
    cursor: pointer;
`;

const FileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    visibility: hidden;
    margin-bottom: 5px;
`;

const FileList = styled.li`
  position: relative;
  padding-top: 10px;
  width: 100%;
  word-break: break-all;
`;

class RequestToSDS extends Component {

    state = {
        loading: false,
        userDetails: { name: "", },
        addInProgress: false,
        saveOrUpdateInProgress: false,
        error: false,
        formValidate: false,
        requestDetails: {
            chemicalName: '',
            manufacturer: '',
            cas: [],
            note: '',
            file: ''
        },
    };
    componentDidMount() {
    };

    onModelChange = (name, value) => {
        if (name === 'cas') value = value.map(val => val.value);

        this.setState({
            requestDetails: {
                ...this.state.requestDetails,
                [name]: value
            }
        });
    };

    sendSDSRequest = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        try {
            if (!form.checkValidity()) {
                e.preventDefault();
                e.stopPropagation();
                this.setState({ formValidate: true });
            } else {
                const { closePopUpModal, user } = this.props;
                this.setState({ saveOrUpdateInProgress: true, formValidate: false });

                const { requestDetails: { chemicalName, manufacturer, cas, note, file } } = this.state;
                const formData = new FormData();
                formData.append('chemicalName', chemicalName);
                formData.append('manufacturer', manufacturer);
                formData.append('cas', cas);
                formData.append('note', note);
                formData.append('practice', user.practice._id);
                formData.append('location', user.location._id);

                if (file.lastModified) {
                    formData.append('file', file);
                };
                await axios.post('/api/v2/requestForSds', formData);
                this.setState({ saveOrUpdateInProgress: false });
                closePopUpModal(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                this.setState({ saveOrUpdateInProgress: false, error: { apiError: true, errorMsg: error.response.data.message } });
                return;
            };
            this.setState({ saveOrUpdateInProgress: false, error: { apiError: true, errorMsg: 'Error at sending request.' } });
            return;
        }
    };

    validateFileType(event) {
        const filePath = event.value;
        const allowedExtensions = /(\.jpeg|\.jpg|\.png)$/i;

        if (!allowedExtensions.exec(filePath)) {
            alert('Please upload files with valid extensions: .jpeg, .jpg, .png');
            event.value = '';
            return false;
        }
    };

    handleFileChange = (event) => {

        const { name } = event.target.files[0];
        const allowedExtensions = /(\.jpeg|\.jpg|\.png)$/i;

        if (!allowedExtensions.exec(name)) {
            toast.error('Please upload files with valid extensions: .jpeg, .jpg, .png');
            event.value = '';
            return
        };

        this.setState({
            requestDetails: {
                ...this.state.requestDetails,
                file: event.target.files[0]
            }
        });
    };

    deleteFiles() {
        this.setState({
            requestDetails: {
                ...this.state.requestDetails,
                file: ''
            }
        });
    };
    render() {
        const { loading, saveOrUpdateInProgress, error, requestDetails, formValidate } = this.state;

        const { closePopUpModal, show } = this.props;
        return (
            <Modal
                centered
                animation={true}
                backdrop="static"
                keyboard={false}
                size='md' show={show}>
                <Modal.Header >
                    <Modal.Title>Request an SDS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {loading ?
                            <Container className='text-center my-3'>
                                <Spinner animation="border" variant="success" />
                            </Container> :
                            <Form noValidate validated={formValidate} onSubmit={this.sendSDSRequest}>
                                <SubHeaderSection>
                                    <SubHeader>SDS Details</SubHeader>
                                </SubHeaderSection>
                                <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                                    <Row className='mt-4'>
                                        <Col className='col-1 text-center' style={{ backgroundColor: '#F3F9F5', marginLeft: '50px', marginRight: '50px', padding: '10px', borderRadius: '50%' }}>
                                            <img src={ethanol_gel} alt="logo" />
                                        </Col>
                                        <Col>
                                            <Input required={true} onChange={(e) => this.onModelChange('chemicalName', e.target.value)} label='Name' type='text' placeholder='Name' value={requestDetails.chemicalName} />
                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <Input required={true} onChange={(e) => this.onModelChange('manufacturer', e.target.value)} label='Manufacturer' type='text' placeholder='Manufacturer' value={requestDetails.manufacturer} />
                                            <ErrorContainer type="invalid">Required</ErrorContainer>
                                        </Col>
                                        <Col lg={12} md={12} sm={12}>
                                            <StyledLabel htmlFor="cas">Subsidiary Ingredient CAS Number(s):</StyledLabel>
                                            <MultiTextInput
                                                name='cas'
                                                data={requestDetails.cas && requestDetails.cas.map((task) => {
                                                    return { label: task, value: task };
                                                })}
                                                onChange={(e) => this.onModelChange('cas', e)}
                                                placeholder='Type a number and press enter...'
                                            />
                                        </Col>
                                        <Col lg={12} md={12} sm={12}>
                                            <StyledLabel htmlFor="textarea">Notes</StyledLabel>
                                            <br />
                                            <StyledTextArea style={{ width: '100%' }}
                                                name='note'
                                                onChange={(e) => this.onModelChange('note', e.target.value)}
                                                placeholder='Text'
                                                value={requestDetails.note}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <StyledLabel htmlFor="recordFiles">Label</StyledLabel>
                                            <br />
                                            <FileUploadBtn className='col-lg-12 col-md-12 col-sm-12'>
                                                Choose File to Upload...
                                                <FileInput name='recordFiles' onChange={this.handleFileChange} type='file' accept=".jpeg, .jpg, .png" />
                                            </FileUploadBtn>
                                            {requestDetails.file &&
                                                <FileList>
                                                    {requestDetails.file.name}
                                                    <img onClick={() => this.deleteFiles()} className='mx-4' src={trash} />
                                                </FileList>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    {error.apiError &&
                                        <Row>
                                            <Col>
                                                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                                    <p className='w-100 mb-0'>{error.errorMsg}</p>
                                                </Alert>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className='mt-4'>
                                        <Col>
                                            <Button className='my-0' type='button' onClick={() => closePopUpModal()}>Cancel</Button>
                                        </Col>
                                        <Col>
                                            <Button disabled={saveOrUpdateInProgress} className='my-0' type='submit'>{saveOrUpdateInProgress ? 'Sending...' : 'Save'}</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        }
                    </Container>
                </Modal.Body>
            </Modal >
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(RequestToSDS);