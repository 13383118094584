import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { changePasswordInside } from '../../actions';
import { Card } from '../Card';
import { Input } from '../Input';
import { Button } from '../Button';

const PasswordContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  width: 528px;
  padding:10px 50px;
  min-height:310px;

  @media (max-width:991px) {
      display:block;
      width:100%;
      padding:15px;
  }
  h2 {
    margin-top: 10px;
    margin-bottom:10px;
    @media (max-width:768px) {
      margin-top:0
    }
  }
`;

const StyledWrapper = styled.div`
  max-width: 215px;
`;
const StyledButton = styled(Button)`
  width: auto;
`;

class Password extends Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password2: '',
      email: props.user.email,
      error: ''
    };
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  };

  handleSave = (e) => {
    e.preventDefault();
    try {
      const { password, password2, email } = this.state;
      const passPattern = /^(?=.*[0-9])(?=.*[!@#$%^&.,_*])[a-zA-Z0-9!@#$%^&.,_*]{8,16}$/;

      if (!passPattern.test(password)) {
        this.setState({ error: `Passwords must contain \n\u2022 a minimum of 1 lower case letter, \n\u2022 1 upper case letter, \n\u2022 1 numeric character \n\u2022 1 special character and \n\u2022 at least 8 characters in length.` });
      } else if (password !== password2) {
        this.setState({ error: 'Password and confirm password does not match.' });
      } else {
        const { dispatch } = this.props;
        dispatch(changePasswordInside({ password, password2, email }));
        this.setState({ password: '', password2: '', error: '' });
      };
    } catch (error) {
      console.log("🚀 ~ Password ~ error:", error);
    }
  };


  render() {
    const { password, password2, error } = this.state;
    return (
      <form onSubmit={this.handleSave}>
        <PasswordContainer>
          <h2>Reset Password</h2>
          <StyledWrapper>
            <Input
              label='Password'
              type='password'
              name='password'
              onChange={this.handleChange}
              value={password}
              placeholder='New Password'
              required={true}
            />
            <Input
              label='Confirm New Password'
              type='password'
              name='password2'
              onChange={this.handleChange}
              value={password2}
              required={true}
              placeholder='Confirm New Password'
            />
          </StyledWrapper>
          {error && <pre className='text-danger'>{error}</pre>}
          <StyledButton type='submit'>Set Password</StyledButton>
        </PasswordContainer>
      </form>
    );
  }
}

export default connect()(Password);
