import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container, Spinner, Table } from 'react-bootstrap';
import { MainContainer, TableContainer } from './StyleComponents';

const StaffSafetyEquipments = ({ user, closePopUpModal }) => {

    const [safetyEquipments, setSafetyEquipments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ apiError: false });

    useEffect(() => {
        getSafetyEquipments();
    }, []);

    const getSafetyEquipments = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/usersSafetyEquipments/' + user._id);

            setSafetyEquipments(payload);
            setLoading(false);
        } catch (error) {
            setError({ apiError: true });
            setLoading(false);
        }
    };

    return (
        <MainContainer>
            <div className="d-flex justify-content-between">
                <h2>{user.fullName}'s Safety Equipments</h2>
                <i style={{ fontSize: '20px' }} className='fa fa-times text-secondary' onClick={closePopUpModal} />
            </div>
            {
                loading ?
                    <Container className='text-center my-3'>
                        <Spinner animation="border" variant="success" />
                    </Container> :
                    <TableContainer>
                        <Table className='mb-0'>
                            <tbody>
                                {safetyEquipments.length ? safetyEquipments.map(se =>
                                    <tr key={se}>
                                        <td className='text-center'>{se}</td>
                                    </tr>
                                )
                                    :
                                    <tr >
                                        <td className='text-center'><h5>No safety equipment assigned yet.</h5></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </TableContainer>
            }
        </MainContainer >
    );
}

export default StaffSafetyEquipments;