import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { DropDownStyle } from '../helpers';

const components = {
  DropdownIndicator: null
};

const createOption = (label) => ({
  label,
  value: label
});

class MultiTextInput extends React.Component {
  state = {
    inputValue: '',
    value: this.props.data || []
  };

  handleChange = (value, actionMeta) => {
    value = value || [];
    this.setState({ value }, () => {
      this.props.onChange(value);
    });
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState(
          {
            inputValue: '',
            value: [...value, createOption(inputValue)]
          },
          () => {
            this.props.onChange(this.state.value);
          }
        );
        event.preventDefault();
        break;
      default:
    }
  };

  render() {
    const { inputValue, value } = this.state;
    const { placeholder } = this.props;

    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        styles={DropDownStyle}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder={placeholder}
        value={value}
      />
    );
  }
}

export default MultiTextInput;
