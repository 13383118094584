import axios from 'axios';
import { useState } from 'react';
import { Container, Modal } from 'react-bootstrap';

import { Elements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import UpdateCardElement from '../Stripe/UpdateCardElement';
import BillingAddressUpdate from '../Stripe/BillingAddressUpdate';

const SubHeaderSection = styled(Container)`
    display: flex;
    justify-content:space-between;
    padding:0px !important;
    border-bottom: 1px solid #4FB973;
`;

const SubHeader = styled.h6`
    color:#4FB973;
    margin:0px !important;
    font-weight: 600 !important;
`;

const Steps = styled.p`
    margin:0px !important;
`;

const UpdateCardDetails = ({ closePopUpModal, show, user, afterUpdateSuccess }) => {

    const [steps, setSteps] = useState({ currentStep: 1, totalSteps: 2, sub_title: 'Address', title: 'Enter Your New Billing Address' });
    const [billingDetails, setBillingDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

    const save = async (data) => {
        try {
            if (steps.currentStep === 1) {
                setBillingDetails(data);
                setSteps({ currentStep: 2, totalSteps: 2, sub_title: 'Payment Details', title: 'Enter Your New Payment Information' });
            } else {
                setLoading(true);
                await axios.put(`/api/v2/practice/${user.practice._id}/updateCard/${data.id}`, { ...billingDetails, ...data });
                setLoading(false);
                afterUpdateSuccess();
            }
        } catch (error) {
            setLoading(false);
            console.log("error:", error);
        }
    };

    return (
        <Container>
            <Modal
                centered
                animation={true}
                backdrop="static"
                keyboard={false}
                size='lg' show={show}>
                <Modal.Header >
                    <Modal.Title>{steps.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SubHeaderSection>
                        <SubHeader>{steps.sub_title}</SubHeader>
                        <Steps>{steps.currentStep} / {steps.totalSteps}</Steps>
                    </SubHeaderSection>
                    {steps.currentStep === 1 ?
                        <BillingAddressUpdate user={user} closePopUpModal={closePopUpModal} save={save} />
                        :
                        <Elements stripe={stripePromise}>
                            <UpdateCardElement closePopUpModal={closePopUpModal} save={save} loading={loading} />
                        </Elements>
                    }
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default UpdateCardDetails;