import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Button, Card, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import Swal from 'sweetalert2';
import { ThreeDots } from "react-loader-spinner";
import styled from "styled-components";

import smallLogo from '../../assets/images/smallLogo.svg';
import pending from '../../assets/forms-icon/circle-gray.svg';
import completed from '../../assets/forms-icon/circle-checked.svg';
import { useDispatch, useSelector } from "react-redux";
import { loadUserSuccess } from "../../actions";

const CustomCard = styled(Col)`
    flex:1;
    position: relative;
    padding: 50px;
    border: 1px solid ${(props) => (props.selected ? '#28a745' : '#b9b7b7')} !important;
    ${(props) => (props.selected && 'box-shadow: 0px 2px 6px 0px rgb(79 195 96)')};
    border-radius: 100px 5px 100px 5px !important;
    margin-bottom: 0.5rem!important;

    .svg-icon {
        opacity:0.5;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 202px; /* Adjust the size according to your needs */
        height: 400px; /* Adjust the size according to your needs */
        background: url(${smallLogo});
        background-size: cover;
    }
`;

const InsideCreditCardDiv = styled.div`
align-items: center;
padding: 10px 15px;
box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
border-radius: 7px;
background: #F3F9F5;
width: 100% !important;
display: flex;
justify-content: space-between;
`;

const CardHeader = styled.div`
   padding-left:1rem;
`;

const CardBody = styled.div`
    margin-top: 2rem;
    padding-left:1rem;
    ul {
        padding-inline-start:0px !important;
        list-style-type: none;
    }
`;

const CardTitle = styled.p`
    font-size:30px;
    font-weight:600;
    margin:0px;
`;

const CardSubTitle = styled.p`
    font-size:26px;
    font-weight:600;
    margin:0px;
    margin-top: -12px;
    display: flex;
    small{
        margin-top:10px;
        font-size:20px;
    }
`;

const ProductFutureList = styled.li`
    font-size:16px;
    margin-bottom:8px;    
`;

const CardFooter = styled.div`
    text-align:center;
`;

const ChangeSubPlan = ({ setOpenModal, openModal }) => {

    const [plans, setPlans] = useState({});
    const user = useSelector(state => state.user.currentUser);

    const [selectedPlan, setSelectedPLan] = useState("OSHA&SDS");
    const [planDetails, setPlanDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [saveInprogress, setSaveInprogress] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        getPlanDetails();
    }, []);

    const getPlanDetails = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get('/api/v2/planDetails');

            setPlans(data);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getPlanDetails ~ error:", error)
            setLoading(false);
        }
    };

    const changePlan = async () => {
        try {
            setSaveInprogress(true);
            if (user.location.locSubscriptionPlan === selectedPlan) {
                setError('Your selected plan and current plan is same. ');
            };
            const { data } = await axios.put(`/api/v2/changeSubPlan/${user.location._id}/${user._id}`, { selectedPlan });
            dispatch(loadUserSuccess(data));
            setSaveInprogress(false);
            Swal.fire({
                icon: 'success',
                title: 'Subscription updated successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => {
                setOpenModal(false);
            });
        } catch (error) {
            console.log("🚀 ~ changePlan ~ error:", error)
            setSaveInprogress(false);
            setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.')
        }
    };
    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='md' show={openModal}>

            {/* <Modal.Header className="justify-content-center">
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                {loading ? <Container className='text-center my-3'>
                    <Spinner animation="border" variant="success" />
                </Container> :
                    plans['OSHA&SDS'].tiers[1].unit_amount && plans['OSHA&SDS'].tiers[1].unit_amount &&
                    <InsideCreditCardDiv className='my-2'>
                        <h5>The charges for the newly added subscription are $ {(plans['OSHA&SDS'].tiers[0].unit_amount / 100).toFixed(2)} for the first location, and for each additional location, the charge is $ {(plans['OSHA&SDS'].tiers[1].unit_amount / 100).toFixed(2)}.</h5>
                        {/* <h3>$ {(plans['OSHA&SDS'].tiers[1].unit_amount / 100).toFixed(2)}</h3> */}
                    </InsideCreditCardDiv>
                }
                {error &&
                    <Alert id='error-alert' className='my-2 mx-4' style={{ 'maxWidth': '100%' }} variant="danger">
                        <p className='w-100 mb-0'>{error}</p>
                    </Alert>
                }
                <div className="d-flex justify-content-end">
                    <Button disabled={saveInprogress} onClick={() => setOpenModal(false)} size="lg" variant="light">Close</Button>
                    <Button disabled={loading || saveInprogress} onClick={changePlan} size='lg' variant="success" className="mx-3">Submit</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default ChangeSubPlan;