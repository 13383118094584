import { useState } from "react";
import { FormInput, HeaderContainer, Heading, OtpContainer, PersonalInfoContainer, SubHeading } from "./Style";
import OtpInput from 'react-otp-input';
import LockIcon from '../../assets/images/big-lock.svg'
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

const Verification = ({ setStepNumber }) => {
    const currentUser = useSelector(state => state.user.currentUser.user ? state.user.currentUser.user : state.user.currentUser);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const verifyEmailOtp = async () => {
        try {
            setLoading(true);
            await axios.post('/api/v2/verifyOtp', { method: 'email', email: currentUser.email, otp });
            setLoading(false);
            setStepNumber(3);
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.status !== 500) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data,
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                });
            };
        }
    };

    const sendOptOnEmail = async () => {
        try {
            const { email, firstName } = currentUser;
            await axios.post('/api/v2/sendOtpOnEmail', { email, firstName });
            Swal.fire({
                icon: 'success',
                title: 'Code sent successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            });
        } catch (error) {
            console.log("🚀 ~ sendOptOnEmail ~ error:", error);
        }
    };

    return (
        <PersonalInfoContainer>
            <HeaderContainer>
                <Heading className="mb-3"><img src={LockIcon} alt="" /></Heading>
                <Heading>Verification</Heading>
                <SubHeading className="px-5">We just sent a code to your work email. Please check your email and enter the code here.</SubHeading>
            </HeaderContainer>
            <OtpContainer>
                <OtpInput
                    inputType='text'
                    inputStyle='otpInput'
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span className="mx-4">-</span>}
                    renderInput={(props) => <FormInput {...props} />}
                />
            </OtpContainer>
            <Button disabled={otp.length !== 4 || loading} type='button' onClick={verifyEmailOtp} size="lg" className="w-100 mt-5" variant="success">{loading ? 'Loading...' : 'Next'}</Button>
            <p className="mt-2 text-center">Didn’t receive a code? <span onClick={sendOptOnEmail} className="text-success cursor-pointer">Resend code</span></p>
        </PersonalInfoContainer>
    )
}

export default Verification;