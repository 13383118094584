import React from 'react';
import styled from 'styled-components';
import check from '../assets/images/glyph-checkmark-white.svg';

/**
 * Checkbox component
 * @component
 * @example
 * return (<p>foo</p>)
 *
 */
export const Checkbox = ({ className, checked, ...props }) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox disabled={props.disabled} checked={checked}>
        <img src={check} alt='' />
      </StyledCheckbox>
    </CheckboxContainer>
  </label>
);
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 0.5px solid #b9bcca;
  // cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${(props) => (props.checked ? '#4fb973' : '#f7f7f7')};
  border-radius: 3px;
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(79, 185, 115, 0.5);
  }
  img {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
    height: 10px;
    width: 10px;
  }
`;
