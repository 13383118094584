import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NavBar, Header } from '../components';

export const AuthRoute = ({ component: Component, authenticated, user, path, history, ...rest }) => {

  return (<Route
    {...rest}
    render={(props) =>
      (Cookies.get('token') && user.isRegistrationCompleted === 2) ? (
        !user.isSubscriptionFailed ?
          <React.Fragment>
            <NavBar {...props} />
            <Header {...props} />
            <Component {...props} />
          </React.Fragment>
          :
          <Redirect to='/update_subscription' />)
        :
        <Redirect to='/signin' />
    }
  />)
}
