import { Badge, Card, Figure, Row } from "react-bootstrap";
import styled from "styled-components";

export const StyledCard = styled.div`
    background:#fff;
    border:1px solid #EFEFEF;
    padding:10px;
    box-shadow:none;
    border-radius:10px;
    color:#000 !important; 
    margin-bottom: 1rem!important;
    text-align: left;
    display: flex;
    width:100%;
    align-items: center;
    img{
        margin-right:1rem;
    }
    h6{
        @media screen and (max-device-width:450px) and (orientation: portrait) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 168px;
        }
        @media screen and (max-device-width:450px) and (orientation: landscape) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 168px;
        }
    }
`;

export const DetailContainer = styled.div`
    overflow-y: auto;
    max-height:60vh;
    .dash-border{
    border: 2px dashed #EFEFEF;
    border-radius: 1rem;
    }
`;
const NameBlock = styled.span`
    padding:8px 8px
    border-radius:50%;
    background-color:#E7F3EB;
    color:#4FB973;
    margin-right: 1rem;
    font-size: 0.875em
    text-transform: uppercase;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
`;

export const NameBlockElement = ({ firstName, lastName }) => {
    return (
        <NameBlock>
            {firstName[0] + lastName[0]}
        </NameBlock>
    );
};

export const DashboardContainer = styled.div`
    width: 95%;
    // height: 90%;
    justify-content: start;
    margin: 0 0rem 0 0rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border-radius:10px;

    @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width: 85%;
        display: block;
        padding: 20px;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: 6.5rem 0.5rem 0rem 6rem !important;
        max-width: 100%;
        display: block;
        padding: 20px;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:6.5rem 0.5rem 0rem 12rem !important;
        max-width:100%;
        display: block;
        padding: 0;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
    }
    .min-w-15{
        min-width:15% !important;
    }
    .min-w-25{
        min-width:25% !important;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;

export const TaskContainer = styled.div`
    max-height: 64vh;
`;

export const TaskHeader = styled.div`
       
`;

export const TaskBody = styled.div`
    width: 94vw;
    overflow-x: auto;
    @media screen and (max-device-width: 768px) {
        width: 88vw;
    }
    @media screen and (max-device-width: 420px) {
        width: 75vw;
    }
`;

export const TaskListContainer = styled.div`
    display: flex;
    width: calc(360px * 5);
`;

export const TasksColumn = styled.div`
    width: 340px !important;
    border-radius: 10px; 
    background-color: #F3F5F9;
    padding: 20px;
    margin: 0 10px 0px 10px;
`;

export const TasksColumnBody = styled.div`
    max-height: 55vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
`;
export const ColHeader = styled.div`
     
`;

export const TasksCard = styled.div`
    border-radius:10px; 
    width: 100%;
    background-color: #FCFCFC;
    padding: 10px;
    margin-bottom:10px;
    cursor: pointer;
    &:hover {
        background-color: #e8edea;
    }
`;

export const TaskTitle = styled.h3`
    margin-top:5px;
`;

export const TaskBadge = styled(Badge)`
background-color: #E7F3EB;
color: #42A764;
padding: 8px 16px;
font-weight:400;
font-size:12px;
`;

export const TaskInstruction = styled.div`

`;

export const TaskDetailsContainer = styled.div`
padding: 3rem;
height: 100%;
width: 30vw;
background-color: #FCFCFC;
overflow-x: hidden;

@media screen and (max-width: 768px) {
    width: 100vw;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 80vw;
}
`;

export const OverLayContainer = styled.div`
    background-color: rgb(0 0 0 / 32%);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
`;

export const InfoCard = styled.div`
    display:inline-flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #EFEFEF;
    background: #FCFCFC;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07); 
    padding: 1rem;
    min-height: 15rem;
    width: 95%;
    cursor: pointer;
    &:hover {
        background-color: #e8edea;
    }
`;

export const TrainingProgressContainer = styled.div`
    width: 10%;
`;

export const CardBody = styled.div`
    margin-left: 2rem;
`;

export const CardHeading = styled.p`
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom:0px;
`;

export const CardSubText = styled.p`

`;

export const TaskHeading = styled.p`
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
`;

export const TaskSubHeading = styled.p`
    font-size: 16px;
    color: #676767;
`;

export const StyledLabel = styled.p`
    font-size: 1.2rem;
    font-weight: 600 !important;
    cursor:pointer;
    border-bottom: 1px solid  #D3D6DD;
`;

export const DateSection = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    padding: 12px;
    border-radius: 7px;
    border: 1px solid #EFEFEF;
    background:  #FCFCFC;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
`;

export const CustomCard = styled(Card)`
    cursor: pointer;
`;

export const CustomCardBody = styled(Card.Body)`
    :hover {
        background: #4FB973;
        transition: all 0.2s ease-out;

        .text-success {
            color: #fff !important;
        }

        .figure-caption {
            color: #fff !important;
        }
    }
    .figure-caption{
        h6{
            @media screen and (max-device-width:1024px) and (orientation: portrait) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            @media screen and (max-device-width:768px) and (orientation: landscape) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
         
        }
    }
`;

export const IconContainer = styled(Figure)`
    margin: 0;
    display: flex !important;
`;

export const ImageContainer = styled(Figure.Image)`
    margin-right: 0.875rem;
    align-self: flex-start;
`;