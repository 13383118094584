import actions from '../actions/actionTypes';

/**
 * @namespace locationReducer
 * @category Reducers
 */

/**
 * @memberOf locationReducer
 */
const initialState = {};

/**
 * @method
 * @memberOf locationReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REGISTER_NEW_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location
      };
    case actions.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location
      };
    case actions.UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        location: {}
      };
    case actions.GET_DEFAULTS_SUCCESS:
      return {
        ...state,
        defaults: {
          ...state.defaults,
          [action.item]: action.defaults
        }
      };
    case actions.GET_DEFAULTS_FAILURE:
      return {
        ...state,
        defaults: {
          ...state.defaults,
          [action.item]: []
        }
      };
    case actions.GET_DEFAULT_OPTIONS_SUCCESS:
      return {
        ...state,
        defaults: action.defaultOptions
      };
    case actions.GET_DEFAULT_OPTIONS_FAILURE:
      return {
        ...state,
        defaults: []
      };
    default:
      return state;
  }
};
