import actions from '../actions/actionTypes';

/**
 * @namespace tasksReducer
 * @category Reducers
 */

/**
 * @memberOf tasksReducer
 */
const initialState = {
  dueOnce: [],
  dueToday: [],
  dueThisWeek: [],
  dueThisMonth: [],
  error: false
};

/**
 * @method
 * @memberOf tasksReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_TASKS_SUCCESS:
      return {
        ...state,
        ...action.tasks
      };
    default:
      return state;
  }
};
