import actions from '../actions/actionTypes';

/**
 * @namespace recordsReducer
 * @category Reducers
 */

/**
 * @memberOf recordsReducer
 */
const initialState = {};

/**
 * @method
 * @memberOf recordsReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const recordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_RECORDS_SUCCESS:
      return {
        recordList: action.list,
        filters: action.filters
      };
    case actions.UPDATE_RECORD_SUCCESS:
      return {
        ...state,
        [action.record.id]: action.record
      };
    default:
      return state;
  }
};
