const { useState, useEffect } = require("react")
import { Alert, Container, Spinner } from 'react-bootstrap';
import axios from 'axios';
import styled from 'styled-components';
import ExpiredLogo from '../assets/images/expired-link.svg';
import InvalidLogo from '../assets/images/invalid-link.svg';

const ExpiredLinkContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px;
    min-width: 325px;
    min-height: 325px;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
`;

const RedirectUrlComponent = () => {
    const [error, setError] = useState({ invalidLink: false, expiredLink: false, msg: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUrlDetails();
    }, []);

    const getUrlDetails = async () => {
        try {
            setLoading(true);
            const urlData = window.location.href.split('/oc/');
            if (urlData.length < 2) {
                setError({ invalidLink: true, msg: "Invalid Link.", expiredLink: false });
                return setLoading(false);
            };
            const { data } = await axios.post('/api/v2/oc/' + urlData[1]);
            window.location.href = data;
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ file: RedirectUrlComponent.jsx:25 ~ getUrlDetails ~ error:", error)
            if (error.response.status === 404) {
                setError({ invalidLink: true, msg: error.response.data, expiredLink: false });
            };
            if (error.response.status === 410) {
                setError({ invalidLink: false, msg: error.response.data, expiredLink: true });
            };
            if (error.response.status === 500) {
                setError({ invalidLink: false, msg: "Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.", expiredLink: false, unrecognizedError: true });

            }
            setTimeout(() => {

                setLoading(false);
            }, 1000);
        }
    };

    return (
        <>
            {loading ?
                <ExpiredLinkContainer className='text-center my-3 w-100'>
                    <Spinner animation="border" variant="success" />
                </ExpiredLinkContainer> :
                <ExpiredLinkContainer className='text-center my-3 px-0 w-100'>
                    {error.invalidLink &&
                        <div className='w-100'>
                            <img src={InvalidLogo} alt="" height='300' />
                            <Alert id='error-alert' className='m-4' style={{ 'maxWidth': '100%' }} variant="danger">
                                <h2 className='w-100 mb-0'>Invalid link...</h2>
                            </Alert>

                        </div>
                    }
                    {error.expiredLink &&
                        <div className='w-100'>
                            <img src={ExpiredLogo} alt="" height='300' />
                            <Alert id='error-alert' className='m-4' style={{ 'maxWidth': '100%' }} variant="info">
                                <h2 className='w-100 mb-0'>{error.msg}</h2>
                            </Alert>

                        </div>
                    }
                    {error.unrecognizedError &&
                        <div className='w-100'>
                            {/* <img src={ExpiredLogo} alt="" height='300' /> */}
                            <Alert id='error-alert' className='m-4' style={{ 'maxWidth': '100%' }} variant="danger">
                                <h2 className='w-100 mb-0'>{error.msg}</h2>
                            </Alert>

                        </div>
                    }
                </ExpiredLinkContainer>
            }
        </>
    )
}

export default RedirectUrlComponent;