import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Alert, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

import styled from 'styled-components';

import { Input, Checkbox, Button } from '../components';
import OcoordLogo from '../assets/images/logo-icon-flat.svg';

const PasswordContainer = styled(Col)`
  pre{
    white-space: pre-wrap;
    font-size: 1rem;
    line-height: 1.5;
    font-family: "Open Sans", sans-serif;
    color: var(--dark-text-color);
  }
  text-align: center;
  padding: 45px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin: 3%;
`;

const Header = styled.div`
  text-align: center;
   img {
    max-height: 50px;
  }
`;


const StyledButton = styled(Button)`
    width: auto;
`;

const ResetPassword = () => {
  const [fieldType, setFieldType] = useState('password');
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const resetToken = params.get('resetToken');
    if (!resetToken) history.push('/');
    setResetToken(resetToken);
  }, []);

  const handleSave = async (e) => {
    e.preventDefault();
    const passPattern = /^(?=.*[0-9])(?=.*[!@#$%^&.,_*])[a-zA-Z0-9!@#$%^&.,_*]{8,16}$/;
    if (!passPattern.test(password)) {
      setError(`Passwords must contain \n\u2022 a minimum of 1 lower case letter, \n\u2022 1 upper case letter, \n\u2022 1 numeric character \n\u2022 1 special character and \n\u2022 at least 8 characters in length.`);
    } else if (password !== password1) {
      setError('Password and confirm password does not match.');
    } else {
      setError('');
      try {
        await axios.put('/api/v2/users/changePassword', { resetToken, password });
        Swal.fire({
          icon: 'success',
          title: 'Password has been reset successfully',
          allowEnterKey: true,
          allowEscapeKey: false,
          confirmButtonColor: '#4FB973',
          confirmButtonText: 'Close',
          allowOutsideClick: false
        }).then(() => history.push('/'));
      } catch (error) {
        if (error && error.response && error.response.data) {
          setError(error.response.data);
        };
      }
    };
  };

  return (
    <PasswordContainer lg='3' md='6' sm='10'>
      <form onSubmit={handleSave}>
        <Header>
          <img src={OcoordLogo} alt='ocoord-logo' />
          <h2 className=''>Reset Password</h2>
        </Header>
        <div className='text-left'>
          <Input
            label='Password'
            type={fieldType}
            name='password'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder='New Password'
            required={true}
          />
          <Input
            label='Confirm New Password'
            type={fieldType}
            name='password2'
            onChange={(e) => setPassword1(e.target.value)}
            value={password1}
            placeholder='Confirm New Password'
            required={true}
          />

          <div className='d-flex mt-2'>
            <Checkbox onChange={() => setFieldType(fieldType === 'password' ? 'text' : 'password')} checked={fieldType === 'text'} />
            <h6 className='mt-1' style={{ color: '#7E818F' }}> Show Password</h6>
          </div>
        </div>
        {error &&
          <Alert id='error-alert' className='my-2' style={{ 'maxWidth': '100%' }} variant="danger">
            <pre className='w-100 mb-0'>{error}</pre>
          </Alert>
        }
        <StyledButton type='submit'>Set Password</StyledButton>
      </form>
    </PasswordContainer>
  )
}

export default ResetPassword;
// export default connect()(Password);
