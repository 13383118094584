import React, { Component } from 'react';
import styled from 'styled-components';
import { Input, Button, Select, EditButton } from '../components';
import { states } from '../helpers';
import NumberFormat from 'react-number-format';
import { Elements, StripeProvider } from 'react-stripe-elements';
import StripeForm from '../components/RegisterPractice/StripeForm'
import { connect } from 'react-redux';
import { updatePracticeAndSub } from '../actions/practiceActions';
import { signOut } from '../actions';
import Cookies from 'js-cookie';

const BillingDetails = styled.div`
width: 45%;
padding: 25px 100px 20px 50px;
border: 1px solid #D3D3D3;
box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.10);
border-radius: 3px;

@media (max-width:768px) {
    padding: 15px;
}
`;

const RowDiv = styled.div`
&:after {
  content: '';
  display: table;
  clear: both;
}
`;

const ColumnDiv = styled.div`
  float: left;
  width: 50%;
  @media (max-width:689px) {
    width:100%;
  }
`;

const StyledDiv = styled.div`
  font-size: 11px;
  color: var(--light-text-color);
`;

const CreditCardDiv = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: var(--background-color);
  border: 0.5px solid #d6d7d9;
  width: 266px;
  @media (max-width:480px) {
    width: 100%;
     }
`;

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  display:block;
  text-transform: uppercase;
  margin: 0;
`;

const StyledButton = styled(Button)`
  width: auto;
  min-width: initial;
  width: 25%;
`;

/**
 * Billing component
 *
 * @component
 * @example
 * return (
 *    <Billing/>
 * )
 */
class UpdateSubscription extends Component {
  state = {
    address: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    name: '',
    cardHolderName: '',
    billingCycle: '',
    _id: '',
    billingFailed: ''
  };
  componentDidMount() {
    if (!Cookies.get('token')) {
      this.backToSignIn();
    } else {
      const { practice: { billingAddress, name, _id, billingCycle, billingFailed } } = this.props;
      this.setState({ ...billingAddress, name, _id, billingCycle, billingFailed });
    }

  };
  componentWillReceiveProps = (updatedProps) => {
    const { practice, stripe } = updatedProps;
    this.setState(stripe);
  }
  handleNameChange = ({ target: { value, name } }) => {
    this.setState({ name: value });
  };
  handleCardNameChange = ({ target: { value } }) => {
    this.setState({ cardHolderName: value });
  };
  handleDropdownChange = ({ value }, name) => {
    this.setState({
      [name]: value
    });
  };
  handleChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value
    });
  };
  handleSave = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const stripe = this.StripeForm1.current.state.stripe;
    let practice = this.state;
    const context = this;
    stripe.createToken().then(async function (result) {
      if (result.error) {
        alert(result.error.message);
      } else {
        try {
          const data = await dispatch(updatePracticeAndSub(practice, result.token));
          if (data) {
            setTimeout(() => {
              context.backToSignIn();
            }, 2000);
          }
        } catch (error) {
          console.error('======================================');
          console.error('error in the component' + error);
          console.error('======================================');
        }
      }
    });
  };
  backToSignIn = async () => {
    localStorage.removeItem('state');
    await this.props.dispatch(signOut());
    window.location.href = '/signin';
  };
  render() {
    const { address, address2, zip, city, state, cardHolderName, name } = this.state;
    this.StripeForm1 = React.createRef();
    return (
      <React.Fragment>
        <BillingDetails id='billingList'>
          <form onSubmit={this.handleSave}>
            <RowDiv><h3 className='text-danger text-center'>Whoops! Looks like there’s an issue with your payment method. Please update your billing details to resume using Ocoord.</h3></RowDiv>
            <RowDiv>
              <ColumnDiv>
                <br />
                <Input
                  name='name'
                  value={name}
                  placeholder='Name'
                  label='PRACTICE NAME'
                  required
                  disable={true}
                  style={{ width: '175px', display: 'block' }}
                />
                <Input
                  onChange={this.handleChange}
                  name='address'
                  value={address}
                  placeholder='Address'
                  label='BILLING ADDRESS'
                  required
                  style={{ width: '175px', display: 'block' }}
                />
                <Input
                  onChange={this.handleChange}
                  name='address2'
                  value={address2}
                  placeholder='Address Line 2'
                  style={{ width: '175px', display: 'block' }}
                />
                <Input
                  onChange={this.handleChange}
                  name='city'
                  value={city}
                  label='City'
                  placeholder='City'
                  required
                  style={{ width: '175px', display: 'block' }}
                />
                <div style={{ width: '175px' }}>
                  <Select
                    options={states}
                    name='state'
                    handleDropdownChange={(e) => {
                      this.handleDropdownChange(e.target, 'state');
                    }}
                    placeholder={state}
                    value={states.find(st => st.value === state)}
                    label='State'
                    defaultValue={state}
                    width='175px'
                    required
                    style={{ width: '175px', display: 'block' }}
                  />
                </div>
                <NumberFormat
                  onChange={this.handleChange}
                  value={zip}
                  name='zip'
                  label='ZIP Code'
                  placeholder='ZIP Code'
                  format='#####'
                  required
                  style={{ width: '175px', display: 'block' }}
                  customInput={Input}
                />
              </ColumnDiv>
              <ColumnDiv>
                <br />
                <br />
                <CreditCardDiv className='mt-2'>
                  <StyledDiv>CREDIT CARD</StyledDiv>
                  <Input
                    onChange={this.handleCardNameChange}
                    value={cardHolderName}
                    name='cardHolderName'
                    label='Cardholder Name'
                    placeholder='Cardholder Name'
                    required
                  />
                  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
                    <Elements>
                      <StripeForm ref={this.StripeForm1} />
                    </Elements>
                  </StripeProvider>
                </CreditCardDiv>
              </ColumnDiv>
            </RowDiv>
            <div className="text-center">
              <StyledButton type='submit'>Save</StyledButton>
              <br />
              <a onClick={this.backToSignIn} href="javascript:void(0)">Sign In</a>
            </div>
          </form>
        </BillingDetails>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state,
  practice: state.user.currentUser.practice,
  stripe: state.admin.stripe,
});

export default connect(mapStateToProps)(UpdateSubscription);
