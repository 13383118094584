import { useState } from "react";
import { FormInput, FormLabel, HeaderContainer, Heading, OtpContainer, PersonalInfoContainer, SubHeading } from "../Registration/Style";
import OtpInput from 'react-otp-input';
import LockIcon from '../../assets/images/big-lock.svg';
import { Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const Verification = ({ userInfo: currentUser, verificationOf, sendOptOnPhone, verifyAndOnboard, sendOptOnEmail }) => {
    const [emailOtp, setEmailOtp] = useState('');
    const [phoneOtp, setPhoneOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const verifyOtp = async () => {
        try {
            setLoading(true);
            if (verificationOf === 'email' || verificationOf === 'both') await axios.post('/api/v2/verifyOtp', { method: 'email', email: currentUser.email, otp: emailOtp });

            if (verificationOf === 'phone' || verificationOf === 'both') await axios.post('/api/v2/verifyOtp', { method: 'phone', phone: currentUser.phone, otp: phoneOtp });
            await verifyAndOnboard();

            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.status !== 500) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data,
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                });
            };
        }
    };

    return (
        <PersonalInfoContainer>
            <HeaderContainer>
                <Heading className="mb-3"><img src={LockIcon} alt="" /></Heading>
                <Heading>Verification</Heading>
                <SubHeading className="px-5">We just sent a code to your {verificationOf === 'both' ? 'work email and phone' : verificationOf === 'phone' ? 'phone' : 'work email'}. Please check and enter the code here.</SubHeading>
            </HeaderContainer>
            {(verificationOf === 'email' || verificationOf === 'both') &&
                <OtpContainer>
                    <div>
                        <FormLabel>Email OTP</FormLabel>
                        <OtpInput
                            inputType='text'
                            inputStyle='otpInput'
                            value={emailOtp}
                            onChange={setEmailOtp}
                            numInputs={4}
                            renderSeparator={<span className="mx-4">-</span>}
                            renderInput={(props) => <FormInput {...props} />}
                        />
                        <p className="mt-2 text-right">Didn’t receive a code? <span onClick={sendOptOnEmail} className="text-success cursor-pointer">Resend code</span></p>
                    </div>
                </OtpContainer>
            }
            {(verificationOf === 'phone' || verificationOf === 'both') &&
                <OtpContainer>
                    <div>
                        <FormLabel>Phone OTP</FormLabel>
                        <OtpInput
                            inputType='text'
                            inputStyle='otpInput'
                            value={phoneOtp}
                            onChange={setPhoneOtp}
                            numInputs={4}
                            renderSeparator={<span className="mx-4">-</span>}
                            renderInput={(props) => <FormInput {...props} />}
                        />
                        <p className="mt-2 text-right">Didn’t receive a code? <span onClick={sendOptOnPhone} className="text-success cursor-pointer">Resend code</span></p>
                    </div>
                </OtpContainer>
            }
            <Button disabled={((verificationOf === 'email' || verificationOf === 'both') && emailOtp.length !== 4) || ((verificationOf === 'phone' || verificationOf === 'both') && phoneOtp.length !== 4) || loading} type='button' onClick={verifyOtp} size="lg" className="w-100 mt-5" variant="success">{loading ? 'Loading...' : 'Next'}</Button>
        </PersonalInfoContainer>
    )
}

export default Verification;