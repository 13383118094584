import actions from '../actions/actionTypes';

/**
 * @namespace notification
 * @category Reducers
 */

/**
 * @memberOf notification
 */
const initialState = { list: [], count: 0 };

/**
 * @method
 * @memberOf notification
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const notification = (state = initialState, action) => {
    switch (action.type) {
        case actions.LOAD_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                list: action.list
            };
        case actions.LOAD_NOTIFICATION_LIST_FAILURE:
            return {
                ...state,
                list: []
            };
        case actions.LOAD_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                count: action.count
            };
        case actions.LOAD_NOTIFICATION_COUNT_FAILURE:
            return {
                ...state,
                count: 0
            };
        default:
            return state
    }

};