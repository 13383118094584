import React, { Component } from 'react';
import { Col, Figure, Spinner, Container, Alert } from 'react-bootstrap';
import axios from 'axios';

import { SearchInput, CustomCard, CardBody, IconContainer, ImageContainer, ContentHeader } from '../Administration/Style';

import violenceIncidentForm from '../../assets/forms-icon/violenceIncidentForm.svg';
import exposureIncidentForm from '../../assets/forms-icon/exposureIncidentForm.svg';
import sharpsInjuryForm from '../../assets/forms-icon/sharpsInjuryForm.svg';
import chemicalExposureForm from '../../assets/forms-icon/chemicalExposureForm.svg';
import eval_sharps_disposal from '../../assets/forms-icon/eval_sharps_disposal.svg';
import hepatitisBVaccineConsentForm from '../../assets/forms-icon/hepatitisBVaccineConsentForm.svg';
import sharpsDisposalContainerEvaluationForm from '../../assets/forms-icon/sharpsDisposalContainerEvaluationForm.svg';
import ECRIsNeedleStickPreventionDeviceEvaluationForm from '../../assets/forms-icon/ECRIsNeedleStickPreventionDeviceEvaluationForm.svg';
import safetyDentalSyringesForm from '../../assets/forms-icon/safetyDentalSyringesForm.svg';
import SlipTripandFallForm from '../../assets/forms-icon/SlipTripandFallForm.svg';
import quarterlySafetyReportForm from '../../assets/forms-icon/quarterlySafetyReportForm.svg';
import needlestickSafetyAndPreventionAnnualRiskAssessment from '../../assets/forms-icon/needlestickSafetyAndPreventionAnnualRiskAssessment.svg';
import tbRiskAssessment from '../../assets/forms-icon/tbRiskAssessment.svg';
import uploadHealthDocumentForm from '../../assets/forms-icon/uploadHealthDocumentForm.svg';
import { HealthRecordDetails } from '../../helpers';
import { FormContainer, FormListContainer } from './Style';

const icons = { violenceIncidentForm, sharpsDisposalContainerEvaluationForm, exposureIncidentForm, sharpsInjuryForm, chemicalExposureForm, eval_sharps_disposal, hepatitisBVaccineConsentForm, ECRIsNeedleStickPreventionDeviceEvaluationForm, safetyDentalSyringesForm, SlipTripandFallForm, quarterlySafetyReportForm, needlestickSafetyAndPreventionAnnualRiskAssessment, tbRiskAssessment, uploadHealthDocumentForm };


class FormsList extends Component {
    state = {
        formsList: [],
        healthDocList: [HealthRecordDetails],
        loading: false,
        errors: { apiError: false },
        search: ''
    };

    componentDidMount() {
        this.getFormsList()
    };

    getFormsList = async () => {
        try {
            this.setState({ loading: true });
            const { data: { payload: formsList } } = await axios.get('/api/v2/forms');
            this.setState({ loading: false, formsList });
        } catch (error) {
            this.setState({ loading: false, errors: { apiError: true, errorMessage: 'Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.' } });
        }
    };

    onSelect = (formId) => {
        const { history } = this.props;
        history.push('/forms/view?formId=' + formId);
    };

    render() {
        const { search, formsList, healthDocList, errors: { apiError, errorMessage }, loading } = this.state;
        const formListTemp = search ? formsList.filter((form) => form.name.toLowerCase().includes(search.toLowerCase())) : formsList;
        const healthDocListTemp = search ? healthDocList.filter((form) => form.name.toLowerCase().includes(search.toLowerCase())) : healthDocList;
        return (
            <FormListContainer>
                <div className="formHeader">
                    <h2 className='my-0'>Forms</h2>
                    <p className='my-0'>Select a form you are creating</p>
                </div>
                <SearchInput onChange={(e) => { this.setState({ search: e.target.value }) }} value={search} type='text' className='pl-5 col-lg-3 col-md-6 col-sm-12' placeholder='Search...' />
                {loading ?
                    <Container className='text-center my-3'>
                        <Spinner animation="border" variant="success" />
                    </Container>
                    :
                    apiError && !formsList.length ?
                        <FormContainer>
                            <Col>
                                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                    <p className='w-100 mb-0'>{errorMessage}</p>
                                </Alert>
                            </Col>
                        </FormContainer> :
                        <FormContainer>
                            <ContentHeader className='mt-0 pb-0'>Incident Report ({formListTemp.length})</ContentHeader>
                            {formListTemp.map((form) => (
                                <Col key={form.id} xs={12} md={6} className="mb-4">
                                    <CustomCard onClick={(e) => this.onSelect(form.id)}>
                                        <CardBody>
                                            <IconContainer>
                                                <ImageContainer
                                                    width='56'
                                                    height='56'
                                                    src={icons[form.id]}
                                                />
                                                <Figure.Caption>
                                                    <div className="text-success">
                                                        <h5>{form.name}</h5>
                                                    </div>
                                                    {form.description}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            ))}
                            <ContentHeader className='mt-0 pb-0'>Health Document ({healthDocListTemp.length})</ContentHeader>
                            {healthDocListTemp.map((healthDoc) => (
                                <Col key={healthDoc.id} xs={12} md={6} className="mb-4">
                                    <CustomCard onClick={(e) => this.onSelect(healthDoc.id)}>
                                        <CardBody>
                                            <IconContainer>
                                                <ImageContainer
                                                    width='56'
                                                    height='56'
                                                    src={icons[healthDoc.id]}
                                                />
                                                <Figure.Caption>
                                                    <div className="text-success">
                                                        <h5>{healthDoc.name}</h5>
                                                    </div>
                                                    {healthDoc.description}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            ))}
                        </FormContainer>
                }
            </FormListContainer>
        );
    }
}

export default FormsList;