import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import chrome from '../assets/images/chrome.jpg';

const BannerContainer = styled.div`
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  border: solid 1px #00000020;
  border-radius: 4px;
  padding: 10px 15px;
  z-index: 10000;
  background: #fff;
  left: 50%;
  width: 715px;
  transform: translateX(-50%);
  @media (max-width: 930px) {
    width: 80%;
  }
  p {
    margin: 0 50px 0 0;
    color: #000;
    font-size: 14px;
  }
  margin-top:5rem;
`;

const BannerLink = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 30px;
  }
  a {
    margin-right: 10px;
    color: var(--key-color);
    font-size: 14px;
    font-weight: 400;
  }
`;

const CloseIcon = styled.div`
  font-size: 12px;
  margin: 0 20px 0 0;
  background: #f0f8ff;
  padding: 5px 10px;
  border-radius: 50%;
  font-weight: 200;
  cursor: pointer;
`;

export const IEBanner = () => {
  const [showBanner, updateBannerState] = useState(true);
  useEffect(() => {
    const ie = (agent) => {
      switch (true) {
        case agent.indexOf('edge') > -1:
          return true;
        case agent.indexOf('trident') > -1:
          return true;
        case agent.indexOf('edg') > -1:
          return false;
        default:
          return false;
      }
    };
    updateBannerState(ie(window.navigator.userAgent.toLowerCase()));
  }, []);
  return (
    showBanner && (
      <BannerContainer>
        <CloseIcon onClick={() => updateBannerState(false)}>X</CloseIcon>
        <p>You're using an unsupported browser, so Ocoord may not work properly.</p>
        <BannerLink>
          <a
            href='https://www.google.com/chrome/'
            target='_blank'
            rel='noopener noreferrer'>
            Try Chrome
          </a>
          <img src={chrome} alt='chrome icon' />
        </BannerLink>
      </BannerContainer>
    )
  );
};
