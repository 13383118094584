import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Card } from '../Card';
import { getAdminsForCurrentLocation } from '../../actions';

const MainContainer = styled(Card)`
    display: flex;
    flex-direction: column;  
    width: 528px;
    padding: 20px 50px;
    min-height:28vh;
    align-items: baseline;
    max-height:72vh;
    overflow-y: auto;

    @media (max-width:991px) {
        display:block;
        width:100%;
        padding:10px;
        max-height:100%;    
       }
    h2 {
        padding-bottom:10px;
        border-bottom: 1px solid #d3d3d3;
        width:100%
        padding-left: 2px;
    }
    ol{
        @media (max-width:768px) {
            padding: 0 0 0 13px;
            /* max-width: 291px; */
            font-size: 12px;

        }
    }
`;
const DetailsList = styled.ol`
    width:100%;
    font-weight: 600;
    font-size: 14px;
    padding-left: 1rem;
`;
const ParaList = styled.div`
white-space: nowrap;
`;
const InfoContainer = styled.div`
  width:95%
  display: flex;
  p {
    font-weight: 600;
    font-size: 13px;
    word-break: normal;
    min-width: 46px;
    &:first-of-type {
      color: var(--light-text-color);
    }
    @media (max-width:480px) {
        font-size: 12px;
        word-break: break-word;
    min-width: 91px;

    }
  }
`;
class UsersAdmins extends React.Component {
    state = {

    };
    componentDidMount() {
        this.props.dispatch(getAdminsForCurrentLocation());
    };
    render() {
        const { adminsForCurrentLocation, user } = this.props;
        return (
            <MainContainer>
                <h2>My Admin</h2>
                <DetailsList>
                    {adminsForCurrentLocation && user && adminsForCurrentLocation.length ? adminsForCurrentLocation.map((admin) => (
                        <li key={admin._id}>
                            <InfoContainer >
                                <ParaList>
                                    <p>Name :</p>
                                </ParaList>
                                <p>&nbsp;{admin.fullName} {user.email === admin.email && '(Self)'}</p>
                            </InfoContainer>
                            <InfoContainer>
                                <ParaList>
                                    <p>Email Address :</p>
                                </ParaList>
                                {/* <ParaEmail> */}
                                <p>&nbsp;{admin.email}</p>
                                {/* </ParaEmail> */}
                            </InfoContainer>
                            <InfoContainer>
                                <ParaList>
                                    <p>Phone :</p>
                                </ParaList>
                                <p>&nbsp;{admin.phone}</p>
                            </InfoContainer>
                        </li>
                    )) : <h3>No Data Found.</h3>}
                </DetailsList>
            </MainContainer>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        adminsForCurrentLocation: state.user.adminsForCurrentLocation || []
    };
};

export default connect(mapStateToProps)(UsersAdmins);