import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import arrow from '../assets/images/glyph-arrow-right-green.svg';
import { sendNewCode } from '../actions';

const DetailsListStyled = styled.div`
  /*  border-bottom: 1px solid #d3d3d3; */
  padding: 5px 0;
  h3 {
    font-weight: 600;
    font-size: 11px;
    color: var(--light-text-color);
    line-height: 22px;
    text-transform: uppercase;
    margin: 0;
  }
`;

const JoinCodeContainer = styled.div`
  background: var(--background-color);
  border-radius: 3px;
  padding: 10px 13px;
  p {
    margin: 0;
  }
`;
const CodeBox = styled.div`
  display: inline-block;
  border: 1px solid var(--dark-text-color);
  padding: 5px 8px;
  color: ${(props) =>
    props.joinCodeExpired ? '#AAABB1' : 'var(--dark-text-color)'};
  &:after {
    content: '';
    display: ${(props) => (props.joinCodeExpired ? 'block' : 'none')};
    width: 100%;
    height: 1px;
    margin-top: -9px;
    margin-bottom: 8px;
    background: #aaabb1;
  }
`;
const InfoContainer = styled.div`
  margin: 25px 0 0 0;
  margin-top: ${({ code, isAlt }) => (!code && isAlt ? '0' : '15px')};
  p {
    margin: 0;
    &:first-of-type {
      font-weight: 600;
      font-size: 11px;
      color: var(--light-text-color);
      line-height: 22px;
      text-transform: uppercase;
      margin: 0;
    }
  }
`;
const ArrowButton = styled.div`
  color: var(--key-color);
  font-weight: 600;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    width: calc(1em - 1px);
    height: calc(1em - 3px);
    margin-left: 5px;
    background-image: url("${arrow}");
    background-size: cover;
    background-repeat: no-repeat;
    transition: margin-left 0.25s ease-out;
  }
  &:hover {
    &::after {
      margin-left: 10px; 
    }
  }
`;
const NewCode = styled.p`
  color: var(--key-color);
  font-weight: 600;
  margin-left: 10px !important;
  cursor: pointer;
`;
const NewCodeContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

class DetailsList extends React.Component {
  handleNewCode = () => {
    this.props.dispatch(sendNewCode());
  };
  render() {
    const {
      title,
      subtext,
      items,
      alt,
      name,
      code,
      email,
      phone,
      joinCodeExpired,
      handleSelect,
      type,
      withChildren,
      children,
      isOnlyOneLocation,
    } = this.props;
    if (!alt) {
      return (
        <DetailsListStyled>
          <h3>{title}</h3>
          {!isOnlyOneLocation && <p>{subtext}</p>}
          {withChildren ? (
            <DetailsListStyled>{children}</DetailsListStyled>
          ) : (
            <ul>
              {items &&
                items.map((item, index) => {
                  return (
                    <li key={index} id={item._id}>
                      <ArrowButton onClick={() => handleSelect(item, type)}>
                        {item.name}
                      </ArrowButton>
                    </li>
                  );
                })}
            </ul>
          )}
        </DetailsListStyled>
      );
    } else {
      return (
        <DetailsListStyled>
          {code && (
            <JoinCodeContainer>
              {joinCodeExpired && (
                <p>
                  {name}’s Join Code has expired. Click “New Code” to create a new
                  Join Code for them.
                </p>
              )}
              {!joinCodeExpired && (
                <p>
                  {name} has not set up their account yet. They can join Ocoord using
                  this code.
                </p>
              )}
              <NewCodeContainer>
                <CodeBox joinCodeExpired={joinCodeExpired}>{code}</CodeBox>
                {joinCodeExpired && (
                  <NewCode onClick={this.handleNewCode}>New Code</NewCode>
                )}
              </NewCodeContainer>
            </JoinCodeContainer>
          )}

          <InfoContainer code={code} isAlt={alt}>
            <p>Name</p>
            <p>{name}</p>
          </InfoContainer>
          <InfoContainer>
            <p>Email Address</p>
            <p>{email}</p>
          </InfoContainer>
          <InfoContainer>
            <p>Phone: </p>
            <p>{phone}</p>
          </InfoContainer>
        </DetailsListStyled>
      );
    }
  }
}

export default connect()(DetailsList);
