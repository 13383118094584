import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NavBar, Header } from '../components';
import { AdvertiseMentPage } from '../pages';

export const AuthRouteSDS = ({ component: Component, authenticated, user, path, history, ...rest }) => {
    let isValidSub = false
    if (user && user.location && user.location.locSubscriptionPlan && ['SDS', 'OSHA&SDS'].includes(user.location.locSubscriptionPlan)) isValidSub = true;
    return (<Route
        {...rest}
        render={(props) =>
            (Cookies.get('token') && user.isRegistrationCompleted === 2) ? (
                !user.isSubscriptionFailed ?
                    <React.Fragment>
                        <NavBar {...props} />
                        <Header {...props} />
                        {isValidSub ? <Component {...props} /> : <AdvertiseMentPage />}
                    </React.Fragment>
                    :
                    <Redirect to='/update_subscription' />)
                :
                <Redirect to='/signin' />
        }
    />)
}
