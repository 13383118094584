import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useState } from "react";
import UserLicensingAgreement from "./UserLicensingAgreement";
import TermsOfUseAndPrivacyPolicy from "./TermsOfUseAndPrivacyPolicy";

const OverLayContainer = styled.div`
    background-color: rgb(0 0 0 / 32%);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
`;

const TaskDetailsContainer = styled.div`
    padding: 3rem;
    height: 100%;
    width: 30vw;
    background-color: #FCFCFC;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        width: 100vw;
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 80vw;
    }
`;

const Documents = styled.div`
    cursor: pointer !important;
    background: var(--base-white, #fcfcfc);
    border-radius: 7px;
    border-style: solid;
    border-color: var(--gray-70, #efefef);
    border-width: 1px;
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    height: 220px;
    position: relative;
    box-shadow: var(--shadow-box-shadow, 0px 1px 2px 0px rgba(0, 0, 0, 0.07));
    overflow: hidden;

.ellipse-1 {
    background: var(--lightgreen-100, #f3f9f5);
    border-radius: 50%;
    flex-shrink: 0;
    width: 781px;
    height: 391.89px;
    position: absolute;
    left: 504.19px;
    top: -407px;
    transform-origin: 0 0;
    transform: rotate(34.008deg) scale(1, 1);
}

.ellipse-2 {
    background: var(--lightgreen-100, #f3f9f5);
    border-radius: 50%;
    flex-shrink: 0;
    width: 781px;
    height: 391.89px;
    position: absolute;
    left: -343.81px;
    top: -123px;
    transform-origin: 0 0;
    transform: rotate(34.008deg) scale(1, 1);
}

.title {
    color: var(--primary-100, #42a764);
    text-align: center;
    font-family: 'Hauora';
    font-size: 20px;
    font-weight: 600;
    position: relative;
}

.title2 {
    color: #676767;
    text-align: center;
    font-family: 'Hauora';
    font-size: 14px;
    font-weight: 400;
    position: relative;
    width: 393px;
}

.logo {
    flex-shrink: 0;
    width: 77px;
    height: 70px;
    position: absolute;
    right: -6.02%;
    left: 93.82%;
    width: 12.2%;
    bottom: 55%;
    top: 13.18%;
    height: 31.82%;
    overflow: visible;
}

.logo2 {
    flex-shrink: 0;
    width: 77px;
    height: 70px;
    position: absolute;
    right: 93.98%;
    left: -6.18%;
    width: 12.2%;
    bottom: 23.18%;
    top: 45%;
    height: 31.82%;
    overflow: visible;
}
`;

const Agreement = ({ onClose }) => {
    const [currentPage, setCurrentPage] = useState('documents');

    return (
        <OverLayContainer>
            <TaskDetailsContainer>
                {currentPage === 'documents' &&
                    <>
                        <Button onClick={() => onClose(false)} type='button' variant='light' className='text-success px-4'>
                            <strong>{'<< Back'}</strong>
                        </Button>
                        <Documents onClick={() => setCurrentPage('termsOfUseAndPrivacyPolicy')} className="mt-3">
                            <div className="ellipse-1">
                            </div>
                            <div className="ellipse-2">
                            </div>
                            <div className="title">User Licensing Agreement</div>
                            <div className="title2">
                                Our Terms of Service outline the rules and guidelines for using Ocoord. Review it to understand the expectations and responsibilities associated with using Occord.
                            </div>
                            <svg className="logo" width="77" height="70" viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M73.4975 27.3212L71.9825 25.9473L62.0838 34.9779L38.5186 56.4483L24.2737 43.528L14.375 52.5587L30.1348 66.8529C32.3659 68.8717 35.3867 70.0037 38.5347 70.0007C41.6826 69.9977 44.7007 68.8599 46.9271 66.8368L73.5365 42.6089C74.6385 41.6029 75.5116 40.4094 76.106 39.0966C76.7005 37.7838 77.0046 36.3774 77.0009 34.9577C76.9973 33.538 76.686 32.1329 76.0849 30.8226C75.4838 29.5123 74.6045 28.3225 73.4975 27.3212Z" fill="url(#paint0_linear_1766_18688)" />
                                <path d="M14.8973 35.0209L38.4661 13.5505L58.1109 31.357L68.0096 22.3263L46.8605 3.14587C44.6295 1.12773 41.6091 -0.00361908 38.4617 8.69775e-06C35.3144 0.00363648 32.2971 1.14192 30.0717 3.16521L3.46238 27.3932C1.24185 29.4208 -0.00331897 32.1663 6.64445e-06 35.0275C0.00333225 37.8886 1.25488 40.6317 3.48012 42.655L10.3844 48.9152L20.283 39.8846L14.8973 35.0209Z" fill="#232323" />
                                <defs>
                                    <linearGradient id="paint0_linear_1766_18688" x1="45.7119" y1="25.9409" x2="45.7139" y2="69.9751" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#69FF90" />
                                        <stop offset="1" stopColor="#2E9968" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <svg className="logo2" width="77" height="70" viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M73.4975 27.3212L71.9825 25.9473L62.0838 34.9779L38.5186 56.4483L24.2737 43.528L14.375 52.5587L30.1348 66.8529C32.3659 68.8717 35.3867 70.0037 38.5347 70.0007C41.6826 69.9977 44.7007 68.8599 46.9271 66.8368L73.5365 42.6089C74.6385 41.6029 75.5116 40.4094 76.106 39.0966C76.7005 37.7838 77.0046 36.3774 77.0009 34.9577C76.9973 33.538 76.686 32.1329 76.0849 30.8226C75.4838 29.5123 74.6045 28.3225 73.4975 27.3212Z" fill="url(#paint0_linear_1766_18691)" />
                                <path d="M14.8973 35.0209L38.4661 13.5505L58.1109 31.357L68.0096 22.3263L46.8605 3.14587C44.6295 1.12773 41.6091 -0.00361908 38.4617 8.69775e-06C35.3144 0.00363648 32.2971 1.14192 30.0717 3.16521L3.46238 27.3932C1.24185 29.4208 -0.00331897 32.1663 6.64445e-06 35.0275C0.00333225 37.8886 1.25488 40.6317 3.48012 42.655L10.3844 48.9152L20.283 39.8846L14.8973 35.0209Z" fill="#232323" />
                                <defs>
                                    <linearGradient id="paint0_linear_1766_18691" x1="45.7119" y1="25.9409" x2="45.7139" y2="69.9751" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#69FF90" />
                                        <stop offset="1" stopColor="#2E9968" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </Documents>
                        <Documents onClick={() => setCurrentPage('userLicensingAgreement')} className="mt-3">
                            <div className="ellipse-1">
                            </div>
                            <div className="ellipse-2">
                            </div>
                            <div className="title">Terms of Use and Privacy Policy</div>
                            <div className="title2">
                                Our Privacy Policy is designed to inform you about how we collect, use, disclose, and safeguard your personal information when you use the Occord.
                            </div><svg className="logo" width="77" height="70" viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M73.4975 27.3212L71.9825 25.9473L62.0838 34.9779L38.5186 56.4483L24.2737 43.528L14.375 52.5587L30.1348 66.8529C32.3659 68.8717 35.3867 70.0037 38.5347 70.0007C41.6826 69.9977 44.7007 68.8599 46.9271 66.8368L73.5365 42.6089C74.6385 41.6029 75.5116 40.4094 76.106 39.0966C76.7005 37.7838 77.0046 36.3774 77.0009 34.9577C76.9973 33.538 76.686 32.1329 76.0849 30.8226C75.4838 29.5123 74.6045 28.3225 73.4975 27.3212Z" fill="url(#paint0_linear_1766_18688)" />
                                <path d="M14.8973 35.0209L38.4661 13.5505L58.1109 31.357L68.0096 22.3263L46.8605 3.14587C44.6295 1.12773 41.6091 -0.00361908 38.4617 8.69775e-06C35.3144 0.00363648 32.2971 1.14192 30.0717 3.16521L3.46238 27.3932C1.24185 29.4208 -0.00331897 32.1663 6.64445e-06 35.0275C0.00333225 37.8886 1.25488 40.6317 3.48012 42.655L10.3844 48.9152L20.283 39.8846L14.8973 35.0209Z" fill="#232323" />
                                <defs>
                                    <linearGradient id="paint0_linear_1766_18688" x1="45.7119" y1="25.9409" x2="45.7139" y2="69.9751" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#69FF90" />
                                        <stop offset="1" stopColor="#2E9968" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <svg className="logo2" width="77" height="70" viewBox="0 0 77 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M73.4975 27.3212L71.9825 25.9473L62.0838 34.9779L38.5186 56.4483L24.2737 43.528L14.375 52.5587L30.1348 66.8529C32.3659 68.8717 35.3867 70.0037 38.5347 70.0007C41.6826 69.9977 44.7007 68.8599 46.9271 66.8368L73.5365 42.6089C74.6385 41.6029 75.5116 40.4094 76.106 39.0966C76.7005 37.7838 77.0046 36.3774 77.0009 34.9577C76.9973 33.538 76.686 32.1329 76.0849 30.8226C75.4838 29.5123 74.6045 28.3225 73.4975 27.3212Z" fill="url(#paint0_linear_1766_18691)" />
                                <path d="M14.8973 35.0209L38.4661 13.5505L58.1109 31.357L68.0096 22.3263L46.8605 3.14587C44.6295 1.12773 41.6091 -0.00361908 38.4617 8.69775e-06C35.3144 0.00363648 32.2971 1.14192 30.0717 3.16521L3.46238 27.3932C1.24185 29.4208 -0.00331897 32.1663 6.64445e-06 35.0275C0.00333225 37.8886 1.25488 40.6317 3.48012 42.655L10.3844 48.9152L20.283 39.8846L14.8973 35.0209Z" fill="#232323" />
                                <defs>
                                    <linearGradient id="paint0_linear_1766_18691" x1="45.7119" y1="25.9409" x2="45.7139" y2="69.9751" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#69FF90" />
                                        <stop offset="1" stopColor="#2E9968" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </Documents>
                    </>
                }
                {currentPage !== 'documents' &&
                    <Button onClick={() => setCurrentPage('documents')} type='button' variant='light' className='text-success px-4'>
                        <strong>{'<< Documents'}</strong>
                    </Button>
                }
                {currentPage === 'termsOfUseAndPrivacyPolicy' && <TermsOfUseAndPrivacyPolicy />}
                {currentPage === 'userLicensingAgreement' && <UserLicensingAgreement />}
            </TaskDetailsContainer>
        </OverLayContainer>
    )
}


export default Agreement;