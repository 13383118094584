import React from 'react';
import { Modal } from 'react-bootstrap';
import { apiUrl } from '../../helpers';

const ViewSDS = ({ sdsDetails, show, handleClose }) => {

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size='xl'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{ height: '85vh' }} >
                <iframe title={sdsDetails.chemicalName} src={apiUrl('/api/v2/sdsDoc/' + sdsDetails._id)} width='100%' style={{ height: '100%' }} />
            </Modal.Body>
        </Modal>
    );
}

export default ViewSDS;