import actions from './actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiUrl } from '../helpers';
// Regular Actions

/**
 * @namespace practiceActions
 * @category Actions
 */

/**
 *
 * @memberof practiceActions
 * @param {*} user
 */
export const practiceSuccess = (user) => ({
  type: actions.REGISTER_NEW_PRACTICE_SUCCESS,
  user
});

/**
 *
 * @memberof practiceActions
 * @param {*} user
 */
const updatePracticeSuccess = (practice) => ({
  type: actions.UPDATE_PRACTICE_SUCCESS,
  practice
});

/**
 * @method
 * @async
 * @memberof practiceActions
 * @param {*} practice
 */
export const registerPractice = (practice) => async (dispatch) => {
  try {
    const { data } = await axios.post(apiUrl(`/api/v2/practice/register`), practice);
    await dispatch(practiceSuccess(data));
    return data;
  } catch (err) {
    toast.error(`error in registerPractice: ${err.response ? (err.response.data && err.response.data.error) : 'An Error Ocurred'}`);
    return err;
  }
};
// Billing 
export const addBillingDetails = async (billingData, currentUser) => {
  try {
    const { data } = await axios.post(`/api/v2/practice/billing/${currentUser.practice._id}`, billingData);
    await practiceSuccess(data);

    return data;
  } catch (err) {
    console.log("🚀 ~ file: practiceActions.js:66 ~ addBillingDetails ~ err:", err);
    toast.error(`error in addBillingDetails: ${err.response ? (err.response.data && err.response.data.error) : 'An Error Ocurred'}`);
    throw err;
  }
};

const deleteUser = (practiceID) => async (dispatch) => {
  const { data } = await axios.get(
    apiUrl(`/api/v1/practice/${practiceID}/deleteUser`)
  );
}
/**
 * @method
 * @async
 * @memberOf practiceActions
 */
export const updatePractice = (practice, token) => async (dispatch, getState) => {
  try {
    const { data } = await axios.put(
      apiUrl(`/api/v1/practice/${practice._id}`),
      practice
    );
    if (token && data) {
      const { stripeData } = await axios.put(
        apiUrl(`/api/v1/practice/${practice._id}/updateCard/${token.id}`),
        practice
      );
      data.stripe = stripeData;
    }
    toast.success('Successfully updated billing info.');
    dispatch(updatePracticeSuccess(data));
    return data;
  } catch (err) {
    toast.error(
      ` error in updatePractice: ${err.response
        ? err.response.data
        : 'An Error Ocurred while updating billing details'
      }`
    );
    return false;
  };
};
/**
 * @method
 * @async
 * @memberOf practiceActions
 */
export const updatePracticeAndSub = (practice, token) => async (dispatch) => {
  try {
    const { data } = await axios.put(apiUrl(`/api/v1/updatePracticeAndSubscription`), { practiceForUpdate: practice, cardToken: token.id, id: practice._id, cardHolderName: practice.cardHolderName });
    toast.success('Successfully updated billing info.');
    return data;
  } catch (err) {
    console.error(err.response.data);
    toast.error(` error in updatePractice: ${err.response ? err.response.data.error : 'An Error Ocurred while updating billing details'}`);
    return false;
  };
};