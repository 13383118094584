import actions from './actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiUrl } from '../helpers';

/**
 * @namespace equipmentActions
 * @category Actions
 */

/**
 * @method
 * @memberOf equipmentActions
 * @param {*} Equipment
 */
const getEquipmentSuccess = (Equipment) => ({
  type: actions.GET_EQUIPMENT_SUCCESS,
  Equipment
});

/**
 * @method
 * @memberOf equipmentActions
 * @param {*}
 */
const getEquipmentFailure = () => ({
  type: actions.GET_EQUIPMENT_FAILURE
});


/**
 * @method
 * @async
 * @memberOf equipmentActions
 * @param {*} locationId
 */
export const getEquipment = (locationId) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      apiUrl(`/api/v1/safetyEquipment?locationId=${locationId}`)
    );
    dispatch(getEquipmentSuccess(data));
  } catch (err) {
    dispatch(getEquipmentFailure());
    console.error(err);
    toast.error(`error in getEquipment ${err.response ? err.response.data.message : 'An Error Ocurred Getting Safety Equipment'}`);
  }
};