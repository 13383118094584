import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import axios from "axios";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES, MARKS } from "@contentful/rich-text-types";

import CloseGrayIcon from '../../assets/images/glyph-disclosure-open-dark-gray.svg';
import OpenGreenIcon from '../../assets/images/glyph-disclosure-close-green.svg';
import { MultiAnswerCodeView, SingleAnswerCodeView, TableAnswerCodeView, LibraryViewContainer, SidePanel, AccordionCard, AccordionTitleContainer, AccordionTitle, AccordionIcon, AccordionSubSection, AccordionSubTitle, HeaderContainer, HeaderText, SearchInput, LibraryContainerBody, DetailsSection, PageSection, PageHeader, PageContent, PageFooter, PageFooterNavigation, MultiUserSelect, SingleUserSelect } from "../../components";
import { Spinner } from "react-bootstrap";
import { CSSTransition, SwitchTransition } from "react-transition-group";


const LibraryView = () => {
    const currentUser = useSelector(state => state.user.currentUser);
    const [searchValue, setSearchValue] = useState('');
    const [pageContainLoading, setPageContainLoading] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [allPages, setAllPages] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    const [openSections, setOpenSections] = useState([]);
    const [activePage, setActivePage] = useState({});
    const [libraryData, setLibraryData] = useState([]);
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        getUsersList();
        getLibData();
        getSectionData();
    }, []);

    const getUsersList = async () => {
        try {
            const { data: { payload } } = await axios.get(`/api/v2/master/users_by_location/${currentUser.location._id}`);
            setUsersList(payload);
        } catch (error) {
            console.error("🚀 ~ file: LibraryView.jsx:39 ~ getUsersList ~ error:", error);
        }
    };

    const getSectionData = async (params = '') => {
        try {
            setListLoading(true);
            setSearchValue(params);

            let url = `/api/v2/libraryPages/${currentUser.location._id}`
            if (params) url += '?searchValue=' + params;
            const { data: { payload } } = await axios.get(url);

            const tempSectionList = [];
            const pages = [];
            const tempOpenSections = [];
            if (payload.includes && payload.items) {
                payload.includes.Entry.forEach((ent, index) => tempSectionList.push({ 'index': index, 'title': ent.fields.title, 'id': ent.sys.id }));
                let pageNumber = 0;
                tempSectionList.map(section => {
                    section.pages = [];
                    payload.items.forEach(item => {
                        if (item.fields.parent && item.fields.parent.sys.id === section.id) {
                            section.pages.push({ 'title': item.fields.title, 'id': item.sys.id, pageNumber, sectionId: section.id });
                            pages.push({ 'title': item.fields.title, 'id': item.sys.id, pageNumber, sectionId: section.id });
                            pageNumber++
                        };
                    });
                    if (params && section.pages) {
                        tempOpenSections.push(section.id);
                    };
                });
                setSectionList(tempSectionList);
                setAllPages(pages);
                if (params) setOpenSections(tempOpenSections);
                getPageData(pages[0]);
            } else {
                setSectionList([]);
                setAllPages([]);
                setOpenSections([]);
                setActivePage('');
            }
            setListLoading(false);
        } catch (error) {
            console.error("🚀 ~ file: LibraryView.jsx:133 ~ getSectionData ~ error:", error);
        }
    };

    const getLibData = async () => {
        try {
            const { data: { payload } } = await axios.get(`/api/v2/libraryData/${currentUser.location._id}`);
            setLibraryData(payload.libraryData);
        } catch (error) {
            console.error('error getting library data', error);
            return false;
        }
    };

    const getPageData = async (page) => {
        try {
            setPageContainLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/libraryPage`, { params: { pageId: page.id } });
            setActivePage({ ...page, ...payload.fields, id: payload.sys.id, });
            setOpenSections([page.sectionId]);
            setPageContainLoading(false);
        } catch (error) {
            setPageContainLoading(false);
            console.error("🚀 ~ file: LibraryView.jsx:180 ~ getPageData ~ error:", error);
        }
    };

    const openCloseSections = (sectionId) => {
        if (openSections.includes(sectionId)) {
            const tempSections = openSections.filter(id => id !== sectionId);
            setOpenSections(tempSections);
        } else {
            setOpenSections([...openSections, sectionId]);
        };
    };

    const getCodeValue = (code) => {
        const associatedVariable = typeof code === 'string' ? code.replace(/`/g, '').replace(/ /g, '') : code[0].replace(/`/g, '').replace(/ /g, '');

        if (associatedVariable === 'PRACTICE_NAME') return {
            associatedVariable,
            answer: currentUser.practice.name,
            inputType: 'singleInput',
            stepNumber: 99,
            question: 'Practice Name',
            editOff: true
        };

        const tempResult = libraryData.find(item => item.associatedVariable === associatedVariable);
        if (tempResult) return {
            associatedVariable: tempResult.associatedVariable,
            answer: tempResult.answer,
            inputType: tempResult.type,
            stepNumber: tempResult.stepNumber,
            question: tempResult.question
        };

        return '';

    };

    const escapeRegExp = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

    const getOptions = () => {
        let option = {
            renderText: text => {
                if (text.match(/^`[A-Z_]+`$/)) {
                    return text;
                };
                const regex = new RegExp(`(${escapeRegExp(searchValue)})`, 'gi');
                return text.split(regex).map((chunk, i) =>
                    chunk.match(regex) ? <span key={i} style={{ backgroundColor: 'yellow' }}>{chunk}</span> : chunk
                );
            },
            renderMark: {
                [MARKS.CODE]: (text) => customCodeHandler(text)
            },
            renderNode: {
                [INLINES.HYPERLINK]: ({ data }, children) => (
                    <a
                        href={data.uri}
                        target='_blank'
                    >{children}</a>
                )
            },
        };
        if (!searchValue) delete option.renderText;
        return option;
    };

    const customCodeHandler = (node) => {
        const val = getCodeValue(node);
        if (val && val.answer) {
            switch (val.inputType) {
                case 'singleInput':
                    return <SingleAnswerCodeView answerData={val} user={currentUser} getLibData={getLibData} usersList={usersList} />
                    break;
                case 'table':
                    return <TableAnswerCodeView answerData={val} headers={Object.keys(val.answer[0])} user={currentUser} getLibData={getLibData} usersList={usersList} />
                    break;
                case 'multipleInput':
                    return <MultiAnswerCodeView answerData={val} user={currentUser} getLibData={getLibData} usersList={usersList} />
                    break;
                case 'multipleUserSelect':
                    return <MultiUserSelect usersList={usersList} answerData={val} user={currentUser} getLibData={getLibData} />
                    break;
                case 'singleUserSelect':
                    return <SingleUserSelect usersList={usersList} answerData={val} user={currentUser} getLibData={getLibData} />
                    break;
                default:
                    break;
            };
        };
    };

    return (
        <LibraryViewContainer>
            <HeaderContainer className="mb-4 pb-2">
                <HeaderText>Library</HeaderText>
                <div className="d-flex justify-content-end">
                    <SearchInput placeholder="Search" value={searchValue} onChange={(e) => getSectionData(e.target.value)} type="text" />
                </div>
            </HeaderContainer>
            <LibraryContainerBody>
                <SidePanel className='p-0 pr-2' lg='3' md='12' sm='12'>
                    {listLoading &&
                        <AccordionCard className='text-center'>
                            <Spinner animation="border" variant="success" />
                        </AccordionCard>
                    }
                    {!listLoading && sectionList.map(section => (
                        <AccordionCard key={section.id}>
                            <AccordionTitleContainer onClick={() => openCloseSections(section.id)}>
                                <AccordionTitle>
                                    {section.title}
                                </AccordionTitle>
                                {openSections.includes(section.id) ?
                                    <AccordionIcon src={OpenGreenIcon} alt='' />
                                    :
                                    <AccordionIcon src={CloseGrayIcon} alt='' />
                                }
                            </AccordionTitleContainer>
                            {openSections.includes(section.id) &&
                                <>
                                    <hr />
                                    {section.pages.map(page =>
                                        <AccordionSubSection onClick={() => getPageData(page)} key={page.id} className="mb-2">
                                            <AccordionSubTitle active={page.id === activePage.id}>
                                                {page.title}
                                            </AccordionSubTitle>
                                        </AccordionSubSection>
                                    )}
                                </>}
                        </AccordionCard>
                    ))}
                </SidePanel>

                <DetailsSection lg='9' md='12' sm='12'>
                    <PageSection >
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={pageContainLoading || 'initial'}
                                timeout={300}
                                classNames="fade"
                                unmountOnExit
                            >
                                {listLoading || pageContainLoading ?
                                    <div className="text-center">
                                        <Spinner animation="border" variant="success" />
                                    </div>
                                    :
                                    <div>
                                        <PageHeader>{activePage.title}</PageHeader>
                                        <PageContent>
                                            {documentToReactComponents(activePage.body, getOptions())}
                                        </PageContent>
                                        <PageFooter>
                                            <PageFooterNavigation>
                                                {activePage.pageNumber > 0 &&
                                                    <p onClick={() => getPageData(allPages[activePage.pageNumber - 1])}>
                                                        {'<< ' + allPages[activePage.pageNumber - 1].title}
                                                    </p>
                                                }
                                            </PageFooterNavigation>
                                            <PageFooterNavigation>
                                                {activePage.pageNumber < allPages.length - 1 &&
                                                    <p className="text-right" onClick={() => getPageData(allPages[activePage.pageNumber + 1])}>
                                                        {allPages[activePage.pageNumber + 1].title + ' >>'}
                                                    </p>
                                                }
                                            </PageFooterNavigation>
                                        </PageFooter>
                                    </div>
                                }
                            </CSSTransition>
                        </SwitchTransition>
                    </PageSection>
                </DetailsSection>
            </LibraryContainerBody>
        </LibraryViewContainer>
    )
}

export default LibraryView;