import moment from 'moment';
import React, { memo } from 'react';
import Select from 'react-select';

import { Checkbox, Input } from '../../components';
import { DropDownStyle } from '../../helpers';
import { AnswerBlock, ChoiceLabel, Label, SrNoCircle } from './Style';

const Element = ({ questionNo, label }) => (
    <div className="d-flex">
        <Label>
            <SrNoCircle>{questionNo}</SrNoCircle>
        </Label>
        <Label dangerouslySetInnerHTML={{ __html: label }} />
    </div>
);

const FormGenerator = memo(({ data, question, questionNo, onFormDataChange, members, disabled }) => {
    const { answer } = question;
    const removeValidation = (name) => {
        const elements = document.getElementsByName(name);
        elements && elements.forEach((e) => {
            e.setCustomValidity('');
        });
    };
    if (disabled) {
        setTimeout(() => {
            const el = document.getElementById('physicianLinkId');
            el && el.removeAttribute('href');
            const el1 = document.getElementById('physicianFormUpload');
            el1 && el1.removeAttribute('href');
        }, 50);
    };

    switch (question.type) {
        case 'input':
            return (
                <div>
                    <Label>
                        <SrNoCircle>{questionNo}</SrNoCircle>
                        <Label className='d-block' dangerouslySetInnerHTML={{ __html: question.label }} />
                    </Label>
                    <AnswerBlock className='col-lg-4 col-md-6 col-sm-12'>
                        <Input
                            name={answer.key}
                            value={data[answer.key] || ''}
                            onChange={(e) => { onFormDataChange(answer.key, e.target.value) }}
                            type='text' required={question.required}
                            disable={disabled} />
                    </AnswerBlock>
                </div>
            );
        case 'radio':
            return (
                <div>
                    <Label>
                        <SrNoCircle>{questionNo}</SrNoCircle>
                        <Label className='d-block' dangerouslySetInnerHTML={{ __html: question.label }} />
                    </Label>
                    {question.group.map((choice, index) => (
                        <AnswerBlock key={index}>
                            <input
                                className='align-middle'
                                type='radio'
                                name={answer.key}
                                checked={data[answer.key] === choice.value}
                                onChange={(e) => { onFormDataChange(answer.key, choice.value) }}
                                required={question.required}
                                disabled={disabled}
                            />
                            <ChoiceLabel className='align-middle ml-2'>
                                {choice.value}
                            </ChoiceLabel>
                            {choice.question_blocks &&
                                choice.question_blocks.map((q_blocks, index) => (
                                    <div key={index}>
                                        {q_blocks &&
                                            q_blocks.question_block &&
                                            q_blocks.question_block[index].question &&
                                            data[answer.key] === choice.value &&
                                            q_blocks.question_block.map((q, index) => (
                                                <ul key={index}>
                                                    <FormGenerator
                                                        members={members}
                                                        questionNo={index + 1}
                                                        data={data}
                                                        question={q.question}
                                                        onFormDataChange={onFormDataChange}
                                                        disabled={disabled}
                                                    />
                                                </ul>
                                            ))}
                                    </div>
                                ))
                            }
                        </AnswerBlock>
                    ))}
                </div>
            );
        case 'checkbox': {
            return (
                <div>
                    <Label>
                        <SrNoCircle>{questionNo}</SrNoCircle>
                        <Label className='d-block' dangerouslySetInnerHTML={{ __html: question.label }} />
                    </Label>
                    {question.group.map((choice, index) => (
                        <AnswerBlock key={index}>
                            <Checkbox
                                key={index}
                                type='checkbox'
                                name={question.label.replace(/\s/g, '')}
                                checked={data[choice.key]}
                                disabled={disabled}
                                onChange={(e) => { onFormDataChange(choice.key, !data[choice.key]); removeValidation(question.label.replace(/\s/g, '')) }}
                                onInvalid={e => (e.target.setCustomValidity('Select at least one option.'))}
                                required={question.group.length > 1 ? (question.group.filter((r) => data[r.key] === true).length ? false : true) : false}
                            />
                            <ChoiceLabel className='align-middle ml-2'>
                                {choice.value}
                            </ChoiceLabel>
                            {choice.question_blocks &&
                                choice.question_blocks.map((q_blocks, index) => (
                                    <div>
                                        {q_blocks &&
                                            q_blocks.question_block &&
                                            q_blocks.question_block[index].question &&
                                            data[choice.key] === true &&
                                            q_blocks.question_block.map((q) => (
                                                <ul>
                                                    <FormGenerator
                                                        members={members}
                                                        questionNo={index + 1}
                                                        data={data}
                                                        question={q.question}
                                                        onFormDataChange={onFormDataChange}
                                                        disabled={disabled}
                                                    />
                                                </ul>
                                            ))}
                                    </div>
                                ))}
                        </AnswerBlock>
                    ))}
                </div>
            );
        }
        case 'textarea':
            return (
                <div>
                    <Label>
                        <SrNoCircle>{questionNo}</SrNoCircle>
                        <Label className='d-block' dangerouslySetInnerHTML={{ __html: question.label }} />
                    </Label>
                    <AnswerBlock>
                        <textarea
                            name={answer.key}
                            value={data[answer.key] || ''}
                            className='col-lg-4 col-md-6 col-sm-12'
                            rows='5'
                            wrap='off'
                            onChange={(e) => { onFormDataChange(answer.key, e.target.value) }}
                            required={question.required}
                            disabled={disabled}
                        />
                    </AnswerBlock>
                </div>
            );
        case 'employee-select': {
            return (
                <div>
                    <Label>
                        <SrNoCircle>{questionNo}</SrNoCircle>
                        <Label className='d-block' dangerouslySetInnerHTML={{ __html: question.label }} />
                    </Label>
                    <AnswerBlock className='col-lg-4 col-md-6 col-sm-12 p-0'>
                        {console.log(data[answer.key])}
                        <Select
                            defaultValue={data[answer.key] ? data[answer.key] : []}
                            onChange={(e) => { onFormDataChange(answer.key, e) }}
                            options={members}
                            isMulti={question.isMulti}
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={question.required}
                            isDisabled={disabled}
                        />
                    </AnswerBlock>
                </div>
            );
        }
        case 'date': {
            return (
                <div>
                    <Label>
                        <SrNoCircle>{questionNo}</SrNoCircle>
                        <Label className='d-block' dangerouslySetInnerHTML={{ __html: question.label }} />
                    </Label>
                    <AnswerBlock className='col-lg-4 col-md-6 col-sm-12'>
                        <Input
                            max={(question.selectUptoToday) && moment().format("YYYY-MM-DD")}
                            value={data[answer.key] ? moment(data[answer.key]).format("YYYY-MM-DD") : data[answer.key]}
                            onChange={(e) => { onFormDataChange(answer.key, e.target.value) }}
                            type='date'
                            required={question.required}
                            disable={disabled}
                        />
                    </AnswerBlock>
                </div>
            );
        }
        default:
            return <Element label={question.label} questionNo={questionNo} />;
    }
}
);

export default FormGenerator;