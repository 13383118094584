import { Alert, Button, Modal } from 'react-bootstrap';
import { Checkbox } from '../Checkbox';
import { useState } from 'react';
import axios from 'axios';

const ConformationModal = ({ show, onClose, videoData, user }) => {
    const [userAction, setUserAction] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const markAsCompleteVideo = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            const { eTag } = videoData;
            const params = {
                completedBy: user._id,
                eTag
            };
            await axios.put('/api/v2/completeTrainingVideo', params);
            onClose();
            setLoading(false);
        } catch (error) {
            setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.');
            setLoading(false);
        }
    };

    return (
        <Modal
            size="md"
            show={show}
            animation={true}
            keyboard={false}
            backdrop="static"
            onHide={() => onClose(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
        >
            <Modal.Header className='px-4 pt-3 pb-2'>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <h2>Submit your training completion</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-3 px-4'>
                <p style={{ letterSpacing: '0.5px' }}>
                    I, {user.fullName}, hereby acknowledge and certify that I have successfully completed my annual one-hour OSHA training through Ocoord.
                </p>
                <p style={{ letterSpacing: '0.5px' }}>
                    I understand the importance of adhering to OSHA standards and regulations in maintaining a safe and compliant workplace. By acknowledging this statement, I affirm that I have actively participated in and comprehended the content of the training provided.
                </p>
                <p style={{ letterSpacing: '0.5px' }}>
                    If I have any questions I will contact my OSHA coordinator Esther Howard.
                </p>
                <div className='d-flex align-item-center my-4'>
                    <Checkbox
                        onChange={() => setUserAction(!userAction)}
                        name='ppeAgreement'
                        checked={userAction}
                        required
                    />
                    <h5 style={{ fontWeight: 600 }}>I read provided information and submit training completion</h5>
                </div>
                {error &&
                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="info">
                        <p className='w-100 mb-0'>{error}</p>
                    </Alert>
                }
                <div className='text-center'>
                    <Button disabled={loading || !userAction} onClick={markAsCompleteVideo} className='w-100' type='button' variant='success'>{loading ? 'Loading...' : 'Submit'}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConformationModal;