import actions from './actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiUrl } from '../helpers';
import * as Constants from '../helpers';

/**
 * @namespace recordsActions
 * @category Actions
 */

/**
 * @method
 * @memberOf recordActions
 * @param {*} list, filters
 */
const getRecordsSuccess = (list, filters) => ({
  type: actions.GET_RECORDS_SUCCESS,
  list,
  filters
});

/**
 * @method
 * @memberOf recordActions
 * @param {*}
 */
const getRecordsFailure = () => ({
  type: actions.GET_RECORDS_FAILURE
});

/**
 * fetch records from the server, sort them, and insert section headers,
 * one for Pending records, and one for each month
 *
 * @method
 * @async
 * @memberOf recordActions
 * @param {*}
 */
export const getRecords = (recordType) => async (dispatch, getState) => {
  try {
    const { user: { currentUser } } = getState();
    if (recordType === 'Archive') {
      const { data } = await axios.get(apiUrl(`/api/v1/archive-records/${currentUser.location._id}`));
      // create filters
      const filters = data.reduce((acc, val) => {
        if (!acc.includes(val.name)) {
          return [...acc, val.name];
        }
        return acc;
      }, []);
      data && data.length && data.unshift({ isArchive: true });
      return await dispatch(getRecordsSuccess(data, ['Health Records', ...filters]));
    } else {
      const { data } = await axios.get(apiUrl(`/api/v1/records?locationId=${currentUser.location._id}`));
      // pending records
      const pendingRecordsList = data.filter((record) => !record.isApproved);
      // split the approved records into months
      let pairs = [];
      const approvedRecordsList = data.sort((aVal, bVal) => new Date(aVal.completedAt).getTime() < new Date(bVal.completedAt).getTime()).filter((record) => record.isApproved).reduce((acc, val) => {
        const year = new Date(val.completedAt).getFullYear();
        const month = new Date(val.completedAt).toLocaleString('en-us', { month: 'long' });
        const match = `${year}-${month}`;
        if (!pairs.includes(match)) {
          pairs = [...pairs, match];
          return [...acc, { isTitleBlock: true, month, year }, val];
        }
        return [...acc, val];
      }, []);
      // concat the approved and pending records, plus the sections, into one list
      const recordsList = [
        { isTitleBlock: true, isPending: true },
        ...pendingRecordsList,
        ...approvedRecordsList
      ];
      // create filters
      const filters = data.reduce((acc, val) => {
        if (!acc.includes(val.name)) {
          return [...acc, val.name];
        }
        return acc;
      }, []);
      return await dispatch(getRecordsSuccess(recordsList, ['Health Records', ...filters]));
    }
  } catch (err) {
    dispatch(getRecordsFailure());
  }
};

const updateRecordSuccess = (record) => ({
  type: actions.UPDATE_RECORD_SUCCESS,
  record
});

const updateRecordFailure = () => ({
  type: actions.UPDATE_RECORD_FAILURE
});

export const updateRecord = (record, showMsg) => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    if (showMsg) {
      record.isApproved = !!currentUser.isAdmin;
    };
    const { data } = await axios.put(apiUrl(`/api/v1/records/${record._id}`), {
      ...record
    });
    dispatch(getRecords());
    // do this because the entire records list needs to be re-sorted
    dispatch(updateRecordSuccess(data));
    if (showMsg) {
      toast.success('Record created successfully')
    }
    return data;
  } catch (err) {
    console.error('======================================');
    console.error('rip');
    console.error('======================================');
    return dispatch(updateRecordFailure());
  }
};

export const deleteRecord = (recordId) => async () => {
  try {
    const { data } = await axios.delete(apiUrl('/api/v1/records/' + recordId));
    return data;
  } catch (error) {
    return false;
  }
};

export const archiveRecord = (recordId) => async (dispatch) => {
  try {
    const { data } = await axios.delete(apiUrl('/api/v1/archive-record/' + recordId));
    return data;
  } catch (error) {
    return false;
  }
};