/**
 * @namespace actionTypes
 * @category Actions
 */

const actionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  REQUEST_PASSWORD: 'REQUEST_PASSWORD',
  REQUEST_PASSWORD_SUCCESS: 'REQUEST_PASSWORD_SUCCESS',
  REQUEST_PASSWORD_FAILURE: 'REQUEST_PASSWORD_FAILURE',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
  GET_EQUIPMENT_SUCCESS: 'GET_EQUIPMENT_SUCCESS',
  GET_EQUIPMENT_FAILURE: 'GET_EQUIPMENT_FAILURE',
  CREATE_EQUIPMENT_SUCCESS: 'CREATE_EQUIPMENT_SUCCESS',
  CREATE_EQUIPMENT_FAILURE: 'CREATE_EQUIPMENT_FAILURE',
  GET_TASKS_SUCCESS: 'GET_TASKS_SUCCESS',
  GET_TASKS_FAILURE: 'GET_TASKS_FAILURE',
  GET_VIDEOS: 'GET_VIDEOS',
  GET_VIDEOS_SUCCESS: 'GET_VIDEOS_SUCCESS',
  GET_VIDEOS_FAILURE: 'GET_VIDEOS_FAILURE',
  GET_RECORDS: 'GET_RECORDS',
  GET_RECORDS_SUCCESS: 'GET_RECORDS_SUCCESS',
  GET_RECORDS_FAILURE: 'GET_RECORDS_FAILURE',
  COMPLETE_TASK_FAILURE: 'COMPLETE_TASK_FAILURE',
  COMPLETE_TASK_SUCCESS: 'COMPLETE_TASK_SUCCESS',
  LOAD_MEMBERS_SUCCESS: 'LOAD_MEMBERS_SUCCESS',
  LOAD_MEMBERS_FAILURE: 'LOAD_MEMBERS_FAILURE',
  LOAD_ROLES_SUCCESS: 'LOAD_ROLES_SUCCESS',
  LOAD_ROLES_FAILURE: 'LOAD_ROLES_FAILURE',
  LOAD_ADMIN_ROLES_SUCCESS: 'LOAD_ADMIN_ROLES_SUCCESS',
  LOAD_ADMIN_ROLES_FAILURE: 'LOAD_ADMIN_ROLES_FAILURE',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',
  LOAD_EQUIPMENT_SUCCESS: 'LOAD_EQUIPMENT_SUCCESS',
  LOAD_EQUIPMENT_FAILURE: 'LOAD_EQUIPMENT_FAILURE',
  LOAD_LOCATIONS_SUCCESS: 'LOAD_LOCATIONS_SUCCESS',
  LOAD_LOCATIONS_FAILURE: 'LOAD_LOCATIONS_FAILURE',
  SEND_NEW_CODE: 'SEND_NEW_CODE',
  GET_PRACTICE_STRIPE_DATA_SUCCESS: 'GET_PRACTICE_STRIPE_DATA_SUCCESS',
  GET_PRACTICE_STRIPE_DATA_FAILURE: 'GET_PRACTICE_STRIPE_DATA_FAILURE',
  GET_PRACTICE_STRIPE_INVOICES_SUCCESS: '  GET_PRACTICE_STRIPE_INVOICES_SUCCESS',
  GET_PRACTICE_STRIPE_INVOICES_FAILURE: '  GET_PRACTICE_STRIPE_INVOICES_FAILURE',
  REMOVE_FROM_PRACTICE: 'REMOVE_FROM_PRACTICE',
  REMOVE_FROM_PRACTICE_SUCCESS: 'REMOVE_FROM_PRACTICE_SUCCESS',
  SELECT_TAB: 'SELECT_TAB',
  SELECT_MEMBER: 'SELECT_MEMBER',
  SELECT_ROLE: 'SELECT_ROLE',
  SELECT_EQUIPMENT: 'SELECT_EQUIPMENT',
  SELECT_LOCATION: 'SELECT_LOCATION',
  LOAD_LIBRARY_FAILURE: 'LOAD_LIBRARY_FAILURE',
  LOAD_LIBRARY_SUCCESS: 'LOAD_LIBRARY_SUCCESS',
  DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
  UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
  UPDATE_LOCATION_FAILURE: 'UPDATE_LOCATION_FAILURE',
  UPDATE_PRACTICE_SUCCESS: 'UPDATE_PRACTICE_SUCCESS',
  UPDATE_PRACTICE_FAILURE: 'UPDATE_PRACTICE_FAILURE',
  SEND_NEW_CODE_SUCCESS: 'SEND_NEW_CODE_SUCCESS',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  LOAD_SAFETY_OFFICERS_FOR_LOCATION_SUCCESS: 'LOAD_SAFETY_OFFICERS_FOR_LOCATION_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  ONBOARD_USER_SUCCESS: 'ONBOARD_USER_SUCCESS',
  ONBOARD_USER_FAILURE: 'ONBOARD_USER_FAILURE',
  SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  REGISTER_NEW_ACCOUNT_SUCCESS: 'REGISTER_NEW_ACCOUNT_SUCCESS',
  REGISTER_NEW_ACCOUNT_FAILURE: 'REGISTER_NEW_ACCOUNT_FAILURE',
  RESUME_REGISTRATION: 'RESUME_REGISTRATION',
  REGISTER_NEW_PRACTICE_SUCCESS: 'REGISTER_NEW_PRACTICE_SUCCESS',
  REGISTER_NEW_PRACTICE_FAILURE: 'REGISTER_NEW_PRACTICE_FAILURE',
  REGISTER_NEW_LOCATION_SUCCESS: 'REGISTER_NEW_LOCATION_SUCCESS',
  REGISTER_NEW_LOCATION_FAILURE: 'REGISTER_NEW_LOCATION_FAILURE',
  SUBMIT_REPORT_SUCCESS: 'SUBMIT_REPORT_SUCCESS',
  SUBMIT_REPORT_FAILURE: 'SUBMIT_REPORT_FAILURE',
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  LOAD_ADMINS_FOR_LOCATION_SUCCESS: 'LOAD_ADMINS_FOR_LOCATION_SUCCESS',
  LOAD_USER_FAILURE: 'LOAD_USER_FAILURE',
  GET_DEFAULTS: 'GET_DEFAULTS',
  GET_DEFAULTS_SUCCESS: 'GET_DEFAULTS_SUCCESS',
  GET_DEFAULTS_FAILURE: 'GET_DEFAULTS_FAILURE',
  UPDATE_USER_LOCATION: 'UPDATE_USER_LOCATION',
  UPDATE_RECORD_SUCCESS: 'UPDATE_RECORD_SUCCESS',
  UPDATE_RECORD_FAILURE: 'UPDATE_RECORD_FAILURE',
  LOAD_ALL_TASKS_SUCCESS: 'LOAD_ALL_TASKS_SUCCESS',
  SELECT_TASK: 'SELECT_TASK',
  SELECT_FORM_SUCCESS: 'SELECT_FORM_SUCCESS',
  SELECT_ACCOUNT_TAB: "SELECT_ACCOUNT_TAB",
  OPEN_USER_PANEL_SUCCESS: 'OPEN_USER_PANEL_SUCCESS',
  LOAD_NOTIFICATION_LIST_FAILURE: 'LOAD_NOTIFICATION_FAILURE',
  LOAD_NOTIFICATION_LIST_SUCCESS: 'LOAD_NOTIFICATION_SUCCESS',
  LOAD_NOTIFICATION_COUNT_SUCCESS: 'LOAD_NOTIFICATION_COUNT_SUCCESS',
  LOAD_NOTIFICATION_COUNT_FAILURE: 'LOAD_NOTIFICATION_COUNT_FAILURE',
  OPEN_NOTIFICATION_PANEL: 'OPEN_NOTIFICATION_PANEL',
  LOAD_NOTIFICATION_WARN: 'NOTIFICATION_WARN',
  ADMIN_REQUEST: 'ADMIN_REQUEST'
};

export default actionTypes;
