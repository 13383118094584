import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Spinner, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { AddEditLocation, Button } from '../../components';
import mapIcon from '../../assets/images/map.svg';
import { WhiteButton } from './Style';
import { loadUser } from '../../actions';

const LocationContainer = styled.div`
    overflow-y: auto;
    max-height:60vh;
`;

const TypeButton = styled(Button)`
    width: 20% !important;
    height: 40px !important;
    border-radius: 20px;
    background: #F3F9F5;
    color: #42A764;
    margin-bottom: 1rem !important;
`;

const LocationNameContainer = styled.h2`
    margin: 1.5rem 0;
`;


class LocationView extends Component {
    state = {
        loading: false,
        locationDetails: { name: '', address: {}, type: '' },
        members: [],
        errors: {},
        editLocationModalOpen: false
    };

    componentDidMount() {
        this.getLocation();
    };

    getLocation = async () => {
        try {
            this.setState({ loading: true });
            const { history: { location } } = this.props;
            const searchParams = new URLSearchParams(location.search);
            const locationId = searchParams.get('locationId');
            const { data: { payload } } = await axios.get(`/api/v2/location/${locationId}`);
            this.setState({ locationDetails: payload, loading: false });
        } catch (error) {
            this.setState({ loading: false, errors: { apiError: true, } });
            return {};
        }
    };

    closeModal = (getLocations) => {
        this.setState({ editLocationModalOpen: false });
        if (getLocations) {
            this.getLocation();
        };
    };

    afterLocationUpdateOrAdd = async () => {
        const { dispatch, user } = this.props;
        await dispatch(loadUser(user));
    };

    render() {
        const { history, user, practice } = this.props;
        const { loading, errors, locationDetails, editLocationModalOpen } = this.state;
        const types = { medical: 'Medical', dental: 'Dental', Veterinary: 'Veterinary', medicalLaboratory: 'Medical Laboratory' };
        return (
            loading ?
                <div style={{ minHeight: '30px' }} className='w-100 text-center'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
                <LocationContainer>
                    <Row style={{ height: '100%' }} className="justify-content-center mx-2 mt-4">
                        <Col xs={12} md={6} className="mb-4">
                            <TypeButton>{types[locationDetails.type[0]]}</TypeButton>
                            <div>
                                <LocationNameContainer>{locationDetails.name}</LocationNameContainer>
                                <div className="locationDetails">
                                    <span><img src={mapIcon} alt="Map Icon" /></span>
                                    <p className='mb-1'>{locationDetails.address.address}</p>
                                    <p className='mb-1'>{locationDetails.address.address2}</p>
                                    <p className='mb-1'>{locationDetails.address.city + ', ' + locationDetails.address.state + ' ' + locationDetails.address.zip}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="mb-4">
                            <iframe
                                height='300'
                                width='100%'
                                style={{ "border": 0 }}
                                loading="lazy"
                                src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDYP0TVsLjm4FBMoW_Vlup0llo9pRJGJcQ&q=" + locationDetails.name + ", " + locationDetails.address.address + "+" + locationDetails.address.zip + "+" + locationDetails.address.state} />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end mr-5">
                        <div className='mr-4'>
                            <WhiteButton onClick={() => { history.goBack() }} type='button' >{'< '}Back</WhiteButton>
                        </div>
                        <div>
                            <Button type='button' onClick={() => this.setState({ editLocationModalOpen: true })}>Edit</Button>
                        </div>
                    </div>
                    {editLocationModalOpen &&
                        <AddEditLocation practice={practice} location={locationDetails._id} show={editLocationModalOpen} closePopUpModal={this.closeModal} user={user} afterLocationUpdateOrAdd={this.afterLocationUpdateOrAdd} />}
                </LocationContainer>

        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    practice: state.user.currentUser.practice || {},
});

export default connect(mapStateToProps)(LocationView);