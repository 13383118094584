import checkIcon from '../../assets/images/glyph-checkmark-green.svg'

import { Dot, Line, ProgressContainer, StepContainer, StepIcon, StepSubTitle, StepTitle, StepTitleContainer, Stepper } from './Style';

export const RegistrationFlowStepper = ({ currentStep = 3 }) => {

    const getStatus = (stepNumber) => {
        if (stepNumber < currentStep) return 'completed';
        if (stepNumber === currentStep) return 'current';
        return 'pending'
    };
    return (
        <Stepper>
            <StepContainer>
                <ProgressContainer className='justify-content-end'>
                    <StepIcon status={getStatus(1)}>
                        {getStatus(1) === 'completed' ?
                            <img src={checkIcon} alt="" />
                            :
                            < Dot />
                        }
                    </StepIcon>
                    <Line status={getStatus(1)} />
                </ProgressContainer>
                <StepTitleContainer>
                    <StepTitle status={getStatus(1)}>Create your account</StepTitle>
                    <StepSubTitle status={getStatus(1)}>Provide your name, email and password</StepSubTitle>
                </StepTitleContainer>
            </StepContainer>

            <StepContainer>
                <ProgressContainer className='justify-content-center'>
                    <Line status={getStatus(1)} />
                    <StepIcon status={getStatus(2)}>
                        {getStatus(2) === 'completed' ?
                            <img src={checkIcon} alt="" />
                            :
                            < Dot />
                        }
                    </StepIcon>
                    <Line status={getStatus(2)} />
                </ProgressContainer>
                <StepTitleContainer>
                    <StepTitle status={getStatus(2)}>Verification</StepTitle>
                    <StepSubTitle status={getStatus(2)}>Verify your email to activate account</StepSubTitle>
                </StepTitleContainer>
            </StepContainer>

            <StepContainer>
                <ProgressContainer className='justify-content-center'>
                    <Line status={getStatus(2)} />
                    <StepIcon status={getStatus(3)}>
                        {getStatus(3) === 'completed' ?
                            <img src={checkIcon} alt="" />
                            :
                            < Dot />
                        }
                    </StepIcon>
                    <Line status={getStatus(3)} />
                </ProgressContainer>
                <StepTitleContainer>
                    <StepTitle status={getStatus(3)}>Company Details</StepTitle>
                    <StepSubTitle status={getStatus(3)}>Get information about your company</StepSubTitle>
                </StepTitleContainer>
            </StepContainer>

            <StepContainer>
                <ProgressContainer className='justify-content-start'>
                    <Line status={getStatus(3)} />
                    <StepIcon status={getStatus(4)}>
                        {getStatus(4) === 'completed' ?
                            <img src={checkIcon} alt="" />
                            :
                            < Dot />
                        }
                    </StepIcon>
                </ProgressContainer>
                <StepTitleContainer>
                    <StepTitle status={getStatus(4)}>Subscription & Payment</StepTitle>
                    <StepSubTitle status={getStatus(4)}>Choose subscription and payment method</StepSubTitle>
                </StepTitleContainer>
            </StepContainer>
        </Stepper>
    )
}
