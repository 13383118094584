import axios from 'axios';
import { toast } from 'react-toastify';
import { apiUrl } from '../helpers';
import { loadUser } from './userActions';
/**
 * @namespace trainingActions
 * @category Actions
 */


export const updateNewHireUser = (id) => async (dispatch) => {
  try {
    let { data } = await axios.get(apiUrl(`/api/v1/updateNewHireUser?userId=${id}`));
    if (id) {
      dispatch(loadUser({ _id: id }))
    };
    return data.data;
  } catch (err) {
    toast.error(`Error in update New Hire User`);
    console.error(err);
    return false;
  }
};
