import React from 'react';
import { default as ReactSelect } from 'react-select';
import styled from 'styled-components';
import { DropDownStyle } from '../helpers';

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;
const StyledSubtext = styled.p`
  font-size: 11px;
  color: var(--light-text-color);
  margin-left: 8px;
  margin-top: 5px;
`;
export const Select = ({ handleDropdownChange, defaultValue, options, label, subtext, placeholder, value, width, required, isDisabled, isMulti, isClearable }) => {

  return (
    <React.Fragment>
      {label &&
        <StyledLabel>{label} {required && <StyledLabel style={{ color: "red", marginLeft: "3px" }}>*</StyledLabel>}</StyledLabel>
      }
      <ReactSelect
        placeholder={placeholder}
        isMulti={isMulti}
        isClearable={isClearable}
        onChange={(e) => handleDropdownChange({ target: e })}
        options={options}
        defaultValue={defaultValue}
        value={value}
        isDisabled={isDisabled}
        menuPosition="fixed"
        styles={DropDownStyle}
        required={required}
      />
      {subtext &&
        <StyledSubtext>{subtext}</StyledSubtext>}
    </React.Fragment>
  );
};
