import React, { Component } from 'react';
import axios from 'axios';
import { Alert, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { Button } from '../Button';
import { Input } from '../Input';
import { Select } from '../Select';
import MultiTextInput from '../MultiTextInput';
import Swal from 'sweetalert2';

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;
const StyledTextArea = styled.textarea`
  width: 70%;
  display:block;
  @media (max-width:768px) {
    width:100%;
  }
`;
const WhiteButton = styled(Button)`
  border-radius: 4px;
  text-decoration: none;
  background: #fbfbfb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: 0.5px solid #eaeaea;
  color: #7f808f;
  transition: box-shadow 0.2s;
`;

class AddEditRole extends Component {
    state = {
        loading: false,
        roleDetails: { name: "", description: "", jobTasks: [], defaultEquipment: [], location: this.props.user.location._id },
        saveOrUpdateInProgress: false,
        errors: { errorMessage: '' },
        roleLoading: false,
        safetyEquipments: []
    };

    componentDidMount() {
        this.getRole();
    };

    getRole = async () => {
        try {
            this.setState({ addOrEditRole: true, roleLoading: true });
            await this.getMasterData();
            const { role } = this.props;
            if (role) {
                const { data: { payload } } = await axios.get(`/api/v2/role/${role._id}`);
                this.setState({
                    roleDetails: payload,
                    roleLoading: false
                });
            } else {
                this.setState({ roleLoading: false });
            }
        } catch (error) {
            console.log(error);
        }
    };


    getMasterData = async () => {
        try {
            const { user } = this.props;
            const { data: { payload: safetyEquipments } } = await axios.get(`/api/v2/master/safetyequipments/${user.location._id}`);
            this.setState({ safetyEquipments });
        } catch (error) {
            console.error(error);
        }
    };

    onModelChange = (name, value) => {
        const { roleDetails } = this.state;
        if (name === 'jobTasks') {
            roleDetails.jobTasks = value.map((task) => task.value);
        } else if (['description', 'name'].includes(name)) {
            roleDetails[name] = value;
        } else if (name === 'defaultEquipment') {
            roleDetails[name] = (value && value.length) ? value.map(({ value }) => { return value }) : [];
        };
        this.setState({ roleDetails });
    };

    validate = () => {
        let error = false;
        let tempErrors = {};
        const { roleDetails: { name, description } } = this.state;
        if (!name || !name.trim()) {
            tempErrors.name = true;
            error = true;
        };
        if (!description || !description.trim()) {
            tempErrors.description = true;
            error = true;
        };
        this.setState({ errors: tempErrors });
        return error;
    };

    saveOrUpdate = async () => {
        const { roleDetails: { _id }, roleDetails } = this.state;
        const error = this.validate();
        if (!error) {
            this.setState({ saveOrUpdateInProgress: true });
            try {
                if (_id) {
                    await axios.put(`/api/v2/role/${_id}`, roleDetails);
                } else {
                    await axios.post(`/api/v2/role`, roleDetails);
                };
                this.props.closePopUpModal();
                if (_id) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Role has been updated successfully',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Role has been added successfully',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                };

            } catch (error) {
                if (error.response && error.response.status === 409) {
                    this.setState({
                        saveOrUpdateInProgress: false,
                        errors: { apiError: true, errorMessage: 'Role already present.' }
                    });
                    return;
                };
                this.setState({
                    saveOrUpdateInProgress: false,
                    errors: { apiError: true, errorMessage: !_id ? 'Error at adding role.' : 'Error at updating role.' }
                });
                return;
            };
        };
    };

    render() {
        const { roleDetails: { _id, name, description, jobTasks, defaultEquipment }, safetyEquipments, roleLoading, saveOrUpdateInProgress, errors, errors: { apiError, errorMessage } } = this.state;
        const { addOrEditRole, closePopUpModal } = this.props;
        return (
            <Modal
                centered
                animation={true}
                backdrop="static"
                keyboard={false}
                size='lg' show={addOrEditRole}>

                {roleLoading ?
                    <Container className='text-center my-3'>
                        <Spinner animation="border" variant="success" />
                    </Container> :
                    <>
                        <Modal.Header >
                            <Modal.Title>{_id ? 'Edit' : 'Add'} Role</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                                <Row className='mb-3' >
                                    <Col>
                                        <Input required={true} onChange={(e) => this.onModelChange('name', e.target.value)} label='Name' type='text' placeholder='Name' value={name} />
                                    </Col>
                                    <Col>
                                        <StyledLabel htmlFor="textarea">Description<span style={{ color: "red", marginLeft: "3px" }}>*</span></StyledLabel>
                                        <StyledTextArea
                                            name='description'
                                            placeholder='Description...'
                                            onChange={(e) => this.onModelChange('description', e.target.value)} type='text' value={description} required={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <StyledLabel htmlFor="textarea" className="mb-2">Job Tasks</StyledLabel>
                                        <p className="mb-2">List the tasks that staff members assigned to this role, perform as a part of their job that put them at risk for exposure.</p>
                                        <MultiTextInput
                                            data={jobTasks && jobTasks.map((task) => {
                                                return { label: task, value: task };
                                            })}
                                            onChange={(e) => this.onModelChange('jobTasks', e)}
                                            placeholder='Type a Job Task and press enter...'
                                        />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <StyledLabel className="mb-2">DEFAULT SAFETY EQUIPMENT</StyledLabel>
                                        <p className="mb-2">Staff members assigned to this role will have these equipment items by default.</p>
                                        <Select
                                            isMulti={true}
                                            handleDropdownChange={(e) => this.onModelChange('defaultEquipment', e.target)}
                                            defaultValue={safetyEquipments.filter((equipment) => defaultEquipment.includes(equipment.value))}
                                            options={safetyEquipments}
                                        />
                                        <br />
                                    </Col>
                                </Row>
                            </Container>
                            <Container className='mt-2'>
                                {(errors.name || errors.description) &&
                                    <Row >
                                        <Col>
                                            <Alert id='error-alert' className='my-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                                <p className='w-100 mb-0'>
                                                    {'Fill all required fields.'}
                                                </p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                {apiError &&
                                    <Row>
                                        <Col>
                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                                onClose={() => this.setState({ errors: { ...errors, apiError: false } })} dismissible>
                                                <p className='w-100 mb-0'>{errorMessage}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                <Row className='mt-3'>
                                    <Col>
                                        <WhiteButton className='mt-0' type='button' onClick={() => closePopUpModal()}>Cancel</WhiteButton>
                                    </Col>
                                    <Col>
                                        <Button disabled={saveOrUpdateInProgress} className='mt-0' type='button' onClick={() => this.saveOrUpdate()}>{_id ? (saveOrUpdateInProgress ? 'Updating...' : 'Update') : (saveOrUpdateInProgress ? 'Saving...' : 'Save')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </>
                }
            </Modal>
        )
    }
}

export default AddEditRole;