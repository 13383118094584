import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Alert, Col } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import styled from 'styled-components';
import { DropDownStyle } from '../../helpers';
import { QuestionSectionContainer, FooterButtonSection, ImageContainer, Label } from './Style';
import trash from '../../assets/images/trash.svg';
import { Button } from '../../components';

const DocUploadContainer = styled(Col)`
    
`;

const FileList = styled.li`
  position: relative;
  padding-top: 10px;
  width: 100%;
  word-break: break-all;
  ${(props) => (props.inValid ? 'text-decoration: line-through;color: #FF4B00;' : '')};
`;

const FileUploadBtn = styled.label`
    border-radius: 4px;
    padding: 6px 10px;
    min-width: 50px;
    height: 30px;
    font-size: 12px;
    font-weight: 600;
    background: #fbfbfb;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14);
    border: 0.5px solid #eaeaea;
    color: #7f808f;
    text-align: center;
    -webkit-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
    cursor: pointer;
`;

const FileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    visibility: hidden;
    margin-bottom: 5px;
`;

const UploadDocuments = ({ user, history, members, error }) => {
    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errors, setErrors] = useState({ ...error, fileSizeError: false, fileFormatError: false });

    useEffect(() => {
    }, []);

    const onFormDataChange = (event) => {
        setSelectedUser(event);
    };

    const handleFileChange = (event) => {
        const files = validate([...selectedFiles, ...Array.from(event.target.files)]);
        setSelectedFiles([...files]);
    };

    const validate = (files) => {
        let totalFileSize = 0;
        let fileSizeErrorCount = 0;
        let fileFormatErrorCount = 0;
        const allowedExtensions = /(\.doc|\.docx|\.xlsx|\.pdf|\.jpeg|\.jpg|\.png)$/i;

        files = files.map((file) => {
            if (file.size > 8000000) {
                file.inValid = true;
                fileSizeErrorCount++;
            };
            if (!allowedExtensions.exec(file.name)) {
                file.inValidFormat = true;
                fileFormatErrorCount++;
            };
            totalFileSize += file.size;
            return file;
        });
        if (totalFileSize > 19999999) {
            setErrors(prevState => ({
                ...prevState,
                totalFileSizeError: true
            }));
        } else {
            setErrors(prevState => ({
                ...prevState,
                totalFileSizeError: false
            }));
        };

        setErrors(prevState => ({
            ...prevState,
            fileSizeError: fileSizeErrorCount ? true : false,
            fileFormatError: fileFormatErrorCount ? true : false
        }));

        return files;
    };

    const deleteFiles = (index) => {
        const tempFiles = [...selectedFiles];
        tempFiles.splice(index, 1);
        validate(tempFiles);
        setSelectedFiles(tempFiles);
    };

    const saveFiles = async (event) => {
        event.preventDefault();
        try {
            const files = selectedFiles.filter(file => !file.inValid);
            const formData = new FormData();
            for (const file of files) {
                formData.append(file.name, file);
            };
            formData.append('formId', 'uploadHealthDocumentForm');
            if (user.isAdmin) {
                formData.append('name', `Health Records for ${selectedUser.label}`);
                formData.append('filesFor', selectedUser.value);
                formData.append('isApproved', 'true');
            } else {
                formData.append('name', `Health Records for ${user.fullName}`);
                formData.append('filesFor', user._id);
                formData.append('isApproved', 'false');
            };
            await axios.post('/api/v2/healthRecord', formData);
            const { location } = history;
            const searchParams = new URLSearchParams(location.search);
            const back = searchParams.get('back');
            if (back) {
                Swal.fire({
                    icon: 'success',
                    title: 'Record has been created successfully',
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                }).then(() => {
                    return history.goBack();
                });
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Record has been created successfully',
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                }).then(() => {
                    return history.push('/records');
                });
            };
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 413) {
                setErrors(prevState => ({
                    ...prevState,
                    totalFileSizeError: true
                }));
                return;
            };
            setErrors({ apiError: true });
        }
    };

    return (
        <DocUploadContainer lg='8'>
            <form onSubmit={saveFiles} className='col-lg-12'>
                <QuestionSectionContainer lg='12' className='mt-2'>
                    {user.isAdmin &&
                        <>
                            <Label>Staff Member</Label>
                            <Select
                                className='col-lg-4 col-md-6 col-sm-12 p-0'
                                onChange={onFormDataChange}
                                options={members}
                                menuPosition="fixed"
                                styles={DropDownStyle}
                                required
                            />
                            <p>Choose which staff member you're uploading health documents for.</p>
                        </>
                    }
                    <FileUploadBtn className='col-lg-4 col-md-6 col-sm-12'>
                        Choose File(s) to Upload...
                        <FileInput
                            name='recordFiles'
                            onChange={handleFileChange}
                            multiple
                            type='file'
                            accept=".doc, .docx, .xlsx, .pdf, image/jpeg, image/png"
                        />
                    </FileUploadBtn>
                    <p className='mb-0'>Choose .doc/.docx/.xlsx/.pdf/.jpg/png type of file</p>
                    <ol>
                        {selectedFiles.map((file, index) => (
                            <FileList key={index} inValid={file.inValid || file.inValidFormat}>
                                {file.name}
                                <ImageContainer onClick={() => deleteFiles(index)} className='mx-4' src={trash} />
                            </FileList>
                        ))}
                    </ol>
                    {errors.fileSizeError &&
                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                            <p className='w-100 mb-0'>Some files' sizes are more than 8MB. Please select files that are smaller than 8MB.</p>
                        </Alert>
                    }
                    {errors.fileFormatError &&
                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                            <p className='w-100 mb-0'>Please select files with the following types: .doc, .docx, .xlsx, .pdf, .jpg, or .png. Files of other types are not supported.</p>
                        </Alert>
                    }
                    <p>Choose one or more files to upload from your computer. Health Documents are shown in Records after uploading.</p>
                    <p>Health documents are only visible to the administrators and the staff member that the documents are about.</p>

                </QuestionSectionContainer>
                {errors.apiError &&
                    <Alert id='error-alert' className='mb-2 mx-3' style={{ 'maxWidth': '100%' }} variant="danger">
                        <p className='w-100 mb-0'>Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.</p>
                    </Alert>
                }
                {errors.totalFileSizeError &&
                    <Alert id='error-alert' className='mb-2 mx-3' style={{ 'maxWidth': '100%' }} variant="danger">
                        <p className='w-100 mb-0'>Total file sizes should be less than 20 MB.</p>
                    </Alert>
                }
                <FooterButtonSection>
                    <Col lg='3' md='4' sm='12' className='d-flex'>
                        <Button disabled={errors.totalFileSizeError || errors.fileSizeError || error.fileFormatError || !selectedFiles.length} type='submit' className='my-0'>Save</Button>
                    </Col>
                </FooterButtonSection>
            </form>
        </DocUploadContainer>
    );
}

export default UploadDocuments;