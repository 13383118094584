import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";


const PrivacyPolicy = ({ }) => {
    const [termOfServiceData, setTermOfServiceData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTermsOfService();
    }, []);

    const getTermsOfService = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/userLicensingAgreement');
            setTermOfServiceData(payload);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getTermsOfService ~ error:", error);
            setLoading(false);
        }
    };

    return (
        <>
            <Row className='my-4'>
                {loading ? 'Loading...' :
                    <Col lg={12} mg={12} sm={12}>
                        <h2>{termOfServiceData.fields?.title}</h2>
                        {documentToReactComponents(termOfServiceData.fields.body)}
                    </Col>
                }
            </Row>
        </>
    )
}

export default PrivacyPolicy;