import React, { Component } from 'react';
import axios from 'axios';
import { Alert, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { Button } from '../Button';
import { Input } from '../Input';
import { DropDownStyle } from '../../helpers';

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;

const WhiteButton = styled(Button)`
  border-radius: 4px;
  text-decoration: none;
  background: #fbfbfb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: 0.5px solid #eaeaea;
  color: #7f808f;
  transition: box-shadow 0.2s;
`;

class AddEditSafetyEquipment extends Component {
    state = {
        loading: false,
        safetyEquipmentDetails: { name: '', location: this.props.user.location._id, default: false, roles: [] },
        saveOrUpdateInProgress: false,
        errors: { errorMessage: '' },
        safetyEquipmentLoading: false,
        roles: []
    };

    componentDidMount() {
        this.getSafetyEquipment();
    };

    getSafetyEquipment = async () => {
        try {
            const { safetyEquipment } = this.props;
            this.setState({ safetyEquipmentLoading: true });
            if (safetyEquipment) {
                const { data: { payload: safetyEquipmentDetails } } = await axios.get(`/api/v2/safetyEquipment/${safetyEquipment._id}`);
                this.setState({ safetyEquipmentDetails });
            };
            await this.getMasterData();
        } catch (error) {
            this.setState({ safetyEquipmentLoading: false });
            console.error(error);
        }
    };

    getMasterData = async () => {
        try {
            const { user } = this.props;
            const { data: { payload: roles } } = await axios.get(`/api/v2/master/roles_by_location/${[user.location._id]}`);
            this.setState({ safetyEquipmentLoading: false, roles });
        } catch (error) {
            this.setState({ safetyEquipmentLoading: false });
            console.error(error);
        }
    };

    onModelChange = (name, value) => {
        const { safetyEquipmentDetails } = this.state;
        if (name === 'role') {
            safetyEquipmentDetails.roles = value.map((va) => va.value);
        } else {
            safetyEquipmentDetails[name] = value;
        };
        this.setState({ safetyEquipmentDetails });
    };

    validate = () => {
        let error = false;
        let tempErrors = {};
        const { safetyEquipmentDetails: { name } } = this.state;
        if (!name || !name.trim()) {
            tempErrors.name = true;
            error = true;
        };
        this.setState({ errors: tempErrors });
        return error;
    };

    saveOrUpdate = async () => {
        const { safetyEquipmentDetails: { _id }, safetyEquipmentDetails } = this.state;
        const error = this.validate();
        if (!error) {
            this.setState({ saveOrUpdateInProgress: true });
            try {
                if (_id) {
                    await axios.put(`/api/v2/safetyEquipment/${_id}`, safetyEquipmentDetails);
                } else {
                    await axios.post(`/api/v2/safetyEquipment`, safetyEquipmentDetails);
                };
                this.props.closePopUpModal();
                if (_id) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Safety Equipment has been updated successfully',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Safety Equipment has been added successfully',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    });
                }
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    this.setState({
                        saveOrUpdateInProgress: false,
                        errors: { apiError: true, errorMessage: 'Safety Equipment already present.' }
                    });
                    return;
                };
                this.setState({
                    saveOrUpdateInProgress: false,
                    errors: { apiError: true, errorMessage: !_id ? 'Error at adding safetyEquipment.' : 'Error at updating safetyEquipment.' }
                });
                return;
            };
        };
    };

    render() {
        const { safetyEquipmentDetails: { _id, name }, safetyEquipmentDetails, roles, safetyEquipmentLoading, saveOrUpdateInProgress, errors, errors: { apiError, errorMessage } } = this.state;
        const { addOrEditSafetyEquipment, closePopUpModal } = this.props;
        return (
            <Modal
                centered
                animation={true}
                backdrop="static"
                keyboard={false}
                size='lg' show={addOrEditSafetyEquipment}>
                {safetyEquipmentLoading ?
                    <Container className='text-center my-3'>
                        <Spinner animation="border" variant="success" />
                    </Container>
                    :
                    <>
                        <Modal.Header >
                            <Modal.Title>{_id ? 'Edit' : 'Add'} Safety Equipment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container style={{ maxHeight: "68vh", overflowY: "auto" }}>
                                <Row className='mb-2'>
                                    <Col>
                                        <Input required={true} onChange={(e) => this.onModelChange('name', e.target.value)} label='Name' type='text' placeholder='Name' value={name} />
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col>
                                        <StyledLabel>Roles</StyledLabel>
                                        <Select
                                            name='role'
                                            options={roles}
                                            onChange={(e) => this.onModelChange('role', e)}
                                            defaultValue={_id ? roles.filter((ro) => safetyEquipmentDetails.roles.includes(ro.value)) : []}
                                            isMulti={true}
                                            menuPosition="fixed"
                                            styles={DropDownStyle}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                {errors.name &&
                                    <Row>
                                        <Col>
                                            <Alert id='error-alert' className='my-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                                <p className='w-100 mb-0'>
                                                    The name cannot be empty.
                                                </p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                {apiError &&
                                    <Row>
                                        <Col>
                                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                                onClose={() => this.setState({ errors: { ...errors, apiError: false } })} dismissible>
                                                <p className='w-100 mb-0'>{errorMessage}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                <Row className='mt-3'>
                                    <Col>
                                        <WhiteButton className='mt-0' type='button' onClick={() => closePopUpModal()}>Cancel</WhiteButton>
                                    </Col>
                                    <Col>
                                        <Button disabled={saveOrUpdateInProgress} className='mt-0' type='button' onClick={() => this.saveOrUpdate()}>{_id ? (saveOrUpdateInProgress ? 'Updating...' : 'Update') : (saveOrUpdateInProgress ? 'Saving...' : 'Save')}</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </>
                }
            </Modal>
        )
    }
}

export default AddEditSafetyEquipment;