import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Player, ControlBar, ReplayControl, VolumeMenuButton, CurrentTimeDisplay, RemainingTimeDisplay, TimeDivider, DurationDisplay, FullscreenToggle, Shortcut, PlayToggle } from "video-react";
import 'video-react/dist/video-react.css';
import { Button, Modal, Spinner } from "react-bootstrap";
import { CustomCard, GroupTrainingContainer, HeaderContainer, HeaderText, SubHeader, TableContainer, TableHead } from "./Style";
import defaultPoster from '../../assets/images/training_video_bg_big.png';
import axios from "axios";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { apiUrl, formatTimeInHmm, formatTimeInMinAndSec } from "../../helpers";


const TrainingBody = styled.div`
    .fade-in {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }
      
    .fade-out {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }
`;

const PlayerContainer = styled.div`
    display: flex;
    justify-content: center;
    // max-height: 400px;
    .video-react .video-react-poster {
        background-color: ${(props) => props.posterPresent ? '#ffffff !important' : '#E7F3EB !important'};
        border: 1px solid grey !important;
    }
`;


const Badge = styled.div`
    justify-content: center!important;
    display: flex;
    .badge {
        align-items: center;
        border-radius: 20px;
        display: flex;
        gap: 10px;
        padding: 6px 16px;
        position: relative;
        width: 111px;
        justify-content: center;
    }

    .badge .text {
        font-family: "Open Sans-Regular", Helvetica;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        width: fit-content;
    }

    .badge.yellow {
        background-color: #f9f8f3;
    }

    .badge.yellow .dot {
        background-color: #bc9b25;
    }

    .badge.yellow .text {
        color: #bc9b25;
    } 
`;

const GroupTrainingView = () => {
    const videoRef = useRef(null);
    const loginUser = useSelector(state => state.user.currentUser);

    const [isOpenPauseModal, setIsOpenPauseModal] = useState(false);
    const [isOpenCompleteModal, setIsOpenCompleteModal] = useState(false);

    const [videoData, setVideoData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [trainingData, setTrainingData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('')
    const history = useHistory();
    let lastDataSaveTime = 1;
    let videoPaused = false;

    useEffect(() => {
        setLoading(true);
        getMasterData();
    }, []);


    const getMasterData = async () => {
        try {
            setLoading(true);
            const searchParams = new URLSearchParams(history.location.search);
            const id = searchParams.get('id');
            const { data: { payload: roles } } = await axios.get(`/api/v2/master/roles_by_location/${loginUser.adminLocationIds}`);
            setRoles(roles);
            const { data: { payload } } = await axios.get(`/api/v2/group_training_details/${id}`);
            const { data: { payload: trainingVideo } } = await axios.get('/api/v2/training/' + loginUser._id);
            const videos = [];
            payload.videos.forEach(vi => {
                const video = trainingVideo.all.find(video => video.eTag === vi.eTag);
                if (video) videos.push({ ...video, currentTime: vi.currentTime });
            });
            setTrainingData(payload);
            await playVideo(videos[payload.currentVideo], payload);
            setVideoData(videos);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const calculateTotal = (training_data) => {
        let totalCount = 0;
        for (let index = 0; index < training_data.currentVideo; index++) {
            totalCount += training_data.videos[index].currentTime;
        };
        return totalCount;
    };

    const handlePlayerRef = (state, prevState, viData, training_data) => {
        if (lastDataSaveTime < state.currentTime - 10 || state.ended) {
            const progressPercentage = Number(((state.currentTime / state.duration) * 100).toFixed(2));

            const dataToUpdate = {
                _id: training_data._id,
                trainingType: viData.trainingType,
                eTag: viData.eTag,
                currentTime: Number((state.currentTime).toFixed(2)),
                duration: state.duration,
                progressPercentage: progressPercentage >= 99 ? 100 : progressPercentage,
                totalCount: calculateTotal(training_data) + state.currentTime,
            };

            axios.put('/api/v2/group_training_progress', { users: training_data.users, progress: dataToUpdate });
            if (state.ended && !videoPaused) {
                videoPaused = true;
                if (training_data.totalVideos === training_data.currentVideo + 1) {
                    videoRef.current.pause();
                    Swal.fire({
                        icon: 'success',
                        text: 'You have completed your group training session! Next steps: All users present for the training session must sign in and complete the training task that has been assigned to them.',
                        title: 'Well Done!',
                        allowEnterKey: true,
                        allowEscapeKey: false,
                        confirmButtonColor: '#4FB973',
                        confirmButtonText: 'Close',
                        allowOutsideClick: false
                    }).finally(() => {
                        history.push('/training/group/list');
                    });
                    completeTraining(false);
                } else {
                    nextTraining(training_data);
                };
            };

            lastDataSaveTime = state.currentTime;
        };
    };

    const getCurrentTime = (viData, training_data) => {
        if (videoRef.current) {
            // Subscribe state change
            videoRef.current.subscribeToStateChange((state, prevState) => handlePlayerRef(state, prevState, viData, training_data));
        };
    };

    const playVideo = async (video_Data, training_data) => {
        try {
            const { data } = await axios.get(`/api/v2/trainingVideo/` + encodeURIComponent(video_Data.name));
            setCurrentVideoUrl(data);
            setTimeout(() => {
                getCurrentTime(video_Data, training_data);
                pauseTraining(false);
                videoPaused = false;
            }, 0);
        } catch (error) {
            console.log("🚀 ~ playVideo ~ error:", error);
        }
    };

    const getPositions = (user) => {
        if (user.adminLocations && user.adminLocations.length && user.adminLocations.includes(loginUser.location._id)) {
            return 'Administration';
        } else {
            return 'Non-administration';
        }
    };

    const getRoleForCurrentLocation = (user) => {
        const role = roles.find(r => user.roles.includes(r._id)) || {};
        return role.label || '--';
    };

    const pauseTraining = async (status) => {
        try {
            await axios.put(`/api/v2/group_training/pause/${trainingData._id}`, { status });
            setIsOpenPauseModal(false);
            history.push('/training/group/list');
        } catch (error) {
            console.log("🚀 ~ pauseTraining ~ error:", error);
        }
    };

    const completeTraining = async (navigate) => {
        try {
            const searchParams = new URLSearchParams(history.location.search);
            const id = searchParams.get('id');
            await axios.put(`/api/v2/group_training/complete/${id}`);
            setIsOpenCompleteModal(false);
            if (navigate) history.push('/training/group/list');
        } catch (error) {
            console.log("🚀 ~ completeTraining ~ error:", error);
        }
    };

    const nextTraining = async (training_data) => {
        try {
            await axios.put(`/api/v2/group_training/next/${training_data._id}`);
            getMasterData();
        } catch (error) {
            console.log("🚀 ~ nextTraining ~ error:", error);
        }
    };

    const closePauseModal = () => {
        setIsOpenPauseModal(false);
        setIsOpenCompleteModal(false);
        videoRef.current.play();
    };

    const openPauseModal = () => {
        videoRef.current.pause();
        setIsOpenPauseModal(true);
    };

    const openCompletedModal = () => {
        videoRef.current.pause();
        setIsOpenCompleteModal(true);
    };
    const calculateNeededDuration = (user) => {
        const temp = 3600 - user.trainingMinutesWatched;
        if (temp > 0) {
            return formatTimeInMinAndSec(temp);
        }
        return '0 min'
    }
    return (
        <GroupTrainingContainer>
            {loading ?
                <div className='w-100 my-3 p-4 text-center'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
                <TrainingBody>
                    <HeaderContainer className="py-3 mx-3">
                        <HeaderText>{videoData[trainingData.currentVideo].title}</HeaderText>
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => history.push('/training/group/list')} variant="light" type='button'>{'<< Back'}</Button>
                        </div>
                    </HeaderContainer>
                    <PlayerContainer className="py-2 px-3" posterPresent={videoData[trainingData.currentVideo].poster ? true : false}>
                        <Player
                            aspectRatio='16:9'
                            fluid={false}
                            width={window.screen.width - 180}
                            height={window.screen.height - 700}
                            autoPlay={false}
                            startTime={videoData[trainingData.currentVideo].currentTime}
                            ref={videoRef}
                            controls={true}
                            src={currentVideoUrl}
                            muted={false}
                            poster={videoData[trainingData.currentVideo].poster ? apiUrl('/api/v2/trainingPoster/' + encodeURIComponent(videoData[trainingData.currentVideo].poster.replaceAll('/', '~|~'))) : defaultPoster}
                        >
                            <ControlBar disableDefaultControls={false} autoHide={true}>
                                <PlayToggle key="play-toggle" order={1} />
                                <ReplayControl seconds={5} order={2.1} />
                                <ReplayControl seconds={10} order={2.2} />
                                <ReplayControl seconds={30} order={2.3} />
                                <VolumeMenuButton key="volume-menu-button" order={4} />
                                <CurrentTimeDisplay key="current-time-display" order={5} />
                                <TimeDivider key="time-divider" order={6} />
                                <DurationDisplay key="duration-display" order={7} />
                                <RemainingTimeDisplay key="remaining-time-display" order={9} />
                                <FullscreenToggle key="fullscreen-toggle" order={11} />
                            </ControlBar>
                            <Shortcut clickable={false} shortcuts={[{ keyCode: 37, handle: () => { } }, { keyCode: 39, handle: () => { } }]} />
                        </Player>
                    </PlayerContainer>
                    <div className="d-flex justify-content-end m-3">
                        <Button onClick={() => openCompletedModal()} className="mr-2 text-success" size="lg" variant="light" type='button'>End Group Training</Button>
                        <Button onClick={() => openPauseModal()} size="lg" variant="success" type='button'>Pause Group Training</Button>
                    </div>
                    <CustomCard className="mx-3">
                        <HeaderContainer className="mx-3 pb-2 align-items-center">
                            <SubHeader>People at This Training</SubHeader>
                            <div className="text-center">
                                <p className="text-secondary mb-1">Credit can get</p>
                                <Badge>
                                    <div className='text-center badge yellow'>
                                        <div className="text">{formatTimeInHmm(trainingData.totalDuration)}</div>
                                    </div>
                                </Badge>
                            </div>
                        </HeaderContainer>
                        <TableContainer className='mt-2' maxHeight='60vh'>
                            <table className='table'>
                                <TableHead>
                                    <tr>
                                        <th className='w-20 pl-4'>Name</th>
                                        <th className="w-20">Position</th>
                                        <th className="w-20">Role</th>
                                        <th className='w-20'>Credit earned</th>
                                        <th className='w-20'>Credit needed</th>
                                    </tr>
                                </TableHead>
                                {!loading &&
                                    <tbody>
                                        {trainingData.users.map((user) => (
                                            <tr className='justify-content-between align-items-center' key={user._id}>
                                                <td className='w-20 pl-4'>{user.fullName + (user._id === loginUser._id ? ' (Self)' : '')}</td>
                                                <td className="w-20">{getPositions(user)}</td>
                                                <td className="w-20">{getRoleForCurrentLocation(user)}</td>
                                                <td className="w-20">{formatTimeInMinAndSec(user.trainingMinutesWatched) || '0 min'}</td>
                                                <td className="w-20">{calculateNeededDuration(user) || '0 min'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                }
                            </table>
                        </TableContainer>
                    </CustomCard>
                </TrainingBody>
            }
            <Modal show={isOpenPauseModal} onHide={closePauseModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={true}
                contentClassName='trainingMaterialsInfoModel'
            >
                <Modal.Header>
                    <Modal.Title>Pause group training</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mt-3 mx-4'>
                    <p className='p'>
                        Would you like to pause this training session so you can return later?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className='btn btn-light' onClick={closePauseModal}>
                        Close
                    </button>
                    <button type="button" className='btn btn-success' onClick={() => pauseTraining(true)}>
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={isOpenCompleteModal} onHide={closePauseModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={true}
                contentClassName='trainingMaterialsInfoModel'
            >
                <Modal.Header>
                    <Modal.Title>End group training</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mt-3 mx-4'>
                    <p className='p'>
                        Would you like to end this?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className='btn btn-light' onClick={closePauseModal}>
                        Close
                    </button>
                    <button type="button" className='btn btn-success' onClick={() => completeTraining(true)}>
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
        </GroupTrainingContainer>
    )
}

export default GroupTrainingView;