import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { sendMessage, openPanel, initSocket, openNotificationPanel } from '../actions';

import TodayIcon from '../assets/images/sidebar-today-normal.svg';
import TrainingIcon from '../assets/images/sidebar-training-normal.svg';
import RecordsIcon from '../assets/images/sidebar-records-normal.svg';
import LibraryIcon from '../assets/images/sidebar-library-normal.svg';
import AdministrationIcon from '../assets/images/sidebar-administration-normal.svg';
import TodayIconSelected from '../assets/images/sidebar-today-selected.svg';
import FormIcon from '../assets/images/gray-form.svg';
import FormIconSelected from '../assets/images/green-form.svg';
import TrainingIconSelected from '../assets/images/sidebar-training-selected.svg';
import RecordsIconSelected from '../assets/images/sidebar-records-selected.svg';
import LibraryIconSelected from '../assets/images/sidebar-library-selected.svg';
import AdminIconSelected from '../assets/images/sidebar-administration-selected.svg';
import SupportIcon from '../assets/images/sidebar-support-selected.svg';
import SelectedChatIcon from '../assets/images/selected-chat-icon.svg';
import NormalSDSIcon from '../assets/images/sds-gray.svg';
import SelectedSDSIcon from '../assets/images/sds-green.svg';
import NormalChatIcon from '../assets/images/chat-icon-normal.svg';
import logo from '../assets/images/ocoord-logo.svg';

const NavBarStyled = styled.nav`
  position: fixed;
  width: 60px;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #343747;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  animation: navigation-sidebar-slide-in 0.5s cubic-bezier(0.105, 0.64, 0.345, 1.005);
  animation-delay: 0.5s;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavigationIcon = styled.div`
  width: 50px;
  height: 40px;
  text-align: center;
  line-height: 50px;
  margin: 10px 0px;
  position: relative;
  &:hover div {
    display: inherit !important;
  }
  .selected {
    img {
      content: ${(props) => `url(${props.selectedIcon})`};
    }
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    img {
      vertical-align: middle;
      width: 30px;
      height: 30px;
    }
  }
`;

const LinkNavigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const SupportContainer = styled.div`
  background-color: #1d202b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const NavLinkToast = styled.div`
  background: #9598a3;
  color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  position: absolute;
  left: 65px;
  top: 80%;
  line-height: 10px;
  transform: translateY(-50%);
  display: none;
`;

const OcoordLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 10px;
`;

const StyledImg = styled.img`
  width: 35px;
  height: 35px;
  margin: 10px;
  cursor: pointer;
`;

const SupportBox = styled.div`
  position: absolute;
  left: 70px;
  top: -220px;
  height: 256px;
  width: 378px;
  border: 0.5px solid #4fb973;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  h2 {
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
    margin: 0;
    margin-bottom: 5px;
  }
  textarea {
    width: 99%;
    height: 99%;
    border: none;
    color: var(--light-text-color);
    font-family: 'Open Sans';
    font-size: 11px;
    line-height: 15px;
    resize: none;
    box-shadow: none !important;
    margin-bottom: 5px;
    -webkit-appearance: none;
  }
`;

const ButtonPanel = styled.div`
  width: calc(100% + 28px);
  height: 40px;
  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: -9px;
  border-top: 1px solid #d3d6dd;
`;

const Button = styled.button`
  width: 50%;
  background-color: white;
  font-family: 'Open Sans';
  height: 99%;
  color: ${(props) => (props.green ? '#4FB973' : '#7e818f')};
  cursor: pointer;
  border: none;
  border-radius: 7px;
  border-left: ${(props) => (props.green ? '.5px solid #d3d6dd' : '')};
  border-top-left-radius: ${(props) => (props.green ? '0' : '7px')};
  border-bottom-left-radius: ${(props) => (props.green ? '0' : '7px')};
`;

class NavBar extends React.Component {
  state = {
    showSupport: false,
    message: '',
    messageSent: false,
    user: {
      firstName: [],
      lastName: []
    }
  };

  componentDidMount() {
    const { user, dispatch } = this.props;
    dispatch(openPanel(false));
    dispatch(openNotificationPanel(false));
    this.connectToSocket();
    this.setState({ user });
  };

  connectToSocket = async () => {
    const { dispatch, user: { _id } } = this.props;
    await dispatch(initSocket(_id));
  };

  toggleSupportPanel = async () => {
    const { dispatch } = this.props;
    await dispatch(openPanel(false));
    await dispatch(openNotificationPanel(false));
    this.setState({ message: '', showSupport: !this.state.showSupport });
  };

  handleTextAreaChange = (e) => {
    this.setState({ message: e.target.value });
  };

  handleSendMessage = () => {
    this.props.dispatch(sendMessage(this.state.message));
    this.setState({ showSupport: false });
    toast.success('Message sent successfully');
  };

  render() {
    const { showSupport, message } = this.state;
    const { user } = this.props;

    if (!Cookies.get('token')) {
      return null;
    } else {
      return (
        <NavBarStyled>
          <LinkNavigation>
            <Link to='/'>
              <OcoordLogo src={logo} />
            </Link>
          </LinkNavigation>
          <IconsContainer>
            <NavigationIcon selectedIcon={TodayIconSelected}>
              <NavLink exact to='/' activeClassName='selected' className='nav-link'>
                <img src={TodayIcon} alt='Dashboard' />
              </NavLink>
              <NavLinkToast>Dashboard</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={TrainingIconSelected}>
              <NavLink
                to='/training'
                activeClassName='selected'
                className='nav-link'>
                <img src={TrainingIcon} alt='Training' />
              </NavLink>
              <NavLinkToast>Training</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={RecordsIconSelected}>
              <NavLink to='/records' activeClassName='selected' className='nav-link'>
                <img src={RecordsIcon} alt='Records' />
              </NavLink>
              <NavLinkToast>Records</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={FormIconSelected}>
              <NavLink to='/forms' activeClassName='selected' className='nav-link'>
                <img src={FormIcon} alt='Forms' />
              </NavLink>
              <NavLinkToast>Forms</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={LibraryIconSelected}>
              <NavLink to='/library' activeClassName='selected' className='nav-link'>
                <img src={LibraryIcon} alt='Library' />
              </NavLink>
              <NavLinkToast>Library</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={SelectedChatIcon}>
              <NavLink to='/medicc' activeClassName='selected' className='nav-link'>
                <img src={NormalChatIcon} alt='MEDiCC' />
              </NavLink>
              <NavLinkToast>MEDiCC</NavLinkToast>
            </NavigationIcon>
            <NavigationIcon selectedIcon={SelectedSDSIcon}>
              <NavLink
                to='/sds'
                activeClassName='selected'
                className='nav-link'>
                <img src={NormalSDSIcon} alt='Safety Data Sheet' />
              </NavLink>
              <NavLinkToast>SDS</NavLinkToast>
            </NavigationIcon>
            {user.isAdmin && (
              <NavigationIcon selectedIcon={AdminIconSelected}>
                <NavLink
                  to='/admin'
                  activeClassName='selected'
                  className='nav-link'>
                  <img src={AdministrationIcon} alt='Administration' />
                </NavLink>
                <NavLinkToast>Admin</NavLinkToast>
              </NavigationIcon>
            )}
          </IconsContainer>
          <SupportContainer>
            <StyledImg src={SupportIcon} onClick={this.toggleSupportPanel} />
            {showSupport && (
              <SupportBox>
                <h2>Send us a Message</h2>
                <textarea
                  name='support message'
                  placeholder='Type your message...'
                  onChange={this.handleTextAreaChange}
                  value={message}
                />
                <ButtonPanel>
                  <Button onClick={this.toggleSupportPanel}>Cancel</Button>
                  <Button green onClick={this.handleSendMessage}>
                    Send
                  </Button>
                </ButtonPanel>
              </SupportBox>
            )}
          </SupportContainer>
        </NavBarStyled>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser
  };
};

export default connect(mapStateToProps)(NavBar);
