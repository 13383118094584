import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';

import { Input, Button } from '../components';
import OcoordLogo from '../assets/images/logo-icon-flat.svg';
import { submitRegistration } from '../actions';
import { Col, Row } from 'react-bootstrap';


const CustomPhoneInput = styled(PhoneInput)`
    width: 100%;
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    background: white;
    .PhoneInputCountry{
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        padding: 0px 8px;
    }
    input{
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        padding: 4px 8px;
        transition: border 0.15s linear, box-shadow 0.15s linear;
        width:100%;
    }
`;

const NavigationTabSection = styled(Row)`
    width: 100%;
    color:#676767 !important;
    margin-bottom: 1rem!important;
    margin-right: 0px !important; 
    margin-left: 0px !important;
    line-height: 30px;
    text-align: center;
`;

const NavTab = styled(Col)`
    font-size: 1.2rem;
    font-weight: 600 !important;
    text-align: center;
    cursor:pointer;
    border-bottom: 1px solid #676767;
    @media screen and (max-device-width: 1024px) and (orientation: portrait) {
        line-height: 3rem;
        white-space: pre;
    }
    @media screen and (max-device-width:1024px) and (orientation: landscape) {
        line-height: 3rem;
        white-space: pre;
    }
    
    :hover{
        background: #e3ffec !important;
    }  
    &.active {
        color:#42A764 !important;
        border-bottom: 2px solid #42A764;
    }
`;

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
  text-align: left;
`;

const FormContainer = styled(Col)`
  padding: 45px;
  width: 325px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
`;

const LoginHeader = styled.div`
  text-align: center;
  img {
    max-height: 50px;
  }
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  width: 100%;
  p {
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  margin-bottom: 5px;
  display: block;
`;

/**
 * Login Page
 *
 * @class Login
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */
class JoinWithCode extends Component {
    state = {
        userName: '',
        joinCode: '',
        loading: false,
        error: '',
        codeHash: ''
    };

    componentDidMount() {
        const { history: { location } } = this.props;
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');
        const phone = searchParams.get('phone');
        const joinCode = searchParams.get('code');
        if (!joinCode) {
            this.setState({ error: 'Join Code not found.' });
        } else {
            if (email) {
                this.setState({ userName: email, joinCode });
            };
            if (phone) {
                this.setState({ userName: phone, joinCode });
            };
        };
    };

    handleInputChange = ({ target, key }) => {
        console.log(target);
        if (key === 'Enter') {
            this.handleSubmit();
        } else {
            this.setState({ [target.name]: target.value });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        const { joinCode, userName, codeHash } = this.state;
        this.setState({ loading: true });
        const payload = { joinCode, codeHash };
        payload.userName = userName.toLowerCase();
        await dispatch(submitRegistration(payload));
        this.setState({ loading: false });
    };

    render() {
        const { userName, joinCode, loading } = this.state;
        return (
            <FormContainer lg='3' md='6' sm='10'>
                <LoginHeader>
                    <img src={OcoordLogo} alt='ocoord-logo' />
                    <h2>Join Ocoord</h2>
                    <p>Enter your email address and Join Code to get started.</p>
                </LoginHeader>
                <LoginForm onSubmit={this.handleSubmit}>
                    <StyledLabel>Username</StyledLabel>
                    <Input
                        name='userName'
                        type='text'
                        placeholder='Email Address'
                        onChange={this.handleInputChange}
                        value={userName}
                    />
                    <Input
                        name='joinCode'
                        placeholder='JOIN CODE'
                        type='text'
                        onChange={this.handleInputChange}
                        value={joinCode}
                    />
                    <p className='mb-0'>
                        If you don't know your Join Code, ask your practice's administrator what it is.
                    </p>
                    <Button disabled={loading} type='submit'>{loading ? 'Loading...' : 'Continue'}</Button>
                </LoginForm>
                <div className='text-center'>
                    <StyledLink to='/registerPractice'>Create a new practice</StyledLink>
                    <StyledLink to='/signin'>Already have an account? Sign in</StyledLink>
                </div>
            </FormContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated
});

export default withRouter(connect(mapStateToProps)(JoinWithCode));