import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { CardHolderName, CardNumberInputContainer, StyledLabel } from './CardElement';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Button } from '../Button';
import { states } from '../../helpers';
import styled from 'styled-components';

const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight:600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
`;

const BillingAddressUpdate = ({ closePopUpModal, save, user }) => {

    const [billingAddress, setBillingAddress] = useState({ "address_city": '', "address_country": 'US', "address_line1": '', "address_line2": '', "address_state": '', "address_zip": '' });
    const [formValidate, setFormValidate] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPracticeData();
    }, []);

    const getPracticeData = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/practice/${user.practice._id}`);

            setBillingAddress({ address_city: payload.billingAddress.city, address_country: 'US', address_line1: payload.billingAddress.address, address_line2: payload.billingAddress.address2 || '', address_state: payload.billingAddress.state, address_zip: payload.billingAddress.zip });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error:=====", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        try {
            if (!form.checkValidity() || !billingAddress.address_state || billingAddress.address_zip.length !== 5) {
                e.preventDefault();
                e.stopPropagation();
                setFormValidate(true);
            } else {
                await save(billingAddress);
            }
        } catch (error) {
            console.log("error:=====", error);
        }
    };

    const onModelChange = (name, value) => {
        const temp = { ...billingAddress };
        temp[name] = value;
        setBillingAddress(temp);
    };

    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Check if the pressed key is a number or a decimal point
        if (!/^\d*\.?\d*$/.test(keyValue)) event.preventDefault();
        if (event.target.value.length === 5) event.preventDefault();
    };

    const disableSpaceAtFirst = (event) => {
        if (event.target.value.length === 0 && event.key === ' ') {
            // Prevent the default behavior of the space key and stop the event from propagating
            event.preventDefault();
            return;
        }
    };

    return (
        <>{
            loading ?
                <Container className='text-center my-3'>
                    <Spinner animation="border" variant="success" />
                </Container>
                :
                <Form noValidate validated={formValidate} onSubmit={handleSubmit}>
                    <Row>
                        <Col lg={6}>
                            <StyledLabel>Street Address</StyledLabel>
                            <CardHolderName onKeyDown={disableSpaceAtFirst} required placeholder='Address' onChange={(e) => onModelChange('address_line1', e.target.value)} type="text" value={billingAddress.address_line1} />
                            <ErrorContainer type="invalid">Required</ErrorContainer>
                        </Col>
                        <Col lg={6}>
                            <StyledLabel>Street Address</StyledLabel>
                            <CardHolderName onKeyDown={disableSpaceAtFirst} required placeholder='Address line 2' onChange={(e) => onModelChange('address_line2', e.target.value)} type="text" value={billingAddress.address_line2} />
                            <ErrorContainer type="invalid">Required</ErrorContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <StyledLabel>City</StyledLabel>
                            <CardHolderName onKeyDown={disableSpaceAtFirst} required placeholder='City' onChange={(e) => onModelChange('address_city', e.target.value)} type="text" value={billingAddress.address_city} />
                            <ErrorContainer type="invalid">Required</ErrorContainer>
                        </Col>
                        <CardNumberInputContainer lg={6}>
                            <StyledLabel>State</StyledLabel>
                            <Select
                                value={states.find(s => s.value === billingAddress.address_state)}
                                options={states}
                                onChange={(e) => onModelChange('address_state', e.value)} placeholder='State' />
                            {(!billingAddress.address_state && formValidate) ? <p style={{ fontWeight: '600', fontSize: '1rem' }} className='my-0 text-danger'>Required</p> : null}
                        </CardNumberInputContainer>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <StyledLabel>ZIP Code</StyledLabel>
                            <CardHolderName onKeyDown={disableSpaceAtFirst} required placeholder='ZIP Code' onChange={(e) => onModelChange('address_zip', e.target.value)} type="text" value={billingAddress.address_zip} onKeyPress={handleKeyPress} />
                            <ErrorContainer type="invalid">Required</ErrorContainer>
                            {(billingAddress.address_zip.length && billingAddress.address_zip.length < 5 && formValidate) ? <p style={{ fontWeight: '600', fontSize: '1rem' }} className='my-0 text-danger'>Invalid Zip Code</p> : null}
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={6}>
                            <Button className='my-0' type='button' onClick={() => closePopUpModal()}>Cancel</Button>
                        </Col>
                        <Col lg={6}>
                            <Button className='my-0' type='submit' >{'Next >>'}</Button>
                        </Col>
                    </Row>
                </Form>
        }
        </>
    );
};

export default BillingAddressUpdate;
