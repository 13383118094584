import actions from './actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';
import { apiUrl } from '../helpers';

/**
 * @namespace taskActions
 * @category Actions
 */

/**
 * @method
 * @memberOf taskActions
 * @param {*} tasks
 */
const getTasksSuccess = (tasks) => ({
  type: actions.GET_TASKS_SUCCESS,
  tasks
});

/**
 * @method
 * @memberOf taskActions
 * @param {*}
 */
const getTasksFailure = () => ({
  type: actions.GET_TASKS_FAILURE
});

/**
 * @method
 * @memberOf taskActions
 * @param {*} task
 */
const completeTasksSuccess = (task) => ({
  type: actions.COMPLETE_TASK_SUCCESS,
  task
});

/**
 * @method
 * @memberOf taskActions
 * @param {*}
 */
const completeTasksFailure = () => ({
  type: actions.COMPLETE_TASK_FAILURE
});

/**
 * @method
 * @async
 * @memberOf taskActions
 * @param {*} task, newReport
 */
export const completeTask = (task, newReport) => async (dispatch, getState) => {
  try {
    const { user } = getState();
    newReport && (task.formData = JSON.stringify(newReport.formData));
    const newTaskObj = {
      ...task,
      isCompleted: true,
      completedBy: { _id: user.currentUser._id },
      isApproved: user.currentUser.isAdmin
    };
    if (!newTaskObj.location) newTaskObj.location = user.currentUser.location._id;
    await axios.put(apiUrl(`/api/v1/tasks/${task._id}`), newTaskObj);
    dispatch(completeTasksSuccess(newTaskObj));
  } catch (err) {
    console.log("🚀 ~ completeTask ~ err:", err)
    dispatch(completeTasksFailure());
    toast.error(
      `error in completeTask: ${err.response ? err.response.data.message : 'An Error Ocurred'
      }`
    );
  }
};

