import actions from '../actions/actionTypes';

/**
 * @namespace trainingReducer
 * @category Reducers
 */

/**
 * @memberOf trainingReducer
 */
const initialState = {
  loading: false,
  error: false,
  videos: []
};

/**
 * @method
 * @memberOf trainingReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const trainingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_VIDEOS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case actions.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        videos: [
          ...action.videos
        ]
      };
    case actions.GET_VIDEOS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
