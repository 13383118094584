import actions from '../actions/actionTypes';

/**
 * @namespace accountReducer
 * @category Reducers
 */

/**
 * @memberOf accountReducer
 */
const initialState = {
    currentPanel: 'details'
};

/**
 * @method
 * @memberOf accountReducer
 * @param {*} [state = initialState]
 * @param {*} action
 * */
export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SELECT_ACCOUNT_TAB:
            return {
                ...state,
                currentPanel: action.newPanel
            };
        default:
            return state;
    };
}