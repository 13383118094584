import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;

export const CardHolderName = styled.input`
display: block;
padding: 10px 14px;
font-size: 16px;
font-family: 'Source Code Pro', monospace;
border: 1px solid #ccc;
/* Add a border to the input */
border-radius: 4px;
box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
transition: box-shadow 150ms ease;
line-height: normal;
width:100%;
&:focus {
    border-color: #80bdff;
    /* Change border color when the input is focused */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
`;

export const CardNumberInputContainer = styled(Col)`
display: flex;
flex-direction: column;

.StripeElement {
    display: block;
    padding: 10px 14px;
    font-size: 16px;
    border: 1px solid #ccc;
    /* Add a border to the input */
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    border-color: #80bdff;
    /* Change border color when the input is focused */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
`;
const CardElement = ({ stripe, disableToEdit }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
    const stripeCard = disableToEdit ? stripe.sources.data[0] : {};
    return (
        <Elements stripe={stripePromise} >
            <Row>
                <Col lg={6}>
                    <StyledLabel>Cardholder Name</StyledLabel>
                    <CardHolderName disabled={disableToEdit} type="text" value={stripe?.name} />
                </Col>
                <CardNumberInputContainer lg={6}>
                    <StyledLabel>Card Number</StyledLabel>
                    <CardHolderName disabled type="text" value={'**** **** **** ' + stripeCard.last4} />
                </CardNumberInputContainer>
            </Row>
            <Row>
                <CardNumberInputContainer lg={6}>
                    <StyledLabel>Expiration Date</StyledLabel>
                    <CardHolderName disabled type="text" value={stripeCard.exp_month + '/' + stripeCard.exp_year} />
                </CardNumberInputContainer>
                <CardNumberInputContainer lg={6}>
                    <StyledLabel>CVC</StyledLabel>
                    <CardHolderName disabled type="text" value='***' />
                </CardNumberInputContainer>
            </Row>
        </Elements>
    );
};

export default CardElement;
