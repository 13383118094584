import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Card } from '../components';
import profileImage from '../assets/images/account-avatar-hero.svg';
import BasicDetails from '../components/Account/BasicDetails';
import Safety from '../components/Account/Safety';
import { getAdminsForCurrentLocation, selectPanel, signOut } from '../actions';
import ChangePassword from '../components/Account/ChangePassword';
import UsersAdmins from '../components/Account/UsersAdmins';
import SafetyOfficers from '../components/Account/SafetyOfficers';

const AccountContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: self-start;

  @media only screen and (max-width:480px){
    display: block;
    margin-top: 11.8rem;
    margin-left: 6.8rem !important;
    width: 75%;
    margin-bottom: 3rem;
    margin-right: 1rem;
   }
   @media only screen and (max-width:900px) and (min-width: 481px){
    display:block;
    margin-top:9.5rem;
    margin-left: 4.5rem;
    width: 75%;
    margin-bottom: 3rem;
   }
  @media only screen and (max-width:1024px) and (min-width:901px){
    margin-top: 10rem;
    margin-left: 0;
      width: 82%;
  }
  @media only screen and (max-width:1500px) and (min-width: 1401px){
    margin-top: 9%;
    margin-left: 7.2rem;
    width: 100%;
   }
  @media only screen and (max-width:1400px) and (min-width: 1301px){
    margin-top: 10%;
    margin-left: 7.5rem;
    width: 100%;
   }
  @media only screen and (max-width:1300px) and (min-width: 1201px){
    margin-top: 10%;
    margin-left:7rem;
    width: 100%;
   }
  @media only screen and (max-width:1200px) and (min-width: 1101px){
    margin-top: 10.5%;
    margin-left:7rem;
    width: 100%;
   }
   @media only screen and (max-width:1100px) and (min-width: 1025px){
    margin-top: 11%;
    margin-left: 8%;
    width: 100%;
   }
   @media only screen and (max-width:1700px) and (min-width: 1501px){
    margin-top: 8.3%;
    margin-left: 7.3rem;
    width: 100%;
   }
   @media only screen and (max-width:2000px) and (min-width: 1701px){
    margin-top: 7.5%;
    margin-left:8rem;
    width: 100%;
   }
   @media only screen and (max-width:2500px) and (min-width: 2001px){
    margin-top:6%;
    margin-left:13rem;
    width: 100%;
   }
   @media only screen and (max-width:3000px) and (min-width: 2501px){
    margin-top:4.5%;
    margin-left:15rem;
    width: 100%;
   }
   @media only screen and (max-width:3500px) and (min-width:3001px){
    margin-top:4.5%;
    margin-left:21rem;
    width: 100%;
   }
   @media only screen and (max-width:4000px) and (min-width:3501px){
    margin-top:4%;
    margin-left:24rem;
    width: 100%;
   }
`;

const ProfileContainer = styled(Card)`
  text-align: center;
  min-width: 285px;
  margin-right:2.2rem;
  @media (max-width:480px) {
min-width:210px;
      }
      @media only screen and (max-width:868px) and (min-width: 481px){
        min-width:355px;
      }      
      @media only screen and (max-width:1024px) and (min-width: 769px){
      margin-right:2rem;
      
      }
  img{
    margin:10px 0 5px 0;
  }
  @media (max-width:900px) {
    margin-bottom: 3rem;
    margin-right: 0;
      }
  h2,
  p {
    margin: 0;
  }
  h2 {
    margin: 15px 0;
  }
  p {
    margin-bottom: 25px;
    @media (max-width:768px) {
      text-align:center;
      width:100%;
  }
  img {
    width: 67px;
    height: 67px;
    margin-top: 25px;
  }
`;
const TabContainer = styled.div`
  text-align: left;
  padding: 15px 40px 15px 50px;
  background: #fbfbfd;
  border-top: 0.5px solid #e7e7e7;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  @media (max-width:768px) {
    display:block;
    width:100%;
    padding:13px;
   }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    li {
      list-style-type: none;
      margin: 5px 0;
      cursor: pointer;
    }
    .active {
      color: #28a745!important;
      font-weight: 600;
    }
  }
`;

/**
 * Account Page
 *
 * @class Account
 * @extends {Component}
 * @category Pages
 * @component
 * @hideconstructor
 */
class Account extends Component {
  state = {
    currentPanel: this.props.currentPanel
  };

  signOut = () => {
    this.setState({ showPanel: false });
    this.props.dispatch(signOut());
  };

  handlePanelClick = async (name) => {
    const { dispatch } = this.props;
    await dispatch(selectPanel(name));
    if (name === 'admin') {
      await dispatch(getAdminsForCurrentLocation());
    };
  };

  render() {
    const { user, currentPanel } = this.props;
    return (
      <AccountContainer>
        <ProfileContainer>
          <img src={profileImage} alt='' />
          <div style={{ wordWrap: 'break-word', width: '100%' }}>
            <p style={{ paddingLeft: "8px", paddingRight: "8px", wordWrap: 'break-word', fontSize: '20px', fontWeight: '600' }}>{user.fullName}</p>
          </div>
          <TabContainer>
            <ul>
              <li className={currentPanel === 'details' ? 'active' : ''} onClick={() => this.handlePanelClick('details')}>Basic Details</li>
              <li className={currentPanel === 'admin' ? 'active' : ''} onClick={() => this.handlePanelClick('admin')}>My Admin</li>
              <li className={currentPanel === 'safetyOfficer' ? 'active' : ''} onClick={() => this.handlePanelClick('safetyOfficer')}>My Safety Officer</li>
              <li className={currentPanel === 'safety' ? 'active' : ''} onClick={() => this.handlePanelClick('safety')}>Safety</li>
              <li className={currentPanel === 'password' ? 'active' : ''} onClick={() => this.handlePanelClick('password')}>Password & Security</li>
              <li onClick={this.signOut}>Logout</li>
            </ul>
          </TabContainer>
        </ProfileContainer>
        {currentPanel === 'details' && <BasicDetails user={user} />}
        {currentPanel === 'safety' && <Safety />}
        {currentPanel === 'admin' && <UsersAdmins />}
        {currentPanel === 'safetyOfficer' && <SafetyOfficers />}
        {currentPanel === 'password' && <ChangePassword user={user} />}
      </AccountContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.currentUser,
  currentPanel: state.account.currentPanel
});

export default connect(mapStateToProps)(Account);
