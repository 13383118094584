import styled from "styled-components";

const Badge = styled.div`
    justify-content: center!important;
    display: flex;
    .badge {
        align-items: center;
        border-radius: 20px;
        display: flex;
        gap: 10px;
        padding: 6px 16px;
        position: relative;
        min-width: 111px;
    }

    .badge .dot {
        border-radius: 2.5px;
        height: 5px;
        position: relative;
        width: 5px;
    }

    .badge .text {
        font-family: "Open Sans-Regular", Helvetica;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        width: fit-content;
    }

    .badge.yellow {
        background-color: #f9f8f3;
    }

    .badge.grey {
        background-color: rgba(249, 249, 249, 1);
    }

    .badge.green {
        background-color: rgba(243, 249, 245, 1);
    }

    .badge.yellow .dot {
        background-color: #bc9b25;
    }

    .badge.grey .dot {
        background-color: rgba(126, 129, 143, 1);
    }

    .badge.green .dot {
        background-color:rgba(66, 167, 100, 1);
    }

    .badge.yellow .text {
        color: #bc9b25;
    }

    .badge.grey .text {
        color: rgba(126, 129, 143, 1);
    }

    .badge.green .text {
        color:rgba(66, 167, 100, 1);
    } 
`;

const StatusBadge = ({ children }) => {
    return (
        <Badge>
            {children}
        </Badge>
    );
};

export default StatusBadge;