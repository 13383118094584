import actions from '../actions/actionTypes';
/**
 * @namespace formsReducer
 * @category Reducers
 */

/**
 * @memberOf formsReducer
 */
const initialState = {};

/**
 * @method
 * @memberOf formsReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const formsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SELECT_FORM_SUCCESS:
            return {
                ...state,
                selectedForm: action.formName
            };
        default:
            return state;
    }
};