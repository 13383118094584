
import React, { Component } from 'react';
import axios from 'axios';
import { Alert, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import styled from 'styled-components';

import pencil from '../../assets/images/white_pencil.svg';
import arrow from '../../assets/images/glyph-arrow-down-green.svg';
import { PaginationSection, TableContainer, TableHead } from './Style';
import { Button, UpdateCardDetails } from '../../components';

const SubscriptionContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
    justify-content: start;
    align-self: flex-start;
    align-items: flex-start;
    overflow-y: auto;
`;

const ArrowButton = styled.div`
  color: var(--key-color);
  font-weight: 600;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    width: calc(1em - 1px);
    height: calc(1em);
    margin-left: 5px;
    background-image: url("${arrow}");
    background-size: cover;
    background-repeat: no-repeat;
    transition: margin-left 0.25s ease-out;
  }
  &:hover {
    color: var(--key-color);
    &::after {
      margin-left: 10px; 
    }
  }
  &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
`;
class Subscription extends Component {
    state = {
        invoices: [],
        currentInvoices: [],
        loading: false,
        pageCount: 0,
        pageNumber: 1,
        errors: {
            apiError: false
        },
        show: false
    };

    componentDidMount() {
        this.getInvoices();

    };

    getInvoices = async () => {
        try {
            this.setState({ loading: true });
            const { user: { practice } } = this.props;
            const { data: { payload } } = await axios.get('/api/v2/invoices/' + practice._id);
            const pageCount = payload.length / 10;
            this.setState({ invoices: payload, pageCount }, () => {
                this.handlePageClick({ selected: 0 })
            });
        } catch (error) {
            this.setState({ invoices: [], loading: false, errors: { apiError: true, errorMsg: 'Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.' } });
        }
    };

    getReceipt = async (url) => {
        try {
            if (url) {
                const { data: { payload } } = await axios.post('/api/v2/getReceipt', { url });
                this.downloadDocument(payload);
            } else {
                toast.error('Invoice not found.');
            }
        } catch (error) {
            window.open(url, '_blank');
            console.error(error);
        }
    };

    downloadDocument = (url) => {
        try {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'document.doc');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(error);
        }
    };

    handlePageClick = ({ selected }) => {
        this.setState({ pageNumber: selected + 1 });
        const { invoices } = this.state;
        const list = JSON.parse(JSON.stringify(invoices));
        const currentInvoices = list.splice(selected * 10, 10);
        this.setState({ currentInvoices, loading: false, });
    };

    afterUpdateSuccess = () => {
        this.setState({ show: false });
        Swal.fire({
            icon: 'success',
            title: 'Billing details updated successfully',
            allowEnterKey: true,
            allowEscapeKey: false,
            confirmButtonColor: '#4FB973',
            confirmButtonText: 'Close',
            allowOutsideClick: false
        });
    };
    render() {
        const { loading, errors, pageNumber, pageCount, currentInvoices, show } = this.state;
        const { user } = this.props;
        return (
            <SubscriptionContainer className='pt-0'>
                <div className="d-flex justify-content-end mb-3 mx-2">
                    <div>
                        <Button onClick={() => this.setState({ show: true })} width='150' type='button' className='my-0' ><img src={pencil} alt="Add Icon" /> Edit Payment</Button>
                    </div>
                </div>
                <TableContainer maxHeight='60vh'>
                    <table className='table'>
                        <TableHead>
                            <tr>
                                <th className='w-20' scope='col'>Type</th>
                                <th className='w-15' scope='col'>Amount Paid</th>
                                <th className='w-15' scope='col'>Amount Remaining</th>
                                <th className='w-20' scope='col'>Payment Method</th>
                                <th className='w-20' scope='col'>Date</th>
                                <th className='w-10' scope='col'>Receipt</th>
                            </tr>
                        </TableHead>
                        {!loading &&
                            <tbody>
                                {currentInvoices &&
                                    currentInvoices.map((row) => (
                                        <tr key={row.id} light='true'>
                                            <td className='w-20'>Subscription ( {row.lines.data[0].description} )</td>
                                            <td className='w-15'>$ {(row.amount_paid / 100).toFixed(2)}</td>
                                            <td className='w-15'>$ {(row.ending_balance / 100).toFixed(2).replace('-', '')}</td>
                                            <td className='w-20'>{row.charge ? row.charge.payment_method_details.card.brand + '****' + row.charge.payment_method_details.card.last4 : '--'}</td>
                                            <td className='w-20'>{moment(row.date * 1000).format('MM/DD/YYYY')}</td>
                                            <td className='w-10'>
                                                <ArrowButton onClick={(e) => this.getReceipt(row.hosted_invoice_url)}>PDF</ArrowButton>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        }
                    </table>
                    {!loading && errors.apiError &&
                        <Col>
                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                <p className='w-100 mb-0'>{errors.errorMsg}</p>
                            </Alert>
                        </Col>
                    }
                    {loading && <div className='w-100 text-center'>
                        <Spinner animation="border" variant="success" />
                    </div>
                    }
                </TableContainer>
                <PaginationSection className='w-100 text-center'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        previousLabel="<"
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        renderOnZeroPageCount={null}
                    />
                </PaginationSection>
                {show && <UpdateCardDetails afterUpdateSuccess={this.afterUpdateSuccess} show={show} user={user} closePopUpModal={() => this.setState({ show: false })} />}
            </SubscriptionContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(Subscription);