import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const TrainingMaterialsInfoContainer = styled.div`

`;

export class TrainingMaterialsInfoModel extends Component {
    render() {
        const { handleClose, show } = this.props;
        return (
            <TrainingMaterialsInfoContainer>
                <Modal show={show} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    animation={true}
                    contentClassName='trainingMaterialsInfoModel'
                >
                    <Modal.Body className='mt-3 mx-4'>
                        <p className='p'>
                            If you have any questions regarding the training materials you’ve just watched, contact your designated safety officer or send an email to <b>support@ocoord.com</b></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-danger' onClick={handleClose}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </TrainingMaterialsInfoContainer>
        );
    }
};