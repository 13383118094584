import { Col, Button, Alert } from "react-bootstrap";
import { useState } from "react";
import styled from "styled-components";

import { Card, ChangeSubPlan } from "../components";
import smallLogo from '../assets/images/smallLogo.svg';
import OSHA_Advertise_Page from '../assets/images/OSHA_Advertise_Page.png';
import { useSelector } from "react-redux";

const AdvertiseMentContainer = styled(Card)`
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index:0;
    padding: 24px;
    margin: 6.5rem 1rem 0rem 6.5rem !important;
    background-color:#fff;
    box-shadow: 0px 2px 6px 0px rgba(96, 96, 96, 0.13);
    align-self: flex-start;

    .min-w-15{
        min-width:15% !important;
    }
    .min-w-25{
        min-width:25% !important;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;

const InfoCard = styled.div`
    position: relative;
    padding: 24px;
    gap: 24px;
    border-radius: 10px;
    border: 1px solid #D3D6DD;
    .svg-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 40px; /* Adjust the size according to your needs */
        height: 75px; /* Adjust the size according to your needs */
        background: url(${smallLogo});
        background-size: cover;
    }
`;

const ContentContainer = styled.div`
    position: relative;
    border-radius: 10px;
    border: 1px solid #D3D6DD;
    text-align: center;
    img {
        margin-top: 8rem;
        height: 400px;
    }
    .svg-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scaleX(-1);
        left: 0;
        width: 100px; /* Adjust the size according to your needs */
        height: 200px; /* Adjust the size according to your needs */
        background: url(${smallLogo});
        background-size: cover;
    }
`;

const Heading = styled.p`
    color: #343747;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const DetailText = styled.p`
    color:  #565656;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const OSHA_Advertise = () => {
    const [openModal, setOpenModal] = useState(false);
    const user = useSelector(state => state.user.currentUser);

    return (
        <AdvertiseMentContainer>
            <InfoCard>
                <Col lg={8} md={12} sm={12}>
                    <Heading>
                        OSHA Solution
                    </Heading>
                    <DetailText>OSHA by Ocoord is a comprehensive OSHA management solution for your practice. Ocoord generates a custom to your practice library of policies and procedures, creates a calendar of tasks required to maintain compliance, and includes a vast training library for new hire training as well as annual OSHA training.</DetailText>
                </Col>
                <div className="svg-icon"></div>
            </InfoCard>
            <ContentContainer className="mt-5">
                <img src={OSHA_Advertise_Page} />
                <div className="svg-icon"></div>
            </ContentContainer>
            {user && user.isAdmin ?
                <div className="d-flex justify-content-end mt-5">
                    <Button variant="light" type='button'>{'<< Back'}</Button>
                    <Button onClick={() => setOpenModal(true)} className="ml-3" variant="success" type='button'>Subscribe Now</Button>
                </div>
                :
                <div className="text-center mt-5 w-100">
                    <Alert id='error-alert' className='my-2 mx-4' style={{ 'maxWidth': '100%' }} variant="danger">
                        <p className='w-100 mb-0'>Your practice has only subscribed to OSHA. If you want to access this page, please contact the admin.</p>
                    </Alert>
                </div>
            }
            {openModal && <ChangeSubPlan openModal={openModal} setOpenModal={setOpenModal} />}
        </AdvertiseMentContainer>
    )
}
