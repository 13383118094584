import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  border-radius: 4px;
  padding: 6px 10px;
  min-width: 50px;
  height: 29px;
  font-family: 'Hauora';
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  background: var(--key-color);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: none;
  color: white;
  transition: box-shadow 0.2s;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  cursor: pointer;
  margin: 20px 0;
  &:disabled {
    cursor: not-allowed;
    background: #fbfbfb;
    border: 0.5px solid #eaeaea;
    color: #bcbcbc;
  }
`;

export const Button = ({ children, onClick = () => { }, type = 'button', width, disabled, ...rest }) => {
  return (
    <StyledButton
      width={width}
      type={type}
      onClick={(e) => onClick(e)}
      disabled={disabled}
      {...rest}>
      {children}
    </StyledButton>
  );
};

export const EditButton = styled(Button)`
  border-radius: 4px;
  padding: 6px 10px;
  margin: 0px 0px 0px 15px;
  flex-grow: 0;
  min-width: 50px;
  height: 29px;
  font-family: 'Hauora';
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  background: #fbfbfb;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  border: 0.5px solid #eaeaea;
  color: #7f808f;
  text-align: center;
  transition: box-shadow 0.2s;
  min-width: 0;
  width: auto;
`;

export const TransparentButton = styled.button`
    font-weight: 500;
    cursor: pointer;
    background: none;
    border: none;
    &:disabled {
        cursor: not-allowed;
    }
    &:hover {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
        border: .5px solid rgba(0, 0, 0, 0.14);
        border-radius: 2px;
    }
`;

export const HyperLinkButton = styled(TransparentButton)`
        color: var(--key-color);
        font-weight: 700;
        &:hover {
            text-decoration: underline;
            box-shadow: none;
            border: none;
            border-radius: none;
        }
`;