import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  font-size: 12px;
  font-family: 'Hauora';
  color: var(--dark-text-color);
  font-weight: 400;
  background: white;
  border: 0.5px solid #b9bcca;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  padding: 8px;
  transition: border 0.15s linear, box-shadow 0.15s linear;
  margin-top: 2px;
  margin-bottom: 2px;
  width:100%;
`;

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: var(--light-text-color);
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
`;
const StyledSubtext = styled.p`
  font-size: 11px;
  color: var(--light-text-color);
  margin-left: 8px;
  margin-top: 5px;
`;

export const Input = ({ label, placeholder, name, type, onChange, onBlur, value, required, subtext, className, style, disable, id, numberOnly, max }) => {
  const handleKeyPress = (event) => {
    if (numberOnly) {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);

      // Check if the pressed key is a number or a decimal point
      if (!/^\d*\.?\d*$/.test(keyValue)) {
        event.preventDefault();
      }
    }
  };
  const disableSpaceAtFirst = (event) => {
    if (event.target.value.length === 0 && event.key === ' ') {
      // Prevent the default behavior of the space key and stop the event from propagating
      event.preventDefault();
      return;
    }
  };
  return (
    <React.Fragment>
      {label &&
        <StyledLabel>{label}{required && <StyledLabel style={{ color: "red", marginLeft: "3px" }}>*</StyledLabel>}</StyledLabel>
      }
      {disable ? (
        <StyledInput
          id={id}
          name={name}
          type={type}
          onChange={(e) => onChange(e)}
          value={value}
          tabIndex='0'
          required={required}
          placeholder={placeholder}
          className={className}
          style={style}
          disabled
        />
      ) : (
        <StyledInput
          max={max || null}
          id={id}
          name={name}
          type={type}
          onKeyDown={disableSpaceAtFirst}
          onKeyPress={(e) => handleKeyPress(e)}
          onChange={(e) => onChange(e)}
          onBlur={(e) => onBlur ? onBlur(e) : null}
          value={value}
          tabIndex='0'
          required={required}
          placeholder={placeholder}
          className={className}
          style={style}
        />
      )}
      {subtext &&
        <StyledSubtext>{subtext}</StyledSubtext>
      }
    </React.Fragment>
  );
};
