import actions from '../actions/actionTypes';

/**
 * @namespace equipmentReducer
 * @category Reducers
 */

/**
 * @memberOf equipmentReducer
 */
const initialState = {
  equipment: [],
  error: false,
  defaults: {}
};

/**
 * @method
 * @memberOf equipmentReducer
 * @param {*} [state = initialState]
 * @param {*} action
 */
export const equipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipment: action.Equipment
      };
    case actions.CREATE_EQUIPMENT_SUCCESS:
      return {
        ...state
      };
    case actions.GET_DEFAULT_SAFTEY_EQUIPMENT_SUCCESS:
      return {
        ...state,
        defaults: action.defaultSafetyEquipment
      };
    default:
      return state;
  }
};
