import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadNotificationList, updateNotificationStatus } from '../actions';

const NotificationList = styled.div`
margin: 0;

:last-child {
  padding-bottom: 0;
}

.notification {
  cursor: pointer;
  display: flex;

  :hover {
    background: #80808017;
  }

  .notification-icon {
    width: 4rem;
    height: 4rem;
    padding: 10px;
    font-size: 2rem;
  }

  .notification-content {
    display: grid;

    .notification-heading {
      font-size: 1.2rem;
      color: var(--dark-text-color);
      font-weight: bold;
    }

    .notification-desc {
      font-size: 1.1rem;
      color: var(--dark-text-color);
      margin-bottom: 0.5rem;
    }

    .notification-time {
      font-size: 0.8rem;
      color: var(--dark-text-color);

      @media only screen and (max-width:3000px) and (min-width:281px) {
        font-size: 1rem;
      }
    }

  }

  border-bottom:1px solid #ddd;
  margin-bottom:0.7rem;

  &:last-child {
    border-bottom: none;
  }
}

.mark-all-read-tag {
  cursor: pointer;

  :hover {
    color: green;
  }
}
`;
class Notification extends Component {
    state = {

    };

    componentDidMount() {
        this.props.dispatch(loadNotificationList());
    };

    goToNotificationDetails = async (selectedRecord) => {
        if (selectedRecord.type === 'RECORD') {
            window.location.href = `/records?activeRecord=${selectedRecord.record}`;
        };
        if (selectedRecord.type === 'TASK') {
            window.location.href = `/?activeTask=${selectedRecord.record}`;
        };
        await this.props.dispatch(updateNotificationStatus([selectedRecord._id]));
    };

    markAllASRead = async () => {
        const { list } = this.props;
        const ids = list.map((item) => { return item._id });
        await this.props.dispatch(updateNotificationStatus(ids));
    };

    render() {
        const { list } = this.props;
        return (
            <NotificationList>
                {(list && list.length) ? <p onClick={() => this.markAllASRead()} className='py-0 mb-0 text-right mr-2 mark-all-read-tag'>Mark all as read</p> : ''}
                <hr className='mt-0 mb-2' />
                {list.length ?
                    list.map((notification) => (
                        <div onClick={(e) => this.goToNotificationDetails(notification)} key={notification._id} className="notification px-4">
                            <div className="notification-content">
                                <span className="notification-heading">
                                    {notification.title}
                                </span>
                                <span className="notification-desc">
                                    {notification.subTitle}
                                </span>
                                <span className="notification-time">
                                    {moment.utc(notification.createdAt).local().format('MM/DD/YYYY HH:mm A')}
                                </span>
                            </div>
                        </div>
                    ))
                    :
                    <div className="px-4">
                        <h5 className="text-center">
                            No notifications yet.
                        </h5>
                    </div>
                }
            </NotificationList >
        );
    };
};

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
    list: state.notification?.list || [],
    count: state.notification?.count
});

export default connect(mapStateToProps)(Notification);
