import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { locationReducer } from './locationReducer';
import { tasksReducer } from './tasksReducer';
import { equipmentReducer } from './equipmentReducer';
import { trainingReducer } from './trainingReducer';
import { adminReducer } from './adminReducer';
import { libraryReducer } from './libraryReducer';
import { recordsReducer } from './recordsReducer';
import { practiceReducer } from './practiceReducer';
import { formsReducer } from './formsReducer';
import { accountReducer } from './accountReducer';
import { commonReducer } from './commonReducer';
import { notification } from './notification';

export default combineReducers({
  user: userReducer,
  location: locationReducer,
  tasks: tasksReducer,
  equipment: equipmentReducer,
  training: trainingReducer,
  admin: adminReducer,
  library: libraryReducer,
  records: recordsReducer,
  practice: practiceReducer,
  forms: formsReducer,
  account: accountReducer,
  common: commonReducer,
  notification: notification
});
