import React, { Component } from 'react';
import axios from 'axios';
import { Spinner, Col, Row, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import styled from 'styled-components';

import { Button, Input } from '../../components';

import sortIcon from '../../assets/images/sort-icon.svg';
import ascendingSortIcon from '../../assets/images/descending-sort-icon.svg';
import descendingSortIcon from '../../assets/images/ascending-sort-icon.svg';
import applyFilterGray from '../../assets/images/filter-apply.svg';
import applyFilterGreen from '../../assets/images/filter-active.svg';
import { ButtonContainer, FilterContainer, OverlayContainer, PaginationSection, TableContainer, TableHead } from '../Administration/Style';
import MultiTextInput from '../../components/MultiTextInput';


const SDSListContainer = styled.div`
    .cursor-pointer{
        cursor: pointer;
    }
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border-radius:10px;
    @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width:85%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: 6.5rem 0.5rem 0rem 6rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:6.5rem 0.5rem 0rem 6rem !important;
        max-width:100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    .w-5{
        width:5% !important;
    }
    .w-10{
        width:10% !important;
    }
    .w-15{
        width:15% !important;
    }
    .w-20{
        width:20% !important;
    }
    .w-25{
        width:25% !important;
    }
`;

const Heading = styled.h2`
    padding:0.875rem;
    margin:0;
    @media screen and (max-device-width:4000px) and (orientation: portrait) {
        margin-left: 1.5rem;
    }
    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin-left: 1.5rem;
    }
`;

const SDSHeading = styled.div`
    display: flex;

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        display: flow;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        display: flow;
    }
 `;

const Badge = styled.div`
justify-content: center!important;
display: flex;
.badge {
    align-items: center;
    border-radius: 20px;
    display: flex;
    gap: 10px;
    padding: 6px 16px;
    position: relative;
    width: 111px;
}

.badge .dot {
    border-radius: 2.5px;
    height: 5px;
    position: relative;
    width: 5px;
}

.badge .text {
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.badge.yellow {
    background-color: #f9f8f3;
}

.badge.red {
    background-color: #f73131;
}

.badge.green {
    background-color: rgba(243, 249, 245, 1);
}

.badge.yellow .dot {
    background-color: #bc9b25;
}

.badge.red .dot {
    background-color: black;
}

.badge.green .dot {
    background-color:rgba(66, 167, 100, 1);
}

.badge.yellow .text {
    color: #bc9b25;
}

.badge.red .text {
    color: #000000bd;
}

.badge.green .text {
    color:rgba(66, 167, 100, 1);
} 
`;


const Badge1 = ({ status = "Label" }) => {
    let color = '';
    if (status === 'Completed') color = 'green';
    if (status === 'Pending') color = 'yellow';
    if (status === 'Rejected') color = 'red';
    return (
        <Badge>
            <div className={`badge ${color}`}>
                <div className="dot" />
                <div className="text">{status}</div>
            </div>
        </Badge>
    );
};
class ShowSDSRequests extends Component {
    state = {
        loading: false,
        orderBy: 'lowerChemicalName',
        order: 'asc',
        pageSize: 10,
        pageNumber: 1,
        pageCount: 100,
        totalCount: 0,
        errors: {},
        filterDetails: {},
        activeFilterDetails: {},
        filterColumns: {},
        requestList: []
    };

    componentDidMount() {
        this.getSDSRequest();
    };

    getSDSRequest = async () => {
        try {
            const { user: { location } } = this.props;
            const { pageSize, pageNumber, orderBy, order, activeFilterDetails } = this.state;
            this.setState({ loading: true });
            const { data: { payload } } = await axios.get(`/api/v2/requestByLocationId/${location._id}`, { params: { ...activeFilterDetails, pageSize, pageNumber, orderBy, order } });
            const pageCount = Math.ceil(payload.totalCount / pageSize);
            this.setState({ loading: false, requestList: payload.data, totalCount: payload.totalCount, pageCount });
        } catch (error) {
            this.setState({ loading: false, errors: { getUsersErr: true, errorMsg: 'Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.' } });
        };
    };

    sort = (orderBy, order) => {
        this.setState({ order, orderBy, pageNumber: 1, pageSize: 10 }, () => {
            this.getSDSRequest();
        });
    };

    handlePageClick = (val) => {
        this.setState({ pageNumber: val.selected + 1 }, () => {
            this.getSDSRequest();
        });
    };

    onFilterInputChange = (name, value) => {
        const { filterDetails } = this.state;
        filterDetails[name] = value;
        this.setState({ filterDetails });
    };

    applyFilter = (colName) => {
        const { filterDetails, activeFilterDetails, filterColumns } = this.state;
        if (colName === 'trainingDueDate') {
            activeFilterDetails[colName] = moment(filterDetails[colName]).subtract(1, 'year');
        } else {
            activeFilterDetails[colName] = filterDetails[colName];
        };
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ activeFilterDetails, filterColumns, pageSize: 10, pageNumber: 1 }, () => {
            this.getSDSRequest();
        });
    };

    clearFilter = (colName) => {
        const { filterDetails, activeFilterDetails, filterColumns } = this.state;

        delete filterDetails[colName];
        delete activeFilterDetails[colName];
        filterColumns[colName] = !filterColumns[colName];
        this.setState({ activeFilterDetails, filterDetails, filterColumns, pageSize: 10, pageNumber: 1 }, () => {
            this.getSDSRequest();
        });
    };

    openCloseFilterSection = (colName) => {
        const { filterColumns } = this.state;
        filterColumns[colName] = !filterColumns[colName];
        this.setState({
            filterColumns
        });
    };

    addEditSDS = () => {
        const { history } = this.props;
        history.push('/sds/add');
    };


    render() {
        const { requestList, errors, loading, pageNumber, pageCount, orderBy, order, filterDetails, filterColumns, activeFilterDetails } = this.state;
        const { history } = this.props;
        return (
            <SDSListContainer>
                <SDSHeading className="justify-content-between mx-2 mt-2">
                    <Heading>Safety Data Sheets Requests</Heading>
                    <Heading>
                        <Button width='150' className='my-0' type='button' onClick={() => history.goBack()}>{'<< Back'}</Button>
                    </Heading>
                </SDSHeading>
                <TableContainer maxHeight='70vh'>
                    <table className='table'>
                        <TableHead>
                            <tr>
                                <th className='w-15 pl-5'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.chemicalName && !activeFilterDetails.chemicalName) && <img onClick={() => this.openCloseFilterSection('chemicalName')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.chemicalName || activeFilterDetails.chemicalName) && <img onClick={() => this.openCloseFilterSection('chemicalName')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.chemicalName &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.chemicalName || ''} onChange={(e) => this.onFilterInputChange('chemicalName', e.target.value)} placeholder='Chemical Name' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('chemicalName')}>Clear</button>
                                                    <button disabled={!filterDetails.chemicalName} className='btn btn-success' type='button' onClick={() => this.applyFilter('chemicalName')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Chemical Name
                                    <span>
                                        {(orderBy !== 'lowerChemicalName') && <img onClick={() => this.sort('lowerChemicalName', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'lowerChemicalName' && order === 'asc') && <img onClick={() => this.sort('lowerChemicalName', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'lowerChemicalName' && order === 'desc') && <img onClick={() => this.sort('lowerChemicalName', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span>
                                </th>
                                <th className='w-20'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.manufacturer && !activeFilterDetails.manufacturer) && <img onClick={() => this.openCloseFilterSection('manufacturer')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.manufacturer || activeFilterDetails.manufacturer) && <img onClick={() => this.openCloseFilterSection('manufacturer')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.manufacturer &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.manufacturer || ''} onChange={(e) => this.onFilterInputChange('manufacturer', e.target.value)} placeholder='Manufacturer' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('manufacturer')}>Clear</button>
                                                    <button disabled={!filterDetails.manufacturer} className='btn btn-success' type='button' onClick={() => this.applyFilter('manufacturer')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Manufacturer
                                    <span>
                                        {(orderBy !== 'lowerManufacturer') && <img onClick={() => this.sort('lowerManufacturer', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'lowerManufacturer' && order === 'asc') && <img onClick={() => this.sort('lowerManufacturer', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'lowerManufacturer' && order === 'desc') && <img onClick={() => this.sort('lowerManufacturer', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-10'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.cas && !activeFilterDetails.cas) && <img onClick={() => this.openCloseFilterSection('cas')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.cas || activeFilterDetails.cas) && <img onClick={() => this.openCloseFilterSection('cas')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.cas &&
                                            <OverlayContainer>
                                                <MultiTextInput
                                                    name='cas'
                                                    data={filterDetails.cas && filterDetails.cas.map((task) => {
                                                        return { label: task, value: task };
                                                    })}
                                                    onChange={(e) => this.onFilterInputChange('cas', e.map(t => t.value))}
                                                    placeholder='Subsidiary Ingredient CAS'
                                                />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('cas')}>Clear</button>
                                                    <button disabled={!filterDetails.cas} className='btn btn-success' type='button' onClick={() => this.applyFilter('cas')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Subsidiary Ingredient CAS <span>
                                        {(orderBy !== 'cas') && <img onClick={() => this.sort('cas', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'cas' && order === 'asc') && <img onClick={() => this.sort('cas', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'cas' && order === 'desc') && <img onClick={() => this.sort('cas', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-10'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.requestedBy && !activeFilterDetails.requestedBy) && <img onClick={() => this.openCloseFilterSection('requestedBy')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.requestedBy || activeFilterDetails.requestedBy) && <img onClick={() => this.openCloseFilterSection('requestedBy')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.requestedBy &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.requestedBy || ''} onChange={(e) => this.onFilterInputChange('requestedBy', e.target.value)} placeholder='Requested By' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('requestedBy')}>Clear</button>
                                                    <button disabled={!filterDetails.requestedBy} className='btn btn-success' type='button' onClick={() => this.applyFilter('requestedBy')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Requested By<span>
                                        {(orderBy !== 'requestedBy.fullName') && <img onClick={() => this.sort('requestedBy.fullName', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'requestedBy.fullName' && order === 'asc') && <img onClick={() => this.sort('requestedBy.fullName', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'requestedBy.fullName' && order === 'desc') && <img onClick={() => this.sort('requestedBy.fullName', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-10'>
                                    <FilterContainer className="mr-1">
                                        {(!filterColumns.requestedAt && !activeFilterDetails.requestedAt) && <img onClick={() => this.openCloseFilterSection('requestedAt')} className='cursor-pointer' src={applyFilterGray} alt="Apply Filter Icon" />}
                                        {(filterColumns.requestedAt || activeFilterDetails.requestedAt) && <img onClick={() => this.openCloseFilterSection('requestedAt')} className='cursor-pointer' src={applyFilterGreen} alt="Apply Filter Icon" />
                                        }
                                        {filterColumns.requestedAt &&
                                            <OverlayContainer>
                                                <Input value={filterDetails.requestedAt || ''} onChange={(e) => this.onFilterInputChange('requestedAt', e.target.value)} placeholder='Requested  At' />
                                                <ButtonContainer>
                                                    <button className='btn mr-2' type='button' onClick={() => this.clearFilter('requestedAt')}>Clear</button>
                                                    <button disabled={!filterDetails.requestedAt} className='btn btn-success' type='button' onClick={() => this.applyFilter('requestedAt')} >Apply</button>
                                                </ButtonContainer>
                                            </OverlayContainer>
                                        }
                                    </FilterContainer>
                                    Requested At<span>
                                        {(orderBy !== 'requestedAt') && <img onClick={() => this.sort('requestedAt', 'asc')} className='cursor-pointer' src={sortIcon} alt="Apply Sort Icon" />}
                                        {(orderBy === 'requestedAt' && order === 'asc') && <img onClick={() => this.sort('requestedAt', 'desc')} className='cursor-pointer' src={ascendingSortIcon} alt="Ascending Sort Icon" />}
                                        {(orderBy === 'requestedAt' && order === 'desc') && <img onClick={() => this.sort('requestedAt', 'asc')} className='cursor-pointer' src={descendingSortIcon} alt="Descending Sort Icon" />}
                                    </span></th>
                                <th className='w-10 text-center'>
                                    Status
                                </th>
                            </tr>
                        </TableHead>
                        {!loading &&
                            <tbody>
                                {requestList.length && requestList.map((sds, index) => (
                                    <tr key={index}>
                                        <td className='w-25 pl-5'>{sds.chemicalName}</td>
                                        <td className='w-20'>{sds.lowerManufacturer}</td>
                                        <td className='w-15'>{sds.cas && sds.cas.length ? sds.cas.toString() : '- -'}</td>
                                        <td className='w-15'>{sds.requestedBy.fullName}</td>
                                        <td className='w-15'>{moment(sds.requestedAt).format('YYYY-MM-DD hh:mm')}</td>
                                        <td className='w-10 text-center'>  <Badge1 status={sds.status} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                    {(!requestList.length && !loading) &&
                        <Heading className='d-flex justify-content-center'>
                            {Object.values(activeFilterDetails).length ? "No results match your search criteria" : "No Safety Data Sheets request submitted yet."}
                        </Heading>
                    }
                </TableContainer>

                {
                    loading &&
                    <div className='w-100 text-center'>
                        <Spinner animation="border" variant="success" />
                    </div>
                }
                {errors.getUsersErr &&
                    <Row>
                        <Col>
                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                <p className='w-100 mb-0'>{errors.errorMsg}</p>
                            </Alert>
                        </Col>
                    </Row>
                }
                <PaginationSection>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        forcePage={pageNumber !== 0 ? pageNumber - 1 : 0}
                        previousLabel="<"
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        renderOnZeroPageCount={null}
                    />
                </PaginationSection>
            </SDSListContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(ShowSDSRequests);