import React, { Component } from 'react';
import axios from 'axios';
import { Figure, Spinner, Col, Row, Alert, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import Select from 'react-select';

import { SearchInput, WhiteButton, CustomCard, CardBody, IconContainer, ImageContainer, ContentHeader, AddButton } from './Style';
import { AddEditRole, Button } from '../../components';

import addIcon from '../../assets/images/add.svg';
import pencil from '../../assets/images/pencil.svg';
import trash from '../../assets/images/trash.svg';
import clinical from '../../assets/roles-icon/clinical.svg';
import nonClinical from '../../assets/roles-icon/nonClinical.svg';
import provider from '../../assets/roles-icon/provider.svg';
import office from '../../assets/roles-icon/office.svg';
import support from '../../assets/roles-icon/support.svg';
import { DropDownStyle } from '../../helpers';

const RolesListContainer = styled.div`
  .row {
        &:last-child {
        margin-bottom: 1rem;
        }
        @media screen and (max-device-width:580px) and (orientation: portrait) {
            width:89%;
        }
        @media screen and (max-device-width:580px) and (orientation: landscape) {
            width:89%;
        }
    }
`;

const RolesContainer = styled(Row)`
    overflow-y: auto;
    max-height:60vh;
`;

class RolesList extends Component {
    state = {
        loading: false,
        roleDetails: { name: "", description: "", jobTasks: [], defaultEquipment: [], location: this.props.user.location._id },
        search: '',
        selectedRoles: [],
        addOrEditRole: false,
        defaultRoles: [],
        customRoles: [],
        errors: { errorMessage: '' },
        saveOrUpdateInProgress: false,
        isDeleteRoleModalOpen: false,
        safetyEquipments: [],
        locations: [],
        selectedLocation: this.props.user.location._id
    };

    componentDidMount() {
        this.getMasterData();
        this.getRoles();
    };

    getMasterData = async () => {
        try {
            const { user } = this.props;
            const { data: { payload: locations } } = await axios.get(`/api/v2/master/locations_by_user/${user._id}`);
            this.setState({ locations });
        } catch (error) {
            console.error(error);
        }
    };

    getRoles = async () => {
        try {
            const { user } = this.props;
            const { search, selectedLocation } = this.state;
            this.setState({ loading: true });
            const { data: { payload } } = await axios.get(`/api/v2/roles/${selectedLocation ? selectedLocation : user.location._id}`, { params: { search } });
            const defaultRoles = [];
            const customRoles = [];

            if (payload.data.length) {
                payload.data.forEach((role) => {
                    if (role.default) {
                        defaultRoles.push(role);
                    } else {
                        customRoles.push(role);
                    };
                });
            };

            this.setState({ loading: false, defaultRoles, customRoles });
        } catch (error) {
            this.setState({ loading: false, defaultRoles: [], errors: { apiError: true } });
        };
    };

    search = (value) => {
        this.setState({ search: value }, () => {
            this.getRoles();
        });
    };

    editAndAddRole = async (e, role) => {
        e.stopPropagation();
        this.setState({ addOrEditRole: true, role });
    };

    deleteRole = async () => {
        try {
            const { roleDetails } = this.state;
            await axios.delete(`/api/v2/role/${roleDetails._id}`);
            await this.getRoles();
            this.closePopUpModal();
            Swal.fire({
                icon: 'success',
                title: 'Role has been deleted successfully',
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            });
        } catch (error) {
            if (error.response && (error.response.status === 405 || error.response.status === 404)) {
                this.setState({
                    saveOrUpdateInProgress: false,
                    errors: { apiError: true, errorMessage: error.response.data.message }
                });
                return;
            };
            this.setState({
                saveOrUpdateInProgress: false,
                errors: { apiError: true, errorMessage: 'Error at deleting role.' }
            });
        }
    };

    closePopUpModal = () => {
        this.getRoles();
        this.setState({
            addOrEditRole: false,
            roleDetails: {
                name: "", description: "", jobTasks: [], defaultEquipment: [], location: this.props.user.location._id
            },
            errors: {},
            saveOrUpdateInProgress: false,
            isDeleteRoleModalOpen: false
        });

    };

    viewRole = (roleId) => {
        const { history } = this.props;
        history.push('/admin/role-view?roleId=' + roleId);
    };

    onLocationChange = async (value) => {
        const selectedLocation = value ? value.value : this.props.user.location._id;
        this.setState({ selectedLocation, search: '' }, () => {
            this.getRoles();
        });
    };

    render() {
        const { locations, search, role, defaultRoles, customRoles, loading, isDeleteRoleModalOpen, saveOrUpdateInProgress, addOrEditRole, errors, errors: { apiError, errorMessage }, selectedLocation } = this.state;
        const { user } = this.props;
        const icons = { clinical, support, nonClinical, provider, office };

        return (
            <RolesListContainer>
                <Row className="mx-2">
                    <Col lg='6' md='6' sm='12'>
                        <SearchInput value={search} className='pl-5 col-lg-8 col-md-6 col-sm-12' onChange={(e) => this.search(e.target.value)} type='text' placeholder='Search...' />
                    </Col>
                    <Col className='d-flex justify-content-end' lg='6' md='6' sm='12'>
                        <Select
                            className='min-w-25'
                            options={locations}
                            value={locations.find(lo => lo.value === selectedLocation)}
                            defaultValue={locations.find(lo => lo.value === selectedLocation)}
                            onChange={this.onLocationChange}
                            placeholder='Locations'
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={true}
                            isClearable={selectedLocation === user.location._id ? false : true}
                        />
                        <AddButton onClick={this.editAndAddRole} className='ml-2 my-0'><img src={addIcon} alt="Add Icon" /> Add Role</AddButton>
                    </Col>
                </Row>
                <RolesContainer className="mt-2 mx-2">
                    {
                        loading &&
                        <div className='w-100 text-center'>
                            <Spinner animation="border" variant="success" />
                        </div>
                    }
                    {!loading && !apiError &&
                        <>
                            <ContentHeader className='mt-0 pb-0'>Custom Roles ({customRoles.length || 0})</ContentHeader>
                            {customRoles.map((role, index) => (
                                <Col key={index} xs={12} md={6} className="mb-4">
                                    <CustomCard onClick={() => this.viewRole(role._id)}>
                                        <CardBody>
                                            <IconContainer>
                                                <ImageContainer
                                                    width={50}
                                                    height={50}
                                                    alt="50x50"
                                                    src={support}
                                                />
                                                <Figure.Caption className='w-100 textAlign'>
                                                    <div className="d-flex justify-content-between text-success">
                                                        <h5>
                                                            {role.name}
                                                        </h5>
                                                        <span>
                                                            <img style={{ cursor: 'pointer' }} onClick={(e) => this.editAndAddRole(e, role)} src={pencil} className='mr-2' alt="Edit" />
                                                            <img style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); this.setState({ isDeleteRoleModalOpen: true, roleDetails: role }); }} src={trash} className='mr-2' alt="Delete" />
                                                        </span>
                                                    </div>
                                                    {role.description ? role.description : '--'}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            ))}
                        </>
                    }
                    {!loading && !apiError &&
                        <>
                            <ContentHeader className='mt-0 pb-0'>Default Roles ({defaultRoles.length || 0})</ContentHeader>
                            {defaultRoles.map((role, index) => (
                                <Col key={index + 'd'} xs={12} md={6} className="mb-4">
                                    <CustomCard onClick={() => this.viewRole(role._id)}>
                                        <CardBody>
                                            <IconContainer>
                                                <ImageContainer
                                                    width={50}
                                                    height={50}
                                                    alt="50x50"
                                                    src={icons[role.defaultID]}
                                                />
                                                <Figure.Caption className='w-100 textAlign'>
                                                    <div className="text-success">
                                                        <h5>
                                                            {role.name}
                                                        </h5>
                                                    </div>
                                                    {role.description ? role.description : '--'}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            ))}
                        </>
                    }
                    {apiError && !defaultRoles.length && !customRoles.length &&
                        <Col>
                            <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                <p className='w-100 mb-0'>Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.</p>
                            </Alert>
                        </Col>
                    }
                </RolesContainer>
                {
                    addOrEditRole &&
                    <AddEditRole addOrEditRole={addOrEditRole} closePopUpModal={this.closePopUpModal} role={role} user={this.props.user} />
                }
                {isDeleteRoleModalOpen &&
                    <Modal
                        centered
                        animation={true}
                        backdrop="static"
                        keyboard={false}
                        show={isDeleteRoleModalOpen}>
                        <Modal.Body>
                            <div className='text-center mt-2'>
                                <h3>Are you sure?</h3>
                                <h5 className='my-3'>Do you want to delete the role? This process cannot be undone.</h5>
                            </div>
                            {apiError &&
                                <Row className='mb-2'>
                                    <Col>
                                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger"
                                            onClose={() => this.setState({ errors: { ...errors, apiError: false } })} dismissible>
                                            <p className='w-100 mb-0'>{errorMessage}</p>
                                        </Alert>
                                    </Col>
                                </Row>
                            }
                            <Modal.Footer className='p-0'>
                                <div className='d-flex justify-content-end'>
                                    <Button className='my-0 mr-3' type='button' onClick={() => this.closePopUpModal()}>No</Button>
                                    <WhiteButton disabled={saveOrUpdateInProgress} className='my-0' type='button' onClick={() => this.deleteRole()}>{saveOrUpdateInProgress ? 'Deleting...' : 'Yes'}</WhiteButton>
                                </div>
                            </Modal.Footer>
                        </Modal.Body>
                    </Modal>
                }
            </RolesListContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(RolesList);