import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components';
import calendar from '../assets/images/glyph-calendar-green.svg';
import { Col } from 'react-bootstrap';

export const DateRangeInputContainer = styled(Col)`
.react-datepicker {
    &-wrapper {
        width: 100%;
    }
    &__triangle {
     display: none !important;
    }
    &__close-icon{
        padding: 0 30px 0 0;
    }
    &__input-container {
      width: 100%;    
      position: relative;
      input {
        width: 100%;
        padding: 9px 30px 9px 9px; /* Adjust the padding as needed */
        font-size: 12px;
        font-family: 'Hauora';
        color: var(--dark-text-color);
        font-weight: 400;
        border: 0.5px solid #b9bcca;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        border-radius: 3px;
        transition: border 0.15s linear, box-shadow 0.15s linear;
      }
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 1.2em;
        height: 1.2em;
        right: 6px;
        top: calc(50% - 7px);
        margin-left: 5px;
        background-image: url("${calendar}");
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
`;

const DatePickerWrapper = styled.span`
.react-datepicker {
  &-wrapper {
    ${(props) => (props.width ? 'width :' + props.width + ';' : '')};
  }
  &__triangle {
   display: none !important;
  }
  &__input-container {
    position: relative;
    ${(props) => (props.width ? 'width :' + props.width + ';' : '')};
    input {
        border: 0.5px solid #b9bcca;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        transition: border 0.15s linear, box-shadow 0.15s linear;
        cursor: pointer;
        padding-right: 20px;
        ${(props) => (props.width ? 'width :' + props.width + ';' : '')};
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        height:26px;
    }
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 1.2em;
      height: 1.2em;
      right: 6px;
      top: calc(50% - 7px);
      margin-left: 5px;
      background-image: url("${calendar}");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
`;



export const DateInput = ({ placeholder = 'mm-dd-yyyy', dateFormat = 'MM-dd-yyyy', onChange, value, required = false, className, style, disabled, id, minDate, maxDate, width }) => {

  return (
    <React.Fragment>
      <DatePickerWrapper width={width}>
        <DatePicker
          className={className}
          style={style}
          disabled={disabled}
          id={id}
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={placeholder}
          dateFormat={dateFormat}
          selected={value}
          onChange={(date) => onChange(date)}
          required={required}
          popperModifiers={[
            {
              name: "offset",
              options: {
                offset: [4, 8],
              },
            },
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
      </DatePickerWrapper>
    </React.Fragment>
  );
};
