import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Select from 'react-select';

import AddButton from "../../assets/images/button-add-mini.svg";
import trash from '../../assets/images/trash.svg';
import { DropDownStyle, apiUrl, formatTimeInMinAndSec } from "../../helpers";
import { VideoDuration, VideoTitle } from "./TrainingList";
import { Checkbox, Input, TransparentButton } from "../../components";
import UserSelection from "./UserSelection";
import { SearchInput, TableContainer, TableHead, CreditContainer, CustomCard, GroupTrainingContainer, HeaderContainer, HeaderText, NavTab, NavigationTabSection, NewVideoTile, SelectedIndex, SubHeader, VideoContainer, StyledLabel, CrosseArrowButton } from "./Style";
import StartGroupTrainingConfirmation from "./StartGroupTrainingConfirmation";
import Swal from "sweetalert2";


const GroupTrainingPreparation = () => {
    const loginUser = useSelector(state => state.user.currentUser);
    const [edit, setEdit] = useState('')
    const [trainingData, setTrainingData] = useState({ all: [], osha: [], hippa: [], newHire: [], newHireTrainingMinutesWatched: 0, trainingMinutesWatched: 0, trainingMinutesResetAt: '' });
    const [trainingName, setTrainingName] = useState('');
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVideoContainerOpen, setIsVideoContainerOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const [peopleSearch, setSearchPeople] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('all');
    const [locations, setLocations] = useState([]);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const [error, setError] = useState('');
    const [selectedVideoDuration, setSelectedVideoDuration] = useState(0);
    const [isUserSelectionModalOpen, setIsUserSelectionModalOpen] = useState(false);
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getTrainingVideos();
        getMasterData();
        getTrainingData();
    }, []);

    const getTrainingData = async () => {
        try {
            const searchParams = new URLSearchParams(history.location.search);
            const id = searchParams.get('id');
            if (id) {
                setEdit(id);
                setIsVideoContainerOpen(true);
                const { data: { payload } } = await axios.get(`/api/v2/group_training_details/${id}`);
                setUsers(payload.users);
                setFilteredUsers(payload.users);
                const users = payload.users.map(user => user._id);
                const videos = payload.videos.map(user => user.eTag);
                setSelectedUsers(users);
                setSelectedVideos(videos);
                setSelectedVideoDuration(payload.totalDuration);
                setTrainingName(payload.trainingName);
            };
        } catch (error) {
            console.log("🚀 ~ getTrainingData ~ error:", error);
        }
    };

    const getMasterData = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(`/api/v2/master/locations_by_user/${loginUser._id}`);
            setLocations([{ label: 'All', value: 'all' }, ...payload]);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.');
            setLoading(false);
        }
    };

    const getTrainingVideos = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/training/' + loginUser._id);
            setTrainingData(payload);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.')
        }
    };

    const selectTab = (tab) => {
        setLoading(true);
        setActiveTab(tab);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const selectVideo = (checked, video) => {
        if (checked) {
            setSelectedVideos([...selectedVideos, video.eTag]);
            setSelectedVideoDuration(selectedVideoDuration + video.duration);
        } else {
            setSelectedVideos(selectedVideos.filter(eTag => eTag !== video.eTag));
            setSelectedVideoDuration(selectedVideoDuration - video.duration);
        };
    };

    const searchPeople = (value) => {
        const temp = !value ? users : users.filter(user => user.fullName.includes(value));
        setFilteredUsers(temp);
        setSearchPeople(value);
    };

    const onLocationChange = async (e) => {
        const temp = e.value === 'all' ? users : users.filter(user => user.locations.includes(e.value));
        setFilteredUsers(temp);
        setSelectedLocation(e.value);
    };

    const onUserSelectionModalClose = () => {
        setIsUserSelectionModalOpen(false);
    };

    const saveSelectedUsers = async (selectedUsersForTraining) => {
        try {
            onLocationChange({ value: 'all' })
            setLoading(true);
            setSelectedUsers(selectedUsersForTraining);
            setIsUserSelectionModalOpen(false);
            if (selectedUsersForTraining.length > 0) {
                const { data: { payload } } = await axios.get(`/api/v2/training/selected_users/${selectedUsersForTraining}`);
                setUsers(payload);
                setFilteredUsers(payload);
            } else {
                setUsers([]);
                setFilteredUsers([]);
            };
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.');
        }
    };

    const removeSelectedUsers = (user_id) => {
        setSelectedUsers(selectedUsers.filter(user => user !== user_id));
        setUsers(users.filter(user => user._id !== user_id));
        setFilteredUsers(filteredUsers.filter(user => user._id !== user_id));
    };

    const validate = () => {
        let valid = true;
        if (!trainingName) {
            setError("Training name is required");
            valid = false;
        } else if (!selectedVideos || !selectedVideos.length) {
            setError("Please select at least one video");
            valid = false;
        } else if (!selectedUsers || selectedUsers.length < 2) {
            setError("Please select at least two users");
            valid = false;
        };
        if (valid) {
            setError("");
            setIsConfirmationModalOpen(true);
        };
    };

    const saveGroupTraining = async () => {
        try {
            if (edit) {
                await axios.post(`/api/v2/group_training/update/${edit}`, { videos: selectedVideos, duration: selectedVideoDuration, users: selectedUsers, trainingName, location: loginUser.location._id, practice: loginUser.practice._id });
                history.push(`/training/group/view?id=${edit}`);
            } else {
                const { data: { payload } } = await axios.post('/api/v2/group_training/create', { videos: selectedVideos, duration: selectedVideoDuration, users: selectedUsers, trainingName, location: loginUser.location._id, practice: loginUser.practice._id });
                history.push(`/training/group/view?id=${payload.insertedId}`);
            }
        } catch (error) {
            console.log("🚀 ~ saveGroupTraining ~ error:", error);
            if (error.response?.status === 409) {
                Swal.fire({
                    icon: 'error',
                    title: 'Training with the same name already exist',
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                });
            } else {
                setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.');
            }
        }
    };

    const calculateDuration = (user, type) => {
        const s_vi = user.trainingProgress?.filter(vi => selectedVideos.includes(vi.eTag));
        let sum = 0;

        s_vi.forEach(el => { sum += el.duration - el.currentTime; });
        if (type === 'this_session') {
            return formatTimeInMinAndSec(sum);
        } else if (type === 'needed') {
            const temp = 3600 - (user.trainingMinutesWatched + sum);

            if (temp > 0) {
                return formatTimeInMinAndSec(temp);
            }
            return '0 min'
        }
    };

    const getUsersLocation = (user) => {
        const loc = locations.filter(lo => user.locations.includes(lo.value));
        if (!loc || !loc.length) return '--';
        if (loc.length === 1) return loc[0].label;
        if (loc.length > 1) return loc;
    };

    return (
        <GroupTrainingContainer>
            <HeaderContainer className="mb-4 pb-2">
                <HeaderText>Start Group Training</HeaderText>
                <div className="d-flex">
                    <Button onClick={() => history.goBack()} className="px-4 mr-2" variant="light" type='button'>{'<< Back'}</Button>
                    <Button onClick={() => validate()} className="px-4" variant="success" type='button'>Start</Button>
                </div>
            </HeaderContainer>
            {error && <Alert className="mt-2" variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert>}
            <CustomCard>
                <HeaderContainer className="mb-4">
                    <SubHeader>1. Training Details</SubHeader>
                </HeaderContainer>
                <CustomCard className="d-bloc p-3">
                    <StyledLabel>Name of the training</StyledLabel>
                    <Input onChange={(e) => setTrainingName(e.target.value)} value={trainingName} name="trainingName" className="d-block col-lg-3 col-md-6 col-sm-12" />
                </CustomCard>
            </CustomCard>
            <CustomCard className="my-4">
                <HeaderContainer className="mb-4">
                    <SubHeader>2. Training Material</SubHeader>
                </HeaderContainer>
                <CustomCard className="text-center p-3">
                    <p className="cursor-pointer p-0 m-0" onClick={() => setIsVideoContainerOpen(!isVideoContainerOpen)}><img src={AddButton} alt="Add button" /> Add video</p>
                </CustomCard>
                {isVideoContainerOpen && <>
                    <HeaderContainer className="mt-4">
                        <SubHeader>Library</SubHeader>
                    </HeaderContainer>
                    <NavigationTabSection className="mx-0 mt-2 w-100">
                        <NavTab onClick={() => selectTab('all')} className={activeTab === 'all' ? 'active' : ''} lg='4' sm='4' md='4' title="All" >
                            All
                        </NavTab>
                        <NavTab onClick={() => selectTab('osha')} className={activeTab === 'osha' ? 'active' : ''} lg='4' sm='4' md='4' title="OSHA" >
                            OSHA
                        </NavTab>
                    </NavigationTabSection>
                    {loading ?
                        <div className='w-100 my-3 p-4 text-center'>
                            <Spinner animation="border" variant="success" />
                        </div>
                        :
                        <Row className={loading ? 'fade-out mx-0 px-0 w-100' : 'fade-in mx-0 px-0 w-100'} style={{ minHeight: '30vh' }} >
                            {trainingData[activeTab].map((video, index) =>
                                <Col lg={4} sm={12} md={6} key={index} className="m-0 p-0">
                                    <VideoContainer selected={selectedVideos.includes(video.eTag)} onClick={() => selectVideo(!selectedVideos.includes(video.eTag), video)}>
                                        {selectedVideos.indexOf(video.eTag) + 1 > 0 && <SelectedIndex>
                                            {selectedVideos.indexOf(video.eTag) + 1}
                                        </SelectedIndex>
                                        }
                                        <NewVideoTile bgImage={video.poster ? apiUrl('/api/v2/trainingPoster/' + encodeURIComponent(video.poster.replaceAll('/', '~|~'))) : defaultPoster} />
                                        <VideoTitle className="mt-2 mx-2 mb-0">{video.title && video.title.indexOf('/') > -1 ? video.title.slice(video.title.indexOf('/') + 1) : video.title}</VideoTitle>
                                        <div className="d-flex justify-content-between">
                                            <VideoDuration className="mx-2 mt-0 mb-3">{(video.duration / 60).toFixed(2)} min</VideoDuration> <Checkbox disabled checked={selectedVideos.includes(video.eTag)} />
                                        </div>
                                    </VideoContainer>
                                </Col>
                            )}
                        </Row>
                    }
                    <CreditContainer className="py-3">
                        <SubHeader>Total possible credit</SubHeader>
                        <SubHeader>{(selectedVideoDuration / 60).toFixed(2)} min</SubHeader>
                    </CreditContainer>
                </>
                }
            </CustomCard>
            <CustomCard>
                <HeaderContainer className="mb-4">
                    <SubHeader>3. People</SubHeader>
                </HeaderContainer>
                <div className="row justify-content-between px-5">
                    <div className="px-0 clo-lg-6 col-md-8 col-sm-12 row">
                        <SearchInput value={peopleSearch} className='clo-lg-2 col-md-3 col-sm-12' onChange={(e) => searchPeople(e.target.value)} type='text' placeholder='Search...' />
                        <Select
                            className="clo-lg-2 col-md-3 col-sm-12"
                            options={locations}
                            value={locations.find(lo => lo.value === selectedLocation)}
                            defaultValue={locations.find(lo => lo.value === selectedLocation)}
                            onChange={onLocationChange}
                            placeholder='Locations'
                            menuPosition="fixed"
                            styles={DropDownStyle}
                            required={true}
                        />
                    </div>
                    <Button className="px-5" onClick={() => setIsUserSelectionModalOpen(true)} variant="success" type="button">+ Add People</Button>
                </div>
                <TableContainer className='mt-2 mx-2' maxHeight='60vh'>
                    <table className='table'>
                        <TableHead>
                            <tr>
                                <th className='w-15 pl-4'>Name</th>
                                <th className='w-15'>Current Credit</th>
                                <th className='w-15'>Earned This Session</th>
                                <th className='w-15'>Needed For Completion</th>
                                <th className='w-15'>Email address</th>
                                <th className='w-15'>Practice Location(s)</th>
                                <th className='w-5'>Action</th>
                            </tr>
                        </TableHead>
                        {!loading &&
                            <tbody>
                                {filteredUsers.map((user) => (
                                    <tr className='justify-content-between align-items-center' key={user._id}>
                                        <td className='w-15 pl-4'>{user.fullName + (user._id === loginUser._id ? ' (Self)' : '')}</td>
                                        <td className="w-15">{formatTimeInMinAndSec(user.trainingMinutesWatched)}</td>
                                        <td className="w-15">{calculateDuration(user, "this_session")}</td>
                                        <td className="w-15">{calculateDuration(user, "needed")} </td>
                                        <td className='w-15'>{user.email || '--'}</td>
                                        <td className="w-10">{Array.isArray(getUsersLocation(user)) ?
                                            <CrosseArrowButton onClick={() => { setSelectedUser({ ...user, locations: getUsersLocation(user) }); setIsLocationModalOpen(true) }}>Show </CrosseArrowButton> : getUsersLocation(user)}</td>
                                        <td className='w-5'>
                                            <TransparentButton onClick={() => removeSelectedUsers(user._id)} className='mr-2' type='button'>
                                                <img src={trash} alt="Remove user" />
                                            </TransparentButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                    {(!loading && !selectedUsers.length) ?
                        <Alert className='my-2' style={{ 'maxWidth': '100%' }} variant="info">
                            <p className='w-100 mb-0 text-center'>No users selected yet.</p>
                        </Alert> : null
                    }

                    {(!loading && selectedUsers.length && !filteredUsers.length) ?
                        <Alert className='my-2' style={{ 'maxWidth': '100%' }} variant="info">
                            <p className='w-100 mb-0 text-center'>No records match the applied filter criteria.</p>
                        </Alert> : null
                    }
                </TableContainer>
                <div>
                    <Col lg={12}>
                        {error && <Alert className="mt-2" variant="danger" style={{ maxWidth: '100%' }} >{error}</Alert>}
                    </Col>
                    <Col lg={12} className="text-right">
                        <Button onClick={() => validate()} className="px-5 py-2" variant="success" type='button'>Start</Button>
                    </Col>
                </div>
            </CustomCard>
            {isUserSelectionModalOpen && <UserSelection show={isUserSelectionModalOpen} onClose={onUserSelectionModalClose} locations={locations} loginUser={loginUser} selectedUsers={selectedUsers} afterSave={saveSelectedUsers} />}

            {
                isConfirmationModalOpen &&
                <StartGroupTrainingConfirmation edit={edit} save={saveGroupTraining} onClose={setIsConfirmationModalOpen} show={isConfirmationModalOpen} />
            }

            {isLocationModalOpen &&
                <Modal
                    centered
                    animation={true}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                    show={isLocationModalOpen}>
                    <Modal.Header>
                        <Modal.Title>{selectedUser.fullName}'s locations</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <table className='table'>
                                <TableHead>
                                    <tr>
                                        <th className='w-100 pl-4 text-center'>Location Name</th>
                                    </tr>
                                </TableHead>
                                <tbody>
                                    {selectedUser.locations.map((lo) => (
                                        <tr key={lo.value}>
                                            <td className='w-100 pl-4 text-center'>{lo.label}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Container>
                    </Modal.Body>
                    <Container className="text-center mb-4">
                        <Button type="button" onClick={() => { setSelectedUser({}); setIsLocationModalOpen(false) }} variant="danger">Close</Button>
                    </Container>
                </Modal>
            }
        </GroupTrainingContainer >
    )
}

export default GroupTrainingPreparation;