import React, { Component } from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import styled from 'styled-components';

const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
`;

const StyledDiv = styled.div`
  font-size: 11px;
  color: var(--light-text-color);
`;

// Style the Stripe elements.
// See https://stripe.com/docs/stripe-js/reference#element-options for allowable options
const createOptions = {
  style: {
    base: {
      'backgroundColor': '#FFFFFF',
      'fontSize': '11px',
      'fontFamily': 'system-ui',
      '::placeholder': {
        color: '#343747'
      }
    },
    invalid: {
      color: '#9e2146'
    }
  }
};
/**
 * Creates a StripeForm that contains credit card number, expiry, and cvc.
 * Must be injected into a parent component using injectStripe, which wraps it as a HOC
 * See https://github.com/stripe/react-stripe-elements#setting-up-your-payment-form-injectstripe
 *
 * @class StripeForm
 * @extends {Component}
 */
class StripeForm extends Component {
  state = {
    locationName: ''
  };

  handleBlur = () => { };

  handleClick = () => { };

  handleFocus = () => { };

  handleReady = () => { };

  handleStripeChange = () => { };

  render() {
    const { cardNumber, expiration, cvc } = this.state;
    const { data, edit } = this.props;
    const stripeCard = data ? data.sources.data[0] : false;
    const cardExpireDate = stripeCard ? `${stripeCard.exp_month} / ${stripeCard.exp_year}` : 'MM / YY';
    const cardLastFour = stripeCard ? `**** **** **** ${stripeCard.last4}` : 'Card Number';
    const cardCVC = stripeCard ? '***' : 'CVC';
    return (
      <div style={{ width: 'auto' }}>
        <CardNumberElement
          onBlur={this.handleBlur}
          onChange={this.handleStripeChange}
          onFocus={this.handleFocus}
          onReady={this.handleReady}
          {...createOptions}
          placeholder={!edit ? cardLastFour : 'Card Number'}
          value={cardNumber}
          className='address'
          disabled={edit !== undefined ? (edit === true ? false : true) : false}
        />
        <DetailsContainer>
          <div
            style={{
              width: '82px'
            }}>
            <StyledDiv>EXPIRATION</StyledDiv>
            <CardExpiryElement
              onBlur={this.handleBlur}
              onChange={this.handleStripeChange}
              placeholder={!edit ? cardExpireDate : 'MM/YY'}
              onFocus={this.handleFocus}
              onReady={this.handleReady}
              {...createOptions}
              value={expiration}
              disabled={edit !== undefined ? (edit === true ? false : true) : false}
            />
          </div>
          <div style={{ width: '58px' }}>
            <StyledDiv>CVC</StyledDiv>
            <CardCVCElement
              onBlur={this.handleBlur}
              onChange={this.handleStripeChange}
              onFocus={this.handleFocus}
              placeholder={!edit ? cardCVC : 'CVC'}
              onReady={this.handleReady}
              {...createOptions}
              value={cvc}
              disabled={edit !== undefined ? (edit === true ? false : true) : false}
            />
          </div>
        </DetailsContainer>
      </div>
    );
  }
}
export default injectStripe(StripeForm);
