import actions from './actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { apiUrl } from '../helpers';


/**
 * @namespace libraryActions
 * @category Actions
 */

/**
 * @memberof libraryActions
 *
 */
export const loadLibraryFailure = () => ({
  type: actions.LOAD_LIBRARY_FAILURE
});

/**
 *
 * @memberof libraryActions
 * @param {*} library
 */
export const loadLibrarySuccess = (library) => ({
  type: actions.LOAD_LIBRARY_SUCCESS,
  library
});

/**
 *
 * @memberof libraryActions
 * @async
 * @param {*} dispatch
 * @param {*} getState
 */
export const loadLibrary = () => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    const { data: { payload } } = await axios.get(apiUrl(`/api/v2/librarySteps/${currentUser.location._id}`));
    await dispatch(loadLibrarySuccess(payload));
    return;
  } catch (err) {
    dispatch(loadLibraryFailure());
  }
};


/**
 *
 * @memberof libraryActions
 * @param {*} article
 */
export const updateLocationSuccess = (location) => ({
  type: actions.UPDATE_USER_LOCATION,
  location
});

export const saveLibraryData = (libraryData) => async (dispatch) => {
  try {
    const { data } = await axios.post(apiUrl(`/api/v1/locations/saveLibraryData`), {
      ...libraryData
    });
    dispatch(updateLocationSuccess(data));
    return data;
  } catch (err) {
    toast.error('Error');
  }
};

export const updateLibraryData = (libraryData) => async (dispatch) => {
  try {
    const { data } = await axios.put(apiUrl(`/api/v1/updateLibraryData`), libraryData);
    await dispatch(updateLocationSuccess(data));
    return data;
  } catch (err) {
    toast.error('Error in updating library.');
  }
};

export const getAllPagesOfLibrary = (params) => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();
    let url = `/api/v2/libraryPages/${currentUser.location._id}`
    if (params) url += '?searchValue=' + params;
    const { data: { payload } } = await axios.get(apiUrl(url));
    return { includes: payload.includes, data: payload };
  } catch (error) {
    console.error(error);
  }
};
